import React from 'react';
import { Pagination as MuiPagination, Stack } from '@mui/material';

const Pagination = ({ count, page, onPageChange }) => {
  return (
    <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
      <MuiPagination
        count={count}
        page={page}
        onChange={onPageChange}
        variant="outlined"
        color="primary"
      />
    </Stack>
  );
};

export default Pagination;