import React, { useEffect, useState } from 'react';
import styles from './MyProgress.module.scss';
import { FaTableCells } from "react-icons/fa6";
import { VscGraph } from "react-icons/vsc";
import { getCategoryList } from '../../services/learningHub.service';
import { getProgressFeedbackScore } from '../../services/feedback.service';
import FeedbackProgress from './components/FeedbackProgress';
import toast from 'react-hot-toast';

const MyProgress = () => {
  const [category, setCategory] = useState([]);
  const [scoreData, setScoreData] = useState(null);
  const [viewType, setViewType] = useState("Table");
  const [selectedFeedbackFor, setSelectedFeedbackFor] = useState({
    id: 'overall',
    title: 'Overall'
  });

  const fetchCategoryList = async () => {
    const result = await getCategoryList('learning-hub');

    if (result?.status) {
      setCategory(result?.data)
    } else {
      toast.error(result?.error);
    }
  };

  const fetchProgressFeedbackScore = async () => {
    const result = await getProgressFeedbackScore(selectedFeedbackFor?.id);

    if (result?.status) {
      setScoreData(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = category.find(cat => cat.id == e.target.value);
    if (selectedCategory) {
      setSelectedFeedbackFor({ id: selectedCategory.id, title: selectedCategory.title });
    } else {
      setSelectedFeedbackFor({ id: 'overall', title: 'Overall' });
    }
  };

  useEffect(() => {
    fetchCategoryList();
    fetchProgressFeedbackScore();
  }, [selectedFeedbackFor]);

  return (
    <div className={styles.myProgress}>
      <div className={styles.myProgressHeader}>
        <div className={styles.headerLeftContainer}>
          <span className={styles.titleAndType}>
            {`Feedback - ${selectedFeedbackFor?.id === "overall" ? "Overall" : selectedFeedbackFor?.title}`}
          </span>
          <span className={styles.feedbackParagraph}>
            Your current progress based on the feedback recieved :
          </span>
        </div>

        <div className={styles.headerRightContainer}>
          <select
            value={selectedFeedbackFor?.id}
            className={styles.categorySelectBox}
            onChange={handleCategoryChange}
          >
            <option value={"overall"}>Overall</option>
            {category?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.title}
              </option>
            ))}
          </select>

          <span
            className={viewType === "Table" ? styles.activeIcon : ""}
            onClick={() => setViewType("Table")}
          >
            <FaTableCells
              fontSize={'1.2rem'}
              color={viewType === "Table" ? "#FFFFFF" : "#7A8688"}
            />
          </span>
          <span
            className={viewType === "Bar Chart" ? styles.activeIcon : ""}
            onClick={() => setViewType("Bar Chart")}
          >
            <VscGraph
              fontSize={'1.2rem'}
              color={viewType === "Bar Chart" ? "#FFFFFF" : "#7A8688"}
            />
          </span>
        </div>
      </div>

      <FeedbackProgress
        scoreData={scoreData}
        viewType={viewType}
        selectedFeedbackFor={selectedFeedbackFor}
      />
    </div>
  );
};

export default MyProgress;