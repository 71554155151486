import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './contexts/AuthContext';
import { MenuProvider } from './contexts/MenuContext';
import { MessagesTabProvider } from './contexts/MessageTabContext';
import { ConnectionRequestProvider } from './contexts/ConnectionRequestContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster position='top-center' reverseOrder={false} />
    <AuthProvider>
      <MenuProvider>
        <MessagesTabProvider>
          <ConnectionRequestProvider>
            <App />
          </ConnectionRequestProvider>
        </MessagesTabProvider>
      </MenuProvider>
    </AuthProvider>
  </>
);