import React, { useEffect, useState } from 'react';
import styles from './SessionDialogBox.module.css';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Close } from '@mui/icons-material';
import { getSpecificSessionDetails } from '../../services/session.service';
import toast from 'react-hot-toast';
import moment from 'moment';

const SessionDialogBox = ({ open, onClose, sessionId }) => {
  const navigate = useNavigate();
  const [session, setSession] = useState({});
  const [remainingTime, setRemainingTime] = useState('');

  const getSessionDetails = async () => {
    const result = await getSpecificSessionDetails(sessionId, 'session');

    if (result.status) {
      setSession(result?.data)
    } else {
      toast.error(result?.error)
    }
  };

  const redirectToSessionRoom = async () => {
    navigate(`/sessions/session-details/${sessionId}`);
  };

  const sessionDateAndTime = () => {
    const startMoment = moment(session?.start_date)
    const endMoment = moment(session?.end_date).add(1, 'minute')

    const formattedStartDate = startMoment.format('dddd, DD MMMM . h:mm A')
    const formattedEndDate = endMoment.format('h:mm A')

    const formattedTimeRange = `${formattedStartDate} - ${formattedEndDate}`
    return formattedTimeRange;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (session?.start_date) {
        const now = moment()
        const startDate = moment(session?.start_date)

        if (startDate > now) {
          const diffDuration = moment.duration(startDate.diff(now))
          const remainingHours = diffDuration.hours()
          const remainingMinutes = diffDuration.minutes()
          const remainingSeconds = diffDuration.seconds()
          setRemainingTime(`${remainingHours}h : ${remainingMinutes}m : ${remainingSeconds}s`)
        } else {
          setRemainingTime('00h : 00m : 00s')
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [session]);

  useEffect(() => {
    if (sessionId) getSessionDetails();
  }, []);

  return (
    <Dialog open={open} maxWidth='sm' fullWidth sx={{ width: '500px', margin: '0px auto' }}>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 4,
          color: theme => theme.palette.grey[800]
        }}
      >
        <Close />
      </IconButton>

      <DialogContent sx={{ padding: '1rem', background: '#ddf2f5' }}>
        <div className={styles.sessionHeaders}>
          <div className={styles.title}>
            <span className={styles.titleMark} />
            <span className={styles.sessionTitle}>{session?.session_name}</span>
          </div>

          <div className={styles.dateAndTimeArea}>
            <div className={styles.sessionDateTime}>{sessionDateAndTime()}</div>
            {remainingTime && <div className={styles.remainingTime}>{remainingTime}</div>}
          </div>
        </div>

        <div className={styles.joinSessionArea}>
          <div className={styles.videoLogo}>
            <VideoCallIcon fontSize='large' htmlColor='green' />
          </div>
          <div className={styles.joinButton} onClick={redirectToSessionRoom}>
            Join Session
          </div>
        </div>

        <div className={styles.sessionSummaryArea}>
          <div className={styles.summaryLogo}>
            <SummarizeIcon fontSize='large' htmlColor='blue' />
          </div>
          <div className={styles.summary}>{session?.description ? session?.description : 'No Description'}</div>
        </div>

        <div className={styles.notificationTimeArea}>
          <div className={styles.notificationLogo}>
            <NotificationsActiveIcon fontSize='large' htmlColor='grey' />
          </div>
          <div className={styles.notification}>
            {session?.notification_before === 'minutes before' && '5 Minutes Before'}
            {session?.notification_before === 'hour before' && '1 Hour Before'}
            {session?.notification_before === 'day before' && '1 Day Before'}
          </div>
        </div>

        <div className={styles.sessionTypeArea}>
          <div className={styles.typeLabel}>Session :</div>
          <div className={styles.sessionType}>{session?.session_type}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SessionDialogBox;