import React from 'react';
import styles from './Exam.module.scss';
import { MenuItem, Select } from '@mui/material';

const ExamInformation = ({ register, watch }) => {
  const yearDropdown = () => {
    const currentYear = new Date().getFullYear();
    const options = [];

    for (let year = currentYear; year >= 2015; year--) {
      options.push(<MenuItem key={year} value={year}>{year}</MenuItem>);
    }
    return options;
  };

  const handleExamDietChange = (event) => {
    const { value, selectionStart } = event.target;
    let newValue = value.replace(/\D/g, '');
    if (newValue.length > 2) {
      let month = parseInt(newValue.substring(0, 2), 10);
      if (month > 12) {
        month = 12;
      }
      newValue = month.toString().padStart(2, '0') + newValue.substring(2);
      newValue = newValue.replace(/(\d{2})/, '$1/');
      if (newValue.includes('/') && newValue.length > 7) {
        newValue = newValue.substring(0, 7);
      }
    }

    const [month, year] = newValue.split('/').map(Number);
    if (month < 1 || month > 12 || year < 0 || year > 99) {
      return;
    }

    event.target.value = newValue;
    const cursorPosition = selectionStart;
    const slashPosition = newValue.indexOf('/');

    if (cursorPosition > slashPosition && cursorPosition !== newValue.length) {
      event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
    } else if (cursorPosition > newValue.length) {
      event.target.setSelectionRange(newValue.length, newValue.length);
    }
  };

  return (
    <div className={styles.examInformation}>
      <div className={styles.content}>
        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}># Part 1 Pass Year</span>
            <Select
              name='pass_year'
              value={watch('pass_year') || ''}
              {...register('pass_year')}
              className={styles.selectBox}
            >
              <MenuItem value="null">Select</MenuItem>
              {yearDropdown()}
            </Select>
          </div>
        </div>

        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Planned # OSCE Exam Diet</span>
            <input
              type='text'
              name='exam_diet'
              placeholder='MM/YYYY'
              {...register('exam_diet')}
              onChange={handleExamDietChange}
              className={styles.inputText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamInformation;