import { axiosInstance } from "../api/Interceptors";
import { ApiUrls } from "../api/apiUrls";

/**
 *
 * @method GET
 * @param { Integer } uid
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Get friends list from comet chat application.
 */
export const getCometFriends = async (uid) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_COMET_CHAT_FRIENDS}/${uid}`);
    return {
      status: true,
      data: result?.data?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } uid 
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Get blocked users list from comet chat application.
 */
export const getBlockedUsers = async (uid) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_COMET_CHAT_BLOCKED_USERS}/${uid}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method DELETE
 * @param { String } blocked_id 
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Unblock the users in comet chat application.
 */
export const unblockUsers = async (blocked_id) => {
  try {
    const result = await axiosInstance.delete(`${ApiUrls.COMET_CHAT_UNBLOCK_USERS}/${blocked_id}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};