import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Message from '@mui/icons-material/Message';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getSpecificUserDetails } from '../../services/user.service';
import {
  removeFriends,
  sendingConnectionRequest,
  cancelConnectionRequest,
  acceptOrDeclineConnectionRequest
} from '../../services/connections.service';
import UserDetailSkeleton from '../../components/SkeletonLoader/userProfileLoader';
import { useParams, useNavigate } from 'react-router-dom';
import { useMessageTab } from '../../hooks/useMessageTab';
import toast from 'react-hot-toast';

const UserDetails = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { setTab } = useMessageTab();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const profileImage = `${process.env.REACT_APP_IMAGE_URL}/users/${userDetail?.profileData?.user_detail?.avatar}`;
  const isImage = userDetail?.profileData?.user_detail?.avatar;
  const open = Boolean(anchorEl);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchSpecificUserData = async () => {
    const result = await getSpecificUserDetails(userId);

    if (result.status) {
      setUserDetail(result?.data);
      setLoading(false);
    } else {
      toast.error(result?.error);
      setLoading(false);
    }
  };

  const buttonType = () => {
    if (userDetail?.requestStatus === 'none') {
      return 'Add connection';
    } else if (userDetail?.requestStatus === 'friends') {
      return 'Friends';
    } else if (userDetail?.requestStatus === 'sent') {
      return 'Cancel request';
    } else if (userDetail?.requestStatus === 'received') {
      return 'Confirm';
    }
  };

  const handleConnectionRequest = async () => {
    if (userDetail?.requestStatus === 'none') {
      const data = { receiver_id: userId };
      const result = await sendingConnectionRequest(data);

      if (result.status) {
        toast.success('Request sent successfully');
        fetchSpecificUserData();
      } else {
        toast.error(result?.error);
      }
    } else if (userDetail?.requestStatus === 'sent') {
      const result = await cancelConnectionRequest(userId);

      if (result?.status) {
        toast.success('Cancel request successfully');
        fetchSpecificUserData();
      } else {
        toast.error(result?.error);
      }
    } else if (userDetail?.requestStatus === 'received') {
      const dataObj = {
        request_id: userDetail?.reqSentId,
        request_status: 'accepted'
      };
      const result = await acceptOrDeclineConnectionRequest(dataObj);

      if (result?.status) {
        toast.success('Request accepted');
        fetchSpecificUserData();
      } else {
        toast.error(result?.error);
      }
    } else {
      toast.error('Invalid request status');
    }
  };

  const handleRemoveFriend = async () => {
    const result = await removeFriends(userId);

    if (result.status) {
      toast.success('Friend removed successfully');
      fetchSpecificUserData();
    } else {
      toast.error(result.error);
    }
  };

  const handleReceivedRequest = async () => {
    const dataObj = {
      request_id: userDetail?.reqSentId,
      request_status: "declined"
    };

    const result = await acceptOrDeclineConnectionRequest(dataObj);

    if (result?.status) {
      toast.success('Request declined');
      fetchSpecificUserData();
    } else {
      toast.error(result?.error);
    }
  };

  const handleMessageClick = (uid) => {
    setTab(0);
    navigate(`/messages?uid=${uid}`);
  };

  useEffect(() => {
    if (userId) fetchSpecificUserData();
  }, [userId]);

  if (loading) {
    return <UserDetailSkeleton />
  }

  return (
    <>
      <Box
        onClick={() => window.history.back()}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <IconButton>
          <ArrowBackIcon htmlColor='#099eaf' />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          border: '1px solid #ced0d4',
          marginTop: '10px',
          borderRadius: '8px',
          overflow: 'hidden',
          background: '#CEEBEE'
        }}
      >
        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
          <Box sx={{ padding: '15px' }}>
            <img
              src={isImage ? profileImage : '../images/avatars/default-avatar.jpg'}
              height={150}
              width={150}
              style={{ borderRadius: '30px', objectFit: 'cover' }}
            />
          </Box>
          <Box>
            <Typography variant='h4' sx={{ color: '#099eaf', fontWeight: 600, fontSize: '28px' }}>
              {`${userDetail?.profileData?.first_name} ${userDetail?.profileData?.last_name}`}
            </Typography>
            <Typography variant='h6' sx={{ color: '#099eaf', fontWeight: 600, }}>
              {userDetail?.profileData?.user_detail?.title}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: '50%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
            padding: '30px',
            position: 'relative'
          }}
        >
          <Button
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'row',
              alignItems: 'center',
              background: '#FFF',
              textTransform: 'none',
              '&:hover': {
                background: '#FFF'
              }
            }}
            onClick={handleConnectionRequest}
            disabled={userDetail?.requestStatus === 'friends'}
          >
            {userDetail?.requestStatus === 'none'
              ? (
                <PersonAddIcon sx={{ color: '#099eaf' }} />
              ) : userDetail?.requestStatus === 'friends' ? (
                <img src='../images/logos/add-friend.png' width={19} height={19} style={{ color: '#099eaf' }} />
              ) : null
            }
            <Typography variant='subtitle1' sx={{ color: '#099eaf', fontWeight: 500 }} fontSize={14}>
              {buttonType()}
            </Typography>
          </Button>

          {userDetail?.requestStatus === 'received' &&
            <Button
              sx={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'row',
                alignItems: 'center',
                background: '#9797a5',
                textTransform: 'none',
                color: '#fff'
              }}
              onClick={handleReceivedRequest}
            >
              Remove
            </Button>
          }

          <Button
            sx={{
              display: 'flex',
              gap: '5px',
              flexDirection: 'row',
              alignItems: 'center',
              background: '#FF5E5C',
              textTransform: 'none',
              '&:hover': {
                background: '#FF5E5C'
              }
            }}
            onClick={() => handleMessageClick(userDetail?.profileData?.id)}
          >
            <Message sx={{ color: '#fff' }} />
            <Typography variant='subtitle1' sx={{ color: '#fff' }} fontSize={14}>
              Message
            </Typography>
          </Button>

          {userDetail?.requestStatus === 'friends' && (
            <>
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem onClick={() => { handleMenuClose(); handleRemoveFriend(); }}>
                  <RemoveCircleIcon sx={{ marginRight: '10px' }} />
                  Remove Connection
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Box >

      <Box sx={{ marginTop: '20px' }}>
        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#FFF', color: '#1F7698' }}>
            <Typography variant='h6'>General Information</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant='subtitle2'>Gender: {userDetail?.profileData?.user_detail?.gender ?? "N/A"}</Typography>
              <Typography variant='subtitle2'>Nationality: {userDetail?.profileData?.user_detail?.nationality ?? "N/A"}</Typography>
              <Typography variant='subtitle2'>Language Spoken: N/A</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#FFF', color: '#1F7698' }}>
            <Typography variant='h6'>Contact Information</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant='subtitle2'>Email: {userDetail?.profileData?.email ?? "N/A"}</Typography>
              <Typography variant='subtitle2'>
                City: {`${userDetail?.profileData?.user_detail?.city ?? 'N/A'}`}
              </Typography>
              <Typography variant='subtitle2'>
                Country: {`${userDetail?.profileData?.user_detail?.country ?? 'N/A'}`}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#FFF', color: '#1F7698' }}>
            <Typography variant='h6'>Work & Study Information</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant='subtitle2'>
                Years of practice: {userDetail?.profileData?.user_detail?.year_of_practice ?? "N/A"}
              </Typography>
              <Typography variant='subtitle2'>
                Work schedule type: {userDetail?.profileData?.user_detail?.work_schedule_type ?? "N/A"}
              </Typography>
              <Typography variant='subtitle2'>
                Available hours: {userDetail?.profileData?.user_detail?.available_study_hour ?? "N/A"}
              </Typography>
              <Typography variant='subtitle2'>
                Preferred study method: {userDetail?.profileData?.user_detail?.preferred_study_method ?? "N/A"}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#FFF', color: '#1F7698' }}>
            <Typography variant='h6'>Exam Information</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant='subtitle2'>
                Part 1 pass year: {userDetail?.profileData?.user_detail?.pass_year ?? "N/A"}
              </Typography>
              <Typography variant='subtitle2'>
                Planned OSCE exam diet: {userDetail?.profileData?.user_detail?.exam_diet ?? "N/A"}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#FFF', color: '#1F7698' }}>
            <Typography variant='h6'>Area of Expertise & Improvement</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant='subtitle2'>
                Strengths: {userDetail?.profileData?.user_detail?.strengths ?? "N/A"}
              </Typography>
              <Typography variant='subtitle2'>
                Area for improvement: {userDetail?.profileData?.user_detail?.area_of_improvement ?? "N/A"}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#FFF', color: '#1F7698' }}>
            <Typography variant='h6'>Additional Information</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant='subtitle2'>
                Professional interests: {userDetail?.profileData?.user_detail?.professional_interests ?? "N/A"}
              </Typography>
              <Typography variant='subtitle2'>
                Previous examination experience: {userDetail?.profileData?.user_detail?.prev_exam_experience ?? "N/A"}
              </Typography>
              <Typography variant='subtitle2'>
                Short bio: {userDetail?.profileData?.user_detail?.short_bio ?? "N/A"}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default UserDetails;