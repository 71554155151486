import React, { useEffect, useState } from 'react';
import styles from './Sent.module.scss';
import { getSentConnectionRequest, cancelConnectionRequest } from '../../../../../services/connections.service';
import Pagination from '../../../../../components/Pagination/index';
import toast from 'react-hot-toast';

const SentRequest = () => {
  const limit = 10;
  const [sentRequest, setSentRequest] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const fetchSentRequest = async (page, limit) => {
    const offset = (page - 1) * limit;
    const result = await getSentConnectionRequest(limit, offset);
    if (result.status) {
      setSentRequest(result?.data);
      setTotalCount(result?.data?.length);
    } else {
      toast.error(result?.error);
    }
  };

  const removeSentRequest = async (receiverId) => {
    const result = await cancelConnectionRequest(receiverId);
    if (result?.status) {
      toast.success('Cancel request successfully');
      await fetchSentRequest(page, limit);
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    fetchSentRequest(page, limit);
  }, [page]);

  return (
    <div className={styles.sentRequest}>
      <div className={styles.sentCardArea}>
        {sentRequest?.length === 0 ? (
          <p className={styles.noSentMsg}>No request available</p>
        ) : (
          sentRequest?.map((item, index) => {
            const image = item?.sentRequestData?.user_detail?.avatar;
            const avatar = `${process.env.REACT_APP_IMAGE_URL}/users/${item?.sentRequestData?.user_detail?.avatar}`;
            return (
              <div className={styles.sentCard} key={index}>
                <div className={styles.imageAndName}>
                  <img
                    src={image ? avatar : '../images/avatars/default-avatar.jpg'}
                    alt='profile'
                    width={130}
                    height={130}
                    style={{ objectFit: 'cover', borderRadius: '30px' }}
                  />

                  <span>{`${item?.sentRequestData?.first_name} ${item?.sentRequestData?.last_name}`}</span>
                </div>

                <div
                  className={styles.cancel}
                  onClick={() => removeSentRequest(item?.receiver_id)}
                >
                  Cancel Request
                </div>
              </div>
            )
          })
        )}
      </div>

      <div className={styles.paginationArea}>
        <Pagination
          page={page}
          count={Math.ceil(totalCount / limit)}
          onPageChange={(e, value) => setPage(value)}
        />
      </div>
    </div>
  );
};

export default SentRequest;