import { ApiUrls } from "../api/apiUrls";
import { axiosInstance } from "../api/Interceptors";

/**
 *
 * @method GET
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Get all the feedback sections and it's details.
 */
export const getFeedbackSections = async () => {
  try {
    const result = await axiosInstance.get(ApiUrls.GET_FEEDBACK_SECTIONS);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method POST
 * @param { Object } data 
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Save the session feedback for each case id.
 */
export const saveSessionFeedback = async (data) => {
  try {
    const result = await axiosInstance.post(ApiUrls.SAVE_SESSION_FEEDBACK, data);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } sessionId
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Get saved feedback details of session by Id.
 */
export const getSessionFeedbackById = async (sessionId, selectedCaseTopic) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_SESSION_FEEDBACK_BY_ID}?sessionId=${sessionId}&caseId=${selectedCaseTopic}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method POST
 * @param { Object } data
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Save the feedback score for each feedback domain with topic id wise.
 */
export const saveTopicFeedbackScore = async (data) => {
  try {
    const result = await axiosInstance.post(ApiUrls.SAVE_TOPIC_FEEDBACK_SCORE, data);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } category_id
 * @returns {Promise<{success: boolean, error: *}|{success: boolean, body: *}>}
 * @comment Get all the progress feedback score.
 */
export const getProgressFeedbackScore = async (category_id = '') => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_PROGRESS_FEEDBACK_SCORE}?catId=${category_id}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};