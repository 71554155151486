import axios from "axios";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { toast } from "react-hot-toast";


export const getToken = () => {
  if (typeof window != 'undefined') {
    const accessToken = localStorage.getItem('access_token');
    return accessToken;
  }
};

export const getAuthorizationHeader = () => {
  const accessToken = getToken();
  return accessToken;
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    authorization: `Bearer ${getAuthorizationHeader()}`
  }
});

axiosInstance.interceptors.request.use(async req => {
  let accessToken = localStorage.getItem('access_token');
  let refreshToken = localStorage.getItem('refresh_token');
  let loginId = localStorage.getItem('login_id');
  req.headers.Authorization = `Bearer ${accessToken}`;
  req.headers.loginId = loginId;

  const user = jwtDecode(accessToken);
  const isExpired = moment.unix(user.exp).diff(moment()) < 1;

  if (!isExpired) return req;
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + 'auth/refreshToken', {
      headers: {
        refreshToken: refreshToken
      }
    });

    if (response.status === 200) {
      accessToken = response?.data?.data?.access_token;
      refreshToken = response?.data?.data?.refresh_token;
      loginId = response?.data?.data?.login_id;
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      req.headers.Authorization = `Bearer ${accessToken}`;
      req.headers.loginId = loginId;
    }
    return req;
  } catch (error) {
    if (error?.response.status === 401) {
      toast.error('Session expired, Please login again!');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login';
    }
  }
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  console.log(error);
  if ((error?.response?.status === 409 && error.response.data.message === "Account already logged in.") ||
    (error?.response?.status === 401)) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('login_id');
    window.location.href = '/login';
  }
  return Promise.reject(error);
});