import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography, Box } from '@mui/material';
import { grade, eachFeedbackSectionScoreForSession } from "../../../../../../utils/feedbackCalculation";

const Score = ({ sessionFeedback, selectedCaseTopic }) => {
  const [score, setScore] = useState();

  const overAllScoreOfSession = () => {
    if (score) {
      const overallScore = (
        parseFloat(score[1]) +
        parseFloat(score[2]) +
        parseFloat(score[3]) +
        (selectedCaseTopic.case_id.includes("CONS") ? 0 : parseFloat(score[4])) +
        parseFloat(score[5])
      ) / (selectedCaseTopic.case_id.includes("CONS") ? 4 : 5);
      return overallScore.toFixed(2);
    } else {
      return null;
    }
  };

  useEffect(() => {
    const result = eachFeedbackSectionScoreForSession(sessionFeedback, 'session-score');
    setScore(result);
  }, [sessionFeedback]);

  return (
    <Card>
      <CardHeader
        sx={{ padding: '16px 0 0 16px' }}
        title={
          <Typography
            variant='h6'
            sx={{
              border: '1px solid #099eaf',
              borderRadius: '3px',
              fontWeight: 500,
              paddingLeft: "10px",
              paddingRight: "30px",
              display: 'inline-block'
            }}>
            Score
          </Typography>
        }
      />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <Box sx={{ p: 2, background: '#099eaf', color: '#FFF', borderRadius: 2 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{"Consultation Skills"} :</Typography>
              <Box sx={{ display: 'flex', gap: '30px' }}>
                <Typography variant='body2'>Grade: {score && score[1] != null ? grade(score[1]) : ''}</Typography>
                <Typography variant='body2'>Score: {score && score[1] != null ? score[1] : ''}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ p: 2, background: '#099eaf', color: '#FFF', borderRadius: 2 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{"Data Gathering Skills"} :</Typography>
              <Box sx={{ display: 'flex', gap: '30px' }}>
                <Typography variant='body2'>Grade: {score && score[2] != null ? grade(score[2]) : ''}</Typography>
                <Typography variant='body2'>Score: {score && score[2] != null ? score[2] : ''}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ p: 2, background: '#099eaf', color: '#FFF', borderRadius: 2 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{"Counselling Skills"} :</Typography>
              <Box sx={{ display: 'flex', gap: '30px' }}>
                <Typography variant='body2'>Grade: {score && score[3] != null ? grade(score[3]) : ''}</Typography>
                <Typography variant='body2'>Score: {score && score[3] != null ? score[3] : ''}</Typography>
              </Box>
            </Box>
          </Grid>
          {!selectedCaseTopic.case_id.includes("CONS") && (
            <Grid item lg={6}>
              <Box sx={{ p: 2, background: '#099eaf', color: '#FFF', borderRadius: 2 }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{"Examination Skills"} :</Typography>
                <Box sx={{ display: 'flex', gap: '30px' }}>
                  <Typography variant='body2'>Grade: {score && score[4] != null ? grade(score[4]) : ''}</Typography>
                  <Typography variant='body2'>Score: {score && score[4] != null ? score[4] : ''}</Typography>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item lg={6}>
            <Box sx={{ p: 2, background: '#099eaf', color: '#FFF', borderRadius: 2 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{"Management and Investigation Skills"} :</Typography>
              <Box sx={{ display: 'flex', gap: '30px' }}>
                <Typography variant='body2'>Grade: {score && score[5] != null ? grade(score[5]) : ''}</Typography>
                <Typography variant='body2'>Score: {score && score[5] != null ? score[5] : ''}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ p: 2, background: '#099eaf', color: '#FFF', borderRadius: 2 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{"Overall Score & Grade"} :</Typography>
              <Box sx={{ display: 'flex', gap: '30px' }}>
                <Typography variant='body2'>Grade: {score && grade(overAllScoreOfSession())}</Typography>
                <Typography variant='body2'>Score: {overAllScoreOfSession()}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Score;