import React from 'react';
import { Typography, Box } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  marginBottom: 10,
  background: 'transparent',
  borderRadius: '5px',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#fff' }} />}
    {...props}
  />

))(({ theme, backgroundColor, summaryWidth, summaryType = '' }) => ({
  maxWidth: summaryType ? summaryWidth : '100%',
  background: backgroundColor || '#69B7C5',
  borderRadius: '5px',
  minHeight: '2rem',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '10px !important',
  width: '100%',
  background: '#fff',
  marginTop: '1rem'
}));

const AccordianBox = ({ title, expand, details, setExpanded, backgroundColor, summaryWidth, summaryType }) => {
  return (
    <Accordion expanded={expand} onChange={() => setExpanded(title)}>
      <AccordionSummary
        id='panel1d-header'
        aria-controls='panel1d-content'
        backgroundColor={backgroundColor}
        summarywidth={summaryWidth}
        summarytype={summaryType}
      >
        <Typography sx={{ color: '#FFF' }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ borderRadius: 2 }}>{details}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordianBox;