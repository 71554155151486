import React, { useEffect, useState } from 'react';
import styles from './FindConnection.module.scss';
import { useNavigate } from 'react-router-dom';
import { IoMdSearch } from "react-icons/io";
import { BsFillFilterCircleFill } from "react-icons/bs";
import { getRandomConnections, sendingConnectionRequest } from '../../../../services/connections.service';
import Debounce from '../../../../components/Debounce/index';
import FindConnectionFilter from './Filter/index';
import toast from 'react-hot-toast';

const FindConnections = () => {
  const navigate = useNavigate();
  const [filterOpen, setFilterOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    title: '',
    gender: '',
    language_spoken: [],
    nationality: '',
    country: '',
    year_of_practice: '',
    work_schedule_type: '',
    available_study_hour: '',
    preferred_study_method: ''
  });

  const handleClearFilters = () => {
    setFilters({
      search: '',
      title: '',
      gender: '',
      language_spoken: [],
      nationality: '',
      country: '',
      year_of_practice: '',
      work_schedule_type: '',
      available_study_hour: '',
      preferred_study_method: ''
    });
  };

  const isAnyFilterActive = () => {
    return (
      filters.search ||
      filters.title ||
      filters.gender ||
      filters.language_spoken.length > 0 ||
      filters.nationality ||
      filters.country ||
      filters.year_of_practice ||
      filters.work_schedule_type ||
      filters.available_study_hour ||
      filters.preferred_study_method
    );
  };

  const debouncedFilter = Debounce({
    delay: 800,
    callback: newSearchTerm => {
      setFilters(prevFilters => ({
        ...prevFilters,
        search: newSearchTerm
      }));
    }
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleLanguageChange = (event) => {
    const selectedLanguages = event.target.value;
    setFilters(prevFilters => ({
      ...prevFilters,
      language_spoken: selectedLanguages
    }));
  };

  const fetchUserList = async () => {
    const queryParams = {};

    if (filters.search) queryParams.search = filters.search;
    if (filters.title) queryParams.title = filters.title;
    if (filters.gender) queryParams.gender = filters.gender;
    if (filters.language_spoken.length > 0) queryParams.language_spoken = filters?.selectedLanguages?.join(', ');
    if (filters.nationality) queryParams.nationality = filters.nationality;
    if (filters.country) queryParams.country = filters.country;
    if (filters.year_of_practice) queryParams.year_of_practice = filters.year_of_practice;
    if (filters.work_schedule_type) queryParams.work_schedule_type = filters.work_schedule_type;
    if (filters.available_study_hour) queryParams.available_study_hour = filters.available_study_hour;
    if (filters.preferred_study_method) queryParams.preferred_study_method = filters.preferred_study_method;

    const queryString = new URLSearchParams(queryParams).toString();
    const result = await getRandomConnections(queryString);

    if (result?.status) {
      setUserList(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  const sendConnectionRequest = async (receiverId) => {
    const result = await sendingConnectionRequest({ receiver_id: receiverId });

    if (result.status) {
      toast.success('Request sent successfully');
      fetchUserList();
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, [filters]);

  return (
    <div className={styles.findConnection}>
      <div className={styles.headingContainer}>
        {isAnyFilterActive()
          ? <h4></h4>
          : <h4 className={styles.heading}>People you may know</h4>
        }

        <div className={styles.searchContainer}>
          <IoMdSearch className={styles.searchIcon} />

          <input type="text" name='search' onChange={e => debouncedFilter(e.target.value)} />

          <BsFillFilterCircleFill
            className={`${isAnyFilterActive() ? styles.filterIconActive : styles.filterIcon}`}
            onClick={() => setFilterOpen(!filterOpen)}
          />
        </div>

        {filterOpen && (
          <FindConnectionFilter
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleClearFilters={handleClearFilters}
            handleLanguageChange={handleLanguageChange}
          />
        )}
      </div>

      <div className={styles.connectionCardArea}>
        {userList?.length === 0 ? (
          <p className={styles.noConnectionMsg}>Not available</p>
        ) : (
          userList?.map((item, index) => {
            const image = item?.user_detail?.avatar;
            const avatar = `${process.env.REACT_APP_IMAGE_URL}/users/${item?.user_detail?.avatar}`;
            return (
              <div className={styles.connectionCard} key={index}>
                <div className={styles.imageAndName}>
                  <img
                    src={image ? avatar : '../images/avatars/default-avatar.jpg'}
                    alt='profile'
                    width={130}
                    height={130}
                    style={{ objectFit: 'cover', borderRadius: '30px' }}
                  />

                  <span className={styles.name}>
                    {`${item?.first_name} ${item?.last_name}`}
                    <span className={styles.title}>{item?.user_detail?.title ?? ""}</span>
                  </span>
                </div>

                <div className={styles.buttons}>
                  <div
                    className={styles.actions}
                    onClick={() => sendConnectionRequest(item?.id)}
                  >
                    Add Connection
                  </div>
                  <div
                    className={styles.actions}
                    onClick={() => navigate(`/user-details/${item?.id}`)}
                  >
                    View Profile
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  );
};

export default FindConnections;