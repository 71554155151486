import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordianBox from '../../../../../../components/AccordionBox/index';
import { getFeedbackSections, getSessionFeedbackById, saveSessionFeedback } from '../../../../../../services/feedback.service';
import {
  Card,
  CardHeader,
  CardContent,
  Chip,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  marginBottom: 15,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const Counselling = (props) => {
  const { selectedCaseTopic, feedbackProgressScore, sessionFeedback, setSessionFeedback, getCaseSelectionData } = props;
  const { sessionId } = useParams();
  const [expanded, setExpanded] = useState('');
  const [feedbackList, setFeedbackList] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [formDirty, setFormDirty] = useState(false);

  const fetchFeedbackList = async () => {
    const result = await getFeedbackSections();

    if (result?.status) {
      let filteredList = result?.data;

      if (selectedCaseTopic?.case_id?.includes('CONS')) {
        filteredList = filteredList?.filter(item => item?.title !== 'Examination Skills');
      }

      setFeedbackList(filteredList);
    } else {
      toast.error(result?.message);
    }
  };

  const handleCheckboxChange = (sectionId, feedbackId) => {
    setFeedbackData(prevFeedbackData => {
      return prevFeedbackData.map(item => {
        if (item.feedback_section_id === sectionId) {
          let updatedFeedbackIds;
          if (item.feedback_id.includes(feedbackId)) {
            updatedFeedbackIds = item.feedback_id.filter(id => id !== feedbackId);
          } else {
            updatedFeedbackIds = [...item.feedback_id, feedbackId];
          }
          return { ...item, feedback_id: updatedFeedbackIds };
        }
        setFormDirty(true);
        return item;
      });
    });
  };

  const fetchSessionFeedbackById = async () => {
    const result = await getSessionFeedbackById(sessionId, selectedCaseTopic?.topic_id);

    if (result?.status) {
      setSessionFeedback(result?.data);
      return result?.data;
    } else {
      toast.error(result?.error);
    }
  };

  const onSubmit = async () => {
    let formattedData;
    const anyCheckboxSelected = feedbackData.some(item => item.feedback_id.length > 0);

    if (anyCheckboxSelected) {
      formattedData = feedbackData?.map(item => ({
        session_id: sessionId,
        category_topic_id: selectedCaseTopic.topic_id,
        feedback_section: {
          feedback_section_id: item.feedback_section_id,
          feedback_id: item.feedback_id
        }
      }));
    } else {
      formattedData = [{
        session_id: sessionId,
        category_topic_id: selectedCaseTopic.topic_id
      }];
    }

    const filteredData = anyCheckboxSelected ? formattedData?.filter(item => item?.feedback_section?.feedback_id?.length > 0) : formattedData;
    const result = await saveSessionFeedback(filteredData);

    if (result?.status) {
      toast.success(result?.data);
      const data = await fetchSessionFeedbackById();
      await feedbackProgressScore(data);
      await getCaseSelectionData();
      setFormDirty(false);
      setExpanded('');
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    if (sessionId && selectedCaseTopic) {
      fetchFeedbackList();
    }
  }, [sessionId, selectedCaseTopic]);

  useEffect(() => {
    if (sessionId && selectedCaseTopic && feedbackList.length > 0) {
      setFeedbackData(
        feedbackList?.map(item => ({
          feedback_section_id: item?.id,
          feedback_id: []
        }))
      );
      fetchSessionFeedbackById();
    }
  }, [sessionId, selectedCaseTopic, feedbackList]);

  useEffect(() => {
    if (sessionFeedback.length > 0) {
      const updatedFeedbackData = [...feedbackData];

      sessionFeedback?.forEach((item) => {
        const { feedback_section_id, details } = item;
        const feedbackIndex = updatedFeedbackData.findIndex(
          (feedback) => feedback.feedback_section_id === feedback_section_id
        );

        if (feedbackIndex !== -1) {
          const existingFeedbackIds = updatedFeedbackData[feedbackIndex].feedback_id;
          if (!existingFeedbackIds.includes(details.id)) {
            updatedFeedbackData[feedbackIndex].feedback_id.push(details.id);
          }
        }
      });

      setFeedbackData(updatedFeedbackData);
    }
  }, [sessionFeedback]);

  return (
    <Card sx={{ mb: 4 }}>
      <CardHeader
        sx={{ padding: '16px 0 0 16px' }}
        title={
          <Typography
            variant='h6'
            sx={{
              border: '1px solid #099eaf',
              borderRadius: '3px',
              fontWeight: 500,
              paddingLeft: '10px',
              paddingRight: '10px',
              display: 'inline-block'
            }}
          >
            Feedback Section
          </Typography>
        }
      />
      <CardContent>
        {feedbackList?.map((item, index) => {
          return (
            <Box key={index}>
              <Accordion expanded={expanded === item?.id}>
                <AccordianBox
                  expand={item?.id === expanded}
                  setExpanded={() => (item?.id === expanded ? setExpanded('') : setExpanded(item?.id))}
                  title={item?.title}
                  backgroundColor={'#099eaf'}
                  details={
                    <>
                      <Chip
                        label='Positive Notes'
                        variant='contained'
                        sx={{
                          mb: 3,
                          background: '#fff',
                          borderRadius: 1,
                          border: '1px solid #6dc56d'
                        }}
                      />
                      <FormGroup>
                        {item?.details?.filter(detail => detail?.feedback_type === 'Positive')?.map((pos, index) => (
                          <FormControlLabel
                            key={index}
                            label={pos?.title}
                            control={
                              <Checkbox
                                name={pos?.title}
                                checked={
                                  feedbackData.find(feedback => feedback.feedback_section_id === item.id)?.feedback_id.includes(pos.id) || false
                                }
                                onChange={() => handleCheckboxChange(item?.id, pos?.id)}
                              />
                            }
                          />
                        ))}
                      </FormGroup>

                      <Chip
                        label='Negative Notes'
                        variant='contained'
                        sx={{
                          mb: 3,
                          mt: 5,
                          background: '#fff',
                          borderRadius: 1,
                          border: '1px solid #f63135'
                        }}
                      />
                      <FormGroup>
                        {item?.details?.filter(detail => detail.feedback_type === 'Negative')?.map((neg, index) => (
                          <FormControlLabel
                            key={index}
                            label={neg?.title}
                            control={
                              <Checkbox
                                name={neg?.title}
                                checked={
                                  feedbackData.find(feedback => feedback.feedback_section_id === item.id)?.feedback_id.includes(neg.id) || false
                                }
                                onChange={() => handleCheckboxChange(item?.id, neg?.id)}
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    </>
                  }
                />
              </Accordion>
            </Box>
          )
        })}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {selectedCaseTopic?.isFeedbackGiven &&
            <Box>
              <Typography
                variant='subtitle1'
                sx={{ fontWeight: 500 }}
              >
                🎉 Feedbacks are submitted for this topic.
              </Typography>
            </Box>
          }
          <Box sx={{ ml: 'auto' }}>
            <Button
              variant='contained'
              onClick={onSubmit}
              disabled={!formDirty}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Counselling;