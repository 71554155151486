import React from 'react';
import styles from './Expertise.module.scss';

const ExpertiseInformation = ({ register }) => {
  return (
    <div className={styles.expertiseInformation}>
      <div className={styles.content}>
        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Strengths</span>
            <textarea
              name='strengths'
              placeholder='Type...'
              {...register('strengths')}
              className={styles.inputText}
            />
          </div>
        </div>

        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Area for Improvement</span>
            <textarea
              name='area_of_improvement'
              placeholder='Type...'
              {...register('area_of_improvement')}
              className={styles.inputText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertiseInformation;