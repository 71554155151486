import React from 'react';
import styles from './CaseCount.module.scss';
import { MdContentPasteSearch } from "react-icons/md";
import { LiaUserNurseSolid } from "react-icons/lia";
import { CiMedicalCase } from "react-icons/ci";

const CaseCount = ({ overViewCount }) => {
  return (
    <div className={styles.caseCount}>
      <div className={styles.heading}>Overview</div>

      <div className={styles.caseCards}>
        <div className={styles.card} style={{ background: '#CEEBEE' }}>
          <div className={styles.logo}>
            <MdContentPasteSearch
              fontSize={'2.5rem'}
              style={{ borderRadius: '50%', background: '#099EAF', color: '#fff', padding: '5px' }}
            />
          </div>

          <div className={styles.detail}>
            <p className={styles.count}>{overViewCount?.caseAttempted}</p>
            <p className={styles.caseHeading}>Total Cases Attempted</p>
          </div>
        </div>

        <div className={styles.card} style={{ background: '#FAE9DD' }}>
          <div className={styles.logo}>
            <LiaUserNurseSolid
              fontSize={'2.5rem'}
              style={{ borderRadius: '50%', background: '#FDA96B', color: '#fff', padding: '5px' }}
            />
          </div>

          <div className={styles.detail}>
            <p className={styles.count}>{overViewCount?.candidate}</p>
            <p className={styles.caseHeading}>As Candidate</p>
          </div>
        </div>

        <div className={styles.card} style={{ background: '#FCECEC' }}>
          <div className={styles.logo}>
            <CiMedicalCase
              fontSize={'2.5rem'}
              style={{ borderRadius: '50%', background: '#E4524F', color: '#fff', padding: '5px' }}
            />
          </div>

          <div className={styles.detail}>
            <p className={styles.count}>{overViewCount?.examiner}</p>
            <p className={styles.caseHeading}>As Examiner</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseCount;