import React, { useState } from 'react';
import styles from './ForgotPassword.module.scss';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useForm } from 'react-hook-form';
import { forgotPassword, resetPassword } from '../../services/user.service';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [showSendOtp, setShowSendOtp] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      otp: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  const handleOtp = async (data) => {
    const otpData = {
      email: data.email
    };

    const result = await forgotPassword(otpData);

    if (result.status) {
      toast.success(result?.data);
      setShowSendOtp(false);
    } else {
      toast.error(result?.error);
    }
  };

  const submitOtp = async (data) => {
    const passwordData = {
      email: data.email,
      otp: parseInt(data.otp),
      new_password: data.newPassword,
      verify_password: data.confirmPassword
    };

    const result = await resetPassword(passwordData);

    if (result.status) {
      toast.success(result?.data);
      navigate('/login');
    } else {
      toast.error(result?.error);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.cardContent}>
        <div className={styles.logo}>
          <img height={50} src='/images/logos/Logo-no-slogan.png' alt='logo' />
        </div>
        <h5 className={styles.title}>Forgot Password?</h5>

        {showSendOtp ? (
          <form autoComplete='off' onSubmit={handleSubmit(handleOtp)}>
            <div className={styles.inputContainer}>
              <label htmlFor="email">Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                name="email"
                autoFocus
                {...register('email', { required: true })}
                error={errors.email}
              />
            </div>

            <button className={styles.button} type='submit'>Send OTP</button>
            <div className={styles.linkContainer}>
              <span>Already have an account?</span>
              <a href='/login' className={styles.link}>Return to login</a>
            </div>
          </form>
        ) : (
          <form autoComplete='off' onSubmit={handleSubmit(submitOtp)}>
            <input
              className={styles.input}
              type='email'
              name='email'
              placeholder='Email'
              {...register('email')}
              disabled
            />

            <input
              className={styles.input}
              type='number'
              name='otp'
              placeholder='OTP'
              {...register('otp', { required: true })}
              error={errors.otp}
            />

            <div className={styles.passwordContainer}>
              <input
                type={showPassword ? 'text' : 'password'}
                name='newPassword'
                className={styles.input}
                placeholder='New Password'
                {...register('newPassword', { required: true })}
                error={errors.newPassword}
              />
              <div className={styles.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </div>
            </div>

            <div className={styles.passwordContainer}>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                name='confirmPassword'
                className={styles.input}
                placeholder='Confirm Password'
                {...register('confirmPassword', { required: true })}
                error={errors.confirmPassword}
              />
              <div className={styles.eyeIcon} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </div>
            </div>

            <button className={styles.button} type='submit' style={{ marginTop: '15px' }}>Submit</button>
            <div className={styles.linkContainer}>
              <span>Already have an account?</span>
              <a href='/login' className={styles.link}>Sign in instead</a>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;