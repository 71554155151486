import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import styles from './Request.module.scss';
import ReceivedRequest from './Received/index';
import SentRequest from './Sent/index';

const ConnectionRequest = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className={styles.requestTabs}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#1EA7B6',
          },
        }}
        sx={{
          "& .MuiTab-root": {
            color: "#",
            fontWeight: 400,
            background: "#CDEAED",
            borderRadius: "20px 20px 0 0",
            textTransform: 'capitalize',
            "&.Mui-selected": {
              color: "#56d1e0",
              fontWeight: 600,
              background: "#fff",
            }
          }
        }}
      >
        <Tab label="Received" sx={{ width: '25%' }} />
        <Tab label="Sent" sx={{ width: '25%' }} />
      </Tabs>

      {tabIndex === 0 && <ReceivedRequest />}
      {tabIndex === 1 && <SentRequest />}
    </div>
  );
};

export default ConnectionRequest;