import React, { useEffect, useState } from 'react';
import styles from './MyConnection.module.scss';
import { useNavigate } from 'react-router-dom';
import { getAllFriends, removeFriends } from '../../../../services/connections.service';
import { useAuth } from '../../../../hooks/useAuth';
import Pagination from '../../../../components/Pagination/index';
import toast from 'react-hot-toast';

const MyConnections = () => {
  const limit = 10;
  const { profile } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [connectionsData, setConnectionsData] = useState([]);

  const fetchFriendList = async (page, limit) => {
    const offset = (page - 1) * limit;
    const result = await getAllFriends(limit, offset);

    if (result.status) {
      setTotalCount(result?.data?.count);
      setConnectionsData(result.data?.rows);
    } else {
      toast.error(result.error);
    }
  };

  const handleUnfollowConnection = async (id) => {
    const result = await removeFriends(id);

    if (result.status) {
      toast.success('Connection removed successfully');
      await fetchFriendList(page, limit);
    } else {
      toast.error(result.error);
    }
  };

  useEffect(() => {
    fetchFriendList(page, limit);
  }, [page]);

  return (
    <div className={styles.myConnection}>
      <h4 className={styles.titleHeading}>My Connections</h4>

      <div className={styles.connectionCardArea}>
        {connectionsData?.length === 0 ? (
          <p className={styles.noConnectionMsg}>No connection available</p>
        ) : (
          connectionsData?.map((connection, index) => {
            const { sender_id, receiver_id, senderData, receiverData } = connection;
            const data = profile?.id === sender_id ? receiverData : profile?.id === receiver_id ? senderData : {};
            const image = data?.user_detail?.avatar;
            const avatar = `${process.env.REACT_APP_IMAGE_URL}/users/${data?.user_detail?.avatar}`;
            return (
              <div className={styles.connectionCard} key={index}>
                <div className={styles.imageAndName}>
                  <img
                    src={image ? avatar : '../images/avatars/default-avatar.jpg'}
                    alt='profile'
                    width={130}
                    height={130}
                    style={{ objectFit: 'cover', borderRadius: '30px' }}
                  />

                  <span>{`${data?.first_name} ${data?.last_name}`}</span>
                </div>

                <div className={styles.buttons}>
                  <div className={styles.actions} onClick={() => handleUnfollowConnection(data?.id)}>Unfollow</div>
                  <div
                    className={styles.actions}
                    onClick={() => navigate(`/user-details/${data?.id}`)}
                  >
                    View Profile
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>

      <div className={styles.paginationArea}>
        <Pagination
          page={page}
          count={Math.ceil(totalCount / limit)}
          onPageChange={(e, value) => setPage(value)}
        />
      </div>
    </div>
  );
};

export default MyConnections;