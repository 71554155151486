import React, { useEffect, useRef, useState } from 'react';
import { Box, CardContent, Grid, Typography, Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import DailyIframe from '@daily-co/daily-js';
import CaseSection from './components/CaseSection/index';
import Counselling from './components/Counselling/index';
import Score from './components/Score/index';
import Timer from '../../../../components/SessionTimer/index';
import {
  getCaseSelectionDetails,
  getSpecificSessionDetails,
  onLeaveSession,
  onRecordingCompleted,
} from "../../../../services/session.service";
import { eachFeedbackSectionScoreForSession } from '../../../../utils/feedbackCalculation';
import { saveTopicFeedbackScore } from '../../../../services/feedback.service';
import { useAuth } from '../../../../hooks/useAuth';
import toast from 'react-hot-toast';

const SessionDetails = () => {
  let callFrame;
  const { profile } = useAuth();
  const { sessionId } = useParams();
  const iframeRef = useRef(null);
  const [session, setSession] = useState(null);
  const [caseSelection, setCaseSelection] = useState(null);
  const [sessionViewType, setSessionViewType] = useState('');
  const [sessionFeedback, setSessionFeedback] = useState([]);
  const [selectedCaseTopic, setSelectedCaseTopic] = useState({
    category_id: 0,
    topic_id: 0,
    case_id: "",
    isFeedbackGiven: false,
  });

  const getCaseSelectionData = async () => {
    const result = await getCaseSelectionDetails(sessionId);

    if (result.status) {
      setCaseSelection(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  const getViewType = () => {
    if (profile?.id === session?.created_by && session?.user_role === 'Examiner') {
      setSessionViewType('Examiner View');
    } else if (profile?.id === session?.created_by && session?.user_role === 'Candidate') {
      setSessionViewType('Candidate View');
    } else if (profile?.id === session?.invited_user && session?.user_role === 'Examiner') {
      setSessionViewType('Candidate View');
    } else if (profile?.id === session?.invited_user && session?.user_role === 'Candidate') {
      setSessionViewType('Examiner View');
    } else {
      setSessionViewType('Observer View');
    }
  };

  const feedbackProgressScore = async (feedback) => {
    try {
      const score = eachFeedbackSectionScoreForSession(feedback, 'session-score');
      const data = {
        session_id: sessionId,
        category_id: selectedCaseTopic?.category_id,
        category_topic_id: selectedCaseTopic?.topic_id,
        invited_user: session?.user_role === "Candidate" ? session?.created_by : session?.invited_user,
        score: {
          1: score[1],
          2: score[2],
          3: score[3],
          4: score[4],
          5: score[5],
        }
      };

      await saveTopicFeedbackScore(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getSessionDetail = async () => {
      const result = await getSpecificSessionDetails(sessionId);
      setSession(result?.data);

      let token;
      if (profile && profile.id) {
        if (profile.id === result?.data?.created_by && result?.data?.user_role === 'Examiner') {
          token = result?.data?.room_token_exam;
        } else if (profile.id === result?.data?.created_by && result?.data?.user_role === 'Candidate') {
          token = result?.data?.room_token_cand;
        } else if (profile.id === result?.data?.invited_user && result?.data?.user_role === 'Examiner') {
          token = result?.data?.room_token_cand;
        } else if (profile.id === result?.data?.invited_user && result?.data?.user_role === 'Candidate') {
          token = result?.data?.room_token_exam;
        } else {
          token = "";
        }
      }

      if (sessionId && profile && profile.id) {
        callFrame = DailyIframe.createFrame(iframeRef?.current, {
          dailyConfig: {
            micAudioMode: 'music'
          },
          showLeaveButton: true,
          showFullscreenButton: true
        });

        callFrame.join({
          url: result?.data?.room_url,
          token: token
        });

        callFrame.on('recording-stopped', async () => {
          await onRecordingCompleted();
        });

        callFrame.on('left-meeting', async () => {
          await onLeaveSession(sessionId);
        });
      }
    };

    if (sessionId) {
      getSessionDetail();
      getCaseSelectionData();
    }

    return () => {
      if (callFrame) {
        callFrame.destroy();
      }
    }
  }, [sessionId, profile]);

  useEffect(() => {
    getViewType();
  }, [profile, session]);

  return (
    <Box>
      <Box
        sx={{
          background: "#1F7698",
          borderRadius: "4px",
          padding: "0px 20px 0px 10px",
          margin: "10px 0 10px 5px",
          display: "inline-block",
          minWidth: "fit-content",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: "#fff",
            fontWeight: "600",
          }}
        >
          {sessionViewType}
        </Typography>
      </Box>

      <Grid container spacing={8} alignItems={"flex-start"}>
        <Grid item lg={8}>
          <Card
            sx={{ background: "black", borderRadius: 2, minHeight: 500, mb: 4 }}
          >
            <CardContent sx={{ position: "relative", height: "500px" }}>
              <div
                id="dailyco-room"
                ref={iframeRef}
                style={{ height: "100%", width: "100%" }}
              />
            </CardContent>
          </Card>

          {sessionViewType === "Examiner View" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  width: "50%",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "black", fontWeight: 600, fontFamily: 'Montserrat' }}
                >
                  Case ID
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    padding: "8px",
                    background: "#dde0e5",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                >
                  {selectedCaseTopic?.case_id}
                </Typography>
              </Box>

              <Timer />
            </Box>
          )}

          {sessionViewType === "Examiner View" && (
            <>
              <Counselling
                selectedCaseTopic={selectedCaseTopic}
                feedbackProgressScore={feedbackProgressScore}
                sessionFeedback={sessionFeedback}
                setSessionFeedback={setSessionFeedback}
                getCaseSelectionData={getCaseSelectionData}
              />

              <Score
                selectedCaseTopic={selectedCaseTopic}
                sessionFeedback={sessionFeedback}
              />
            </>
          )}
        </Grid>

        <Grid item lg={4} sx={{ height: "calc(100vh - 110px)", paddingLeft: '40px !important' }}>
          <CaseSection
            caseSelection={caseSelection}
            sessionViewType={sessionViewType}
            selectedCaseTopic={selectedCaseTopic}
            setSelectedCaseTopic={setSelectedCaseTopic}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SessionDetails;