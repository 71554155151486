import { ApiUrls } from "../api/apiUrls";
import { axiosInstance } from "../api/Interceptors";

/**
 *
 * @method GET
 * @returns {Promise<{status: boolean, data: *} | {status: boolean, error: *}>}
 * @comment Get all the random connections for login users.
 */
export const getRandomConnections = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_RANDOM_FRIENDS}?${filters}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @returns {Promise<{status: boolean, data: *} | {status: boolean, error: *}>}
 * @comment Retrieves all friends.
 */
export const getAllFriends = async (limit, offset) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALL_FRIENDS}?limit=${limit}&offSet=${offset}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method DELETE
 * @param { Integer } senderId
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Removes a friend.
 */
export const removeFriends = async (senderId) => {
  try {
    const result = await axiosInstance.delete(`${ApiUrls.REMOVE_FRIENDS}/${senderId}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method POST
 * @param { Object } data
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Sending the connection request.
 */
export const sendingConnectionRequest = async (data) => {
  try {
    const result = await axiosInstance.post(ApiUrls.SEND_CONNECTION_REQUEST, data);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get received connection request.
 */
export const getReceivedConnectionRequest = async (limit, offset) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_RECEIVED_CONNECTION_REQUESTS}?limit=${limit}&offSet=${offset}`);
    return {
      status: true,
      data: result?.data?.data?.rows
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get sent connection request.
 */
export const getSentConnectionRequest = async (limit, offset) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_SENT_CONNECTION_REQUEST}?limit=${limit}&offSet=${offset}`);
    return {
      status: true,
      data: result?.data?.data?.rows
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @param { Object } data
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Accept or Decline received connection request.
 */
export const acceptOrDeclineConnectionRequest = async (data) => {
  try {
    const result = await axiosInstance.patch(ApiUrls.ACCEPT_DECLINE_CONNECTION_REQUEST, data);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method DELETE
 * @param { Integer } receiverId
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Cancel the sent request to user.
 */
export const cancelConnectionRequest = async (receiverId) => {
  try {
    const result = await axiosInstance.delete(`${ApiUrls.CANCEL_CONNECTION_REQUEST}/${receiverId}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};
