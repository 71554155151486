import React, { useState, useEffect } from 'react';
import { IconButton, Container, Typography, Box } from '@mui/material';
import { PlayArrow, Pause, Replay } from '@mui/icons-material';

const Timer = () => {
  const [timerTime, setTimerTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const toggleTimer = () => {
    setIsRunning(prev => !prev);
  };

  const reset = () => {
    setTimerTime(0);
  };

  const pad = (number) => {
    return number < 10 ? '0' + number : number;
  };

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setTimerTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning]);

  const numberMinutes = Math.floor(timerTime / 60);
  const numberSeconds = timerTime % 60;

  return (
    <Container
      sx={{
        display: 'flex',
        margin: '0px !important',
        padding: '0px !important',
        justifyContent: 'flex-end',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '5px',
          color: '#fff',
          background: 'red',
          fontSize: '20px',
          borderRadius: '5px',
          marginRight: '5px'
        }}
      >
        <Typography variant='h5' className='minutes' sx={{ color: '#fff' }}>
          {pad(numberMinutes)}
        </Typography>
        :
        <Typography variant='h5' className='seconds' sx={{ color: '#fff' }}>
          {pad(numberSeconds)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton onClick={toggleTimer} color='primary'>
          {isRunning ? <Pause /> : <PlayArrow />}
        </IconButton>
        <IconButton onClick={reset} color='error'>
          <Replay />
        </IconButton>
      </Box>
    </Container>
  );
};

export default Timer;