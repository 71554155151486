import React, { useEffect, useState } from 'react';
import styles from './Notifications.module.scss';
import { Switch } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { updateEmailSetting } from '../../../../services/user.service';
import { useAuth } from '../../../../hooks/useAuth';
import toast from 'react-hot-toast';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const Notifications = () => {
  const { profile, getUserProfile } = useAuth();
  const settingValue = profile?.user_detail?.send_email_setting === "Yes" ? true : false;
  const [emailSetting, setEmailSetting] = useState(settingValue);

  const handleChange = async (newValue) => {
    const result = await updateEmailSetting(newValue);

    if (result.status) {
      toast.success(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  const handleToggleChange = (event) => {
    const newValue = event.target.checked;
    setEmailSetting(newValue);
    handleChange(newValue);
  };

  useEffect(() => {
    getUserProfile();
  }, [emailSetting]);

  return (
    <div className={styles.notifications}>
      <h5 className={styles.heading}>Notifications</h5>

      <div className={styles.contentArea}>
        <div className={styles.content}>
          <span className={styles.emailHeading}>Receive email notifications</span>
          <PinkSwitch
            name='email_notification'
            checked={emailSetting}
            onChange={handleToggleChange}
            size='medium'
            sx={{ background: '#FFF', borderRadius: '8px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Notifications;