import { CometChatUIKit, UIKitSettingsBuilder } from "@cometchat/chat-uikit-react";
const APP_ID = process.env.REACT_APP_COMET_CHAT_APP_ID;
const REGION = process.env.REACT_APP_COMET_CHAT_REGION;
const AUTH_KEY = process.env.REACT_APP_COMET_CHAT_AUTH_KEY;

const initializeCometApp = async () => {
  try {
    const UIKitSettings = new UIKitSettingsBuilder()
      .setAppId(APP_ID)
      .setRegion(REGION)
      .setAuthKey(AUTH_KEY)
      .subscribePresenceForAllUsers()
      .build();

    await CometChatUIKit.init(UIKitSettings);
    console.log("Initialization completed successfully");
    const cometChatUID = localStorage.getItem("comet_uid");
    await loginCometUser(cometChatUID);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 *
 * @param { Integer } uid
 * @comment This function is used to login in comet chat application.
 */
const loginCometUser = async (uid) => {
  try {
    const user = await CometChatUIKit.getLoggedinUser();

    if (user) {
      await CometChatUIKit.logout();
    }

    const loggedInUser = await CometChatUIKit.login(uid, AUTH_KEY);

    if (loggedInUser) {
      localStorage.setItem('comet_auth_token', loggedInUser?.authToken);
      console.log('Login successful');
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Login failed', error);
    return false;
  }
};

export { initializeCometApp, loginCometUser };