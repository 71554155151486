import React, { useEffect, useState } from "react";
import styles from "./LearningHub.module.scss";
import { useNavigate } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";
import { getCategoryList } from "../../services/learningHub.service";
import toast from "react-hot-toast";
import Debounce from "../../components/Debounce";

const LearningHub = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [categoryList, setCategoryList] = useState([]);

  const getAllCategories = async () => {
    const result = await getCategoryList("learning-hub", search);

    if (result.status) {
      setCategoryList(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  const handleClick = (catTitle, catId) => {
    navigate(`/learning-hub/${catTitle}?catId=${catId}`);
  };

  const debouncedFilter = Debounce({
    delay: 800,
    callback: newSearchTerm => {
      setSearch(newSearchTerm);
    }
  });

  useEffect(() => {
    getAllCategories();
  }, [search]);

  return (
    <div className={styles.learningHub}>
      <div className={styles.header}>
        <h5>Categories</h5>
        <input
          type="text"
          placeholder="Type here to search...."
          onChange={e => debouncedFilter(e.target.value)}
        />
        <IoMdSearch className={styles.searchIcon} />
      </div>

      <div className={styles.content}>
        {categoryList?.map((category, index) => {
          const IMAGE_URL = `${process.env.REACT_APP_IMAGE_URL}/category/${category?.image}`;
          return (
            <div
              key={index}
              className={styles.categoryCard}
              onClick={() => handleClick(category?.title, category?.id)}
            >
              <div className={styles.categoryImage}>
                <img
                  src={IMAGE_URL}
                  alt="category-image"
                  width={132}
                  height={132}
                />
              </div>
              <span>{category?.title}</span>

              <div className={styles.opacityCard}></div>
              <img
                src={IMAGE_URL}
                alt="category-image"
                className={styles.opacityImage}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LearningHub;