import React from 'react';
import styles from './ProfileOverview.module.scss';
import RoomIcon from '@mui/icons-material/Room';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';

const ProfileOverview = () => {
  const navigate = useNavigate();
  const { profile } = useAuth();
  const ImageUrl = `${process.env.REACT_APP_IMAGE_URL}/users/${profile?.user_detail?.avatar}`;

  return (
    <div className={styles.profileOverview}>
      <div className={styles.profileDetails}>
        <img
          src={
            profile?.user_detail?.avatar
              ? ImageUrl
              : "../images/avatars/default-avatar.jpg"
          }
          alt="profile"
          style={{
            width: 80,
            height: 80,
            objectFit: "contain",
            borderRadius: "50%",
            border: "1px solid #8bc7c1",
          }}
        />

        <div className={styles.details}>
          <p className={styles.title}>{`${profile?.first_name} ${profile?.last_name}`}</p>
          <p className={styles.location}>
            <RoomIcon htmlColor="#099EAF" sx={{ fontSize: "16px" }} />
            {profile?.user_detail?.city &&
              `${profile?.user_detail?.city},`}{" "}
            {profile?.user_detail?.country && profile?.user_detail?.country}
          </p>
        </div>
      </div>

      <div className={styles.buttons}>
        <div
          className={styles.viewProfile}
          onClick={() => navigate("/settings/my-profile")}
        >
          View Profile
        </div>
        <div
          className={styles.myProgress}
          onClick={() => navigate("/my-progress")}
        >
          My Progress
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;