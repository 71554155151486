import React, { useEffect, useState } from 'react';
import styles from './Invitation.module.scss';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useAuth } from '../../../../hooks/useAuth';
import { getInvitationNotification, updateSessionStatus } from '../../../../services/session.service';
import toast from 'react-hot-toast';
import moment from 'moment';

const InvitationList = ({ getSessionList }) => {
  const { profile } = useAuth();
  const [invitationList, setInvitationList] = useState([]);

  const invitedFor = (invited_user, user_role) => {
    if (profile?.id === invited_user && user_role === 'Examiner') {
      return 'Invitation for Candidate Role';
    } else if (profile?.id === invited_user && user_role === 'Candidate') {
      return 'Invitation for Examiner Role';
    } else {
      return 'Invitation for Observer Role';
    }
  };

  const fetchInvitationNotification = async () => {
    const result = await getInvitationNotification();

    if (result?.status) {
      const resultData = result?.data?.map((item) => (
        {
          id: item?.id,
          title: item?.session_name,
          dateTime: moment(item?.createdAt).fromNow(),
          message: item?.description,
          startDate: moment(item?.start_date).format('ddd, D MMMM'),
          startTime: moment(item?.start_date).format('h:mm A'),
          endTime: moment(item?.end_date).add(1, 'minute').format("h:mm A"),
          invited_user: item?.invited_user,
          created_by: item?.created_by,
        }
      ));

      const filteredResultData = resultData?.filter((item) => profile?.id !== item?.created_by);

      setInvitationList(filteredResultData);
    } else {
      toast.error(result?.error);
    }
  };

  const updateSessionStatusByUser = async (status, sessionId, statusFrom) => {
    const result = await updateSessionStatus(status, sessionId, statusFrom);

    if (result?.status) {
      toast.success('Success');
      fetchInvitationNotification();
      getSessionList('upcoming');
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    if (profile) fetchInvitationNotification();
  }, [profile]);

  return (
    <div className={styles.invitations}>
      <h4 className={styles.titleHeading}>Invitations</h4>

      {invitationList?.length === 0 ? (
        <p className={styles.noInvitationMsg}>No invitation available</p>
      ) : (
        invitationList?.map((alert, index) => {
          return (
            <div className={styles.invitationCard} key={index}>
              <div className={styles.titleAndAgo}>
                <span className={styles.title}>{alert?.title}</span>
                <span className={styles.ago}>{alert?.dateTime}</span>
              </div>

              <div className={styles.dateAndtime}>
                <span className={styles.calendarSchedule}><CalendarMonthOutlinedIcon fontSize='small' />
                  {alert?.startDate}
                </span>
                <span className={styles.calendarSchedule}><ScheduleIcon fontSize='small' />
                  {alert?.startTime} - {alert?.endTime}
                </span>
              </div>

              <div className={styles.description}>
                {alert?.message}
              </div>

              <div className={styles.buttons}>
                <button
                  className={styles.accept}
                  onClick={() => updateSessionStatusByUser(1, alert?.id, profile?.id === alert?.invited_user ? 'InvitedUser' : 'Observer')}
                >
                  Accept
                </button>
                <button
                  className={styles.decline}
                  onClick={() => updateSessionStatusByUser(0, alert?.id, profile?.id === alert?.invited_user ? 'InvitedUser' : 'Observer')}
                >
                  Decline
                </button>
              </div>
            </div>
          )
        })
      )}
    </div>
  );
};

export default InvitationList;