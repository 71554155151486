import { Box, CardContent, Typography, Button } from '@mui/material';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  border: 'none',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4
};

const CommonDeleteModal = ({ open, handleClose, id, title, handleDelete }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <CardContent>
            <Typography
              variant='h6'
              sx={{ marginTop: '1rem', fontWeight: '600', textAlign: 'center' }}
              color='text.primary'
            >
              Are you sure you want to delete this {title}?
            </Typography>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', gap: '15px' }}>
              <Button sx={{}} variant='contained' onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type='submit'
                sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'red' } }}
                variant='contained'
                onClick={() => handleDelete(id)}
              >
                Delete
              </Button>
            </Box>
          </CardContent>
        </Box>
      </Modal>
    </div>
  );
};

export default CommonDeleteModal;