import React, { useEffect, useState } from 'react';
import styles from './CandidateFeedback.module.scss';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getCandidateSessionLogs } from '../../../../services/session.service';
import toast from 'react-hot-toast';
import moment from 'moment';

const CandidateFeedback = () => {
  const navigate = useNavigate();
  const [sessionLogs, setSessionLogs] = useState([]);

  const fetchSessionLogs = async () => {
    const result = await getCandidateSessionLogs();

    if (result.status) {
      setSessionLogs(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    fetchSessionLogs();
  }, []);

  return (
    <div className={styles.candidateFeedback}>
      <div className={styles.heading}>Latest Candidate Feedback</div>

      <div className={styles.feedbackTable}>
        <TableContainer component={Paper} className={styles.feedbackTable}>
          <Table>
            <TableHead sx={{ background: '#FAE9DD' }}>
              <TableRow>
                <TableCell align='center' sx={{ fontWeight: 550 }}>Sr No</TableCell>
                <TableCell align='center' sx={{ fontWeight: 550 }}>Session Name</TableCell>
                <TableCell align='center' sx={{ fontWeight: 550 }}>Session Date</TableCell>
                <TableCell align='center' sx={{ fontWeight: 550 }}>Number Of Cases</TableCell>
                <TableCell align='center' sx={{ fontWeight: 550 }}>Feedback Comments</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sessionLogs?.rows?.length > 0 ? (
                sessionLogs?.rows.map((row, index) => (
                  <TableRow key={index} sx={{ background: '#EFFCFF' }}>
                    <TableCell align='center'>{index + 1}</TableCell>
                    <TableCell align='center'>{row.session_name}</TableCell>
                    <TableCell align='center'>{moment(row?.start_date).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align='center'>{row?.case_selection_topics?.length}</TableCell>
                    <TableCell
                      align='center'
                      sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                      onClick={() => navigate(`/my-progress/session-feedback-details/${row?.id}`)}
                    >
                      View Feedback
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align='center'>No feedback available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CandidateFeedback;