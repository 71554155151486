/**
 * 
 * @param { Integer } score 
 * @returns String
 * @comment This function is used to get grade based on section value.
 */
export function grade(score) {
  score = parseFloat(score);
  switch (true) {
    case score > 7.9:
      return "Excellent";
    case score > 4.9 && score <= 7.9:
      return "Competent";
    case score > 2.9 && score <= 4.9:
      return "Unsatisfactory";
    case score >= 0 && score <= 2.9:
      return "Poor";
    default:
      return "";
  };
};

/**
 *
 * @param { Object } feedbackData
 * @returns Object
 * @comment This function is used to calculate the score of each feedback section in a session.
 */
export const eachFeedbackSectionScoreForSession = (feedbackData, type) => {
  let pointsBySection = {};
  let averageScores = {};

  feedbackData?.forEach(feedback => {
    let sectionId;
    if (type === "my-progress") {
      sectionId = feedback?.details?.feedback_section_id;
    } else {
      sectionId = feedback?.feedback_section_id;
    }
    const point = feedback?.details?.point;

    if (!pointsBySection[sectionId]) {
      pointsBySection[sectionId] = { positivePoints: 0, negativePoints: 0 };
    }

    if (feedback?.details?.feedback_type === "Positive") {
      pointsBySection[sectionId].positivePoints += point;
    } else if (feedback.details.feedback_type === "Negative") {
      pointsBySection[sectionId].negativePoints += point;
    }

    pointsBySection[sectionId].count++;
  });

  for (const sectionId in pointsBySection) {
    const section = pointsBySection[sectionId];
    const totalScore = section.positivePoints - section.negativePoints;
    const averageScore = totalScore;
    averageScores[sectionId] = averageScore.toFixed(2);
  };

  return averageScores;
};