import { ApiUrls } from "../api/apiUrls";
import { axiosInstance } from "../api/Interceptors";

/**
 *
 * @method GET
 * @param { Object } params
 * @param { Integer } catId
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all the clinical system.
 */
export const getAllClinicalSystem = async (catId) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALL_CLINICAL_SYSTEM}/${catId}?limit=25&offSet=0`);
    return {
      status: true,
      data: result?.data?.data?.rows
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};