import { useEffect, useState } from 'react';
import { getSessionsList } from '../../services/session.service';
import toast from 'react-hot-toast';

const useSessionList = (initialType) => {
  const [sessionList, setSessionList] = useState([]);

  const getSessionList = async (type) => {
    try {
      const result = await getSessionsList(type);

      if (result.status) {
        setSessionList(result?.data);
      } else {
        toast.error(result?.error);
      }
    } catch (error) {
      console.error('Error fetching session list:', error);
      toast.error('An error occurred while fetching session list');
    }
  };

  useEffect(() => {
    getSessionList(initialType);
  }, [initialType]);

  return { sessionList, getSessionList };
};

export default useSessionList;