
import HouseIcon from "@mui/icons-material/House";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { FaLink } from "react-icons/fa6";
import { IoBookOutline } from "react-icons/io5";
import { TbProgressCheck } from "react-icons/tb";
import { RiSettingsLine } from "react-icons/ri";
import { getCategoryList } from "../services/learningHub.service";
import toast from "react-hot-toast";

export const initialNavigation = [
  {
    id: 1,
    title: 'Dashboard',
    path: '/',
    icon: <HouseIcon />
  },
  {
    id: 2,
    title: 'Calendar',
    path: '/calendar',
    icon: <CalendarMonthOutlinedIcon />
  },
  {
    id: 3,
    title: 'Messages',
    path: '/messages',
    icon: <ChatOutlinedIcon />
  },
  {
    id: 4,
    title: 'Connections',
    path: '/connections',
    icon: <FaLink fontSize={"1.5rem"} />,
    children: [
      {
        id: 1,
        title: 'Find Connections',
        path: '/find-connections'
      },
      {
        id: 2,
        title: 'Requests',
        path: '/requests'
      },
      {
        id: 3,
        title: 'My Connections',
        path: '/my-connections'
      }
    ]
  },
  {
    id: 5,
    title: 'Sessions',
    path: '/sessions',
    icon: <VideocamOutlinedIcon />
  },
  {
    id: 6,
    title: 'Learning Hub',
    path: '/learning-hub',
    icon: <IoBookOutline fontSize={"1.5rem"} />,
    children: []
  },
  {
    id: 7,
    title: 'My Progress',
    path: '/my-progress',
    icon: <TbProgressCheck fontSize={"1.5rem"} />
  },
  {
    id: 8,
    title: 'Settings',
    path: '/settings',
    icon: <RiSettingsLine fontSize={"1.5rem"} />,
    children: [
      {
        id: 1,
        title: 'My Profile',
        path: '/my-profile'
      },
      {
        id: 2,
        title: 'Notifications',
        path: '/notifications'
      },
      {
        id: 3,
        title: 'Billing',
        path: '/billing'
      }
    ]
  }
];

export const getAllCategories = async (setNavigation) => {
  const result = await getCategoryList("learning-hub");

  if (result?.status) {
    setNavigation((prevNavigation) => {
      return prevNavigation?.map((menu) => {
        if (menu.title === "Learning Hub") {
          return {
            ...menu,
            children: result?.data?.map((item) => ({
              id: item?.id,
              title: item?.title,
              path: `/${item?.title}?catId=${item?.id}`,
            })),
          };
        }
        return menu;
      });
    });
  } else {
    toast.error(result?.error);
  }
};