import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { eachFeedbackSectionScoreForSession, grade } from '../../../../utils/feedbackCalculation';
import { getRecordingFile } from '../../../../services/session.service';
import download from 'downloadjs';
import moment from 'moment';

const SessionLogTable = ({ rows, count }) => {
  const candidateColumns = [
    { id: 'sr_no', label: 'Sr No', minWidth: 100 },
    { id: 'session_name', label: 'Session Name', minWidth: 120 },
    { id: 'session_date', label: 'Session Date', minWidth: 120 },
    { id: 'number_of_cases', label: 'Number Of Cases', minWidth: 120, align: 'center' },
    { id: 'overall_feedback', label: 'Overall Feedback', minWidth: 120, align: 'center' },
    { id: 'session_recording', label: 'Session Recordings', minWidth: 120, align: 'center' }
  ];

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const overAllSessionFeedbackScore = (feedback) => {
    const averageScore = eachFeedbackSectionScoreForSession(feedback, 'my-progress');

    let sum = 0;
    let count = 0;
    let overallScore;
    for (const key in averageScore) {
      if (averageScore.hasOwnProperty(key)) {
        sum += parseFloat(averageScore[key]);
        count++;
      }
    };

    rows?.forEach((item) => {
      overallScore = (sum / item?.case_selection_topics?.length) / count;
    });
    return overallScore.toFixed(2);
  };

  const handleDownload = async (sessionId) => {
    try {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [sessionId]: true
      }));

      const response = await getRecordingFile(sessionId);
      if (response?.status) {
        const { room_name, recording_file } = response?.data;
        const fullUrl = `${process.env.NEXT_PUBLIC_IMAGE_URL}${recording_file}`;
        download(fullUrl, `${room_name}.mp3`, 'audio/mpeg');

        setLoading((prevLoading) => ({
          ...prevLoading,
          [sessionId]: false
        }));
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading((prevLoading) => ({
        ...prevLoading,
        [sessionId]: false
      }));
    }
  };

  const redirectToSessionFeedbackDetail = (sessionId) => {
    navigate(`/my-progress/session-feedback-details/${sessionId}`);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow sx={{
              "& th": {
                color: 'white',
                backgroundColor: "#009eaf"
              }
            }}>
              {candidateColumns?.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                key={index}
                tabIndex={-1}
                onClick={() => redirectToSessionFeedbackDetail(row?.id)}
                sx={{
                  cursor: 'pointer', "& td": { color: '#000 !important' }
                }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.session_name}</TableCell>
                <TableCell>{moment(row?.start_date).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='center'>{row?.case_selection_topics?.length}</TableCell>
                <TableCell align='center'>{grade(overAllSessionFeedbackScore(row?.feedback))}</TableCell>
                <TableCell align='center' onClick={(e) => { e.stopPropagation(); handleDownload(row?.id) }}>
                  {loading[row?.id]
                    ? 'Downloading...'
                    : <FileDownloadOutlinedIcon fontSize='large' htmlColor='#2aaade' />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component='div'
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[]}
      />
    </Paper>
  );
};

export default SessionLogTable;