import { useState, useEffect } from 'react';

const Debounce = ({ delay, callback }) => {
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  }, [timer])

  const debounceCallback = (...args) => {
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      callback(...args)
    }, delay)
    setTimer(newTimer)
  }

  return debounceCallback
};

export default Debounce;