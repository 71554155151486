import React from 'react';
import styles from './Groups.module.scss';
import { CometChatGroupsWithMessages } from '@cometchat/chat-uikit-react';

const Groups = () => {
  return (
    <div className={styles.groups}>
      <CometChatGroupsWithMessages
        messageText='Please select a group to start messaging'
        groupsWithMessagesStyle={{
          width: '100%',
          height: '100%',
          border: "1px solid #00000024",
          borderRadius: '2px',
        }}
      />
    </div>
  );
};

export default Groups;