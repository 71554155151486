import { ApiUrls } from "../api/apiUrls";
import { axiosInstance } from "../api/Interceptors";

/**
 *
 * @method GET
 * @param { Object } params
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the list of sessions.
 */
export const getSessionsList = async (type = '') => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALL_SESSIONS}?type=${type}&limit=25&offSet=0`);

    return {
      status: true,
      data: result?.data?.data?.rows
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } sessionId
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the specific session details by session id.
 */
export const getSpecificSessionDetails = async (sessionId, type = '') => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_SPECIFIC_SESSION_DETAIL}/${sessionId}?type=${type}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 * 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the invitation notification list.
 */
export const getInvitationNotification = async () => {
  try {
    const result = await axiosInstance.get(ApiUrls.GET_INVITATION_NOTIFICATION);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method POST
 * @param { Object } data 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Create the sessions.
 */
export const createSession = async (data) => {
  try {
    const result = await axiosInstance.post(`${ApiUrls.CREATE_SESSIONS}`, data);
    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method DELETE
 * @param { Integer } sessionId
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Delete the session.
 */
export const deleteSession = async (sessionId) => {
  try {
    const result = await axiosInstance.delete(`${ApiUrls.DELETE_SESSION}/${sessionId}`);
    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } sessionId
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the case selection details to show the list in session details page.
 */
export const getCaseSelectionDetails = async (sessionId) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_CASE_SELECTION_DETAILS}/${sessionId}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @param { Object } data
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Marked as completed for case topics.
 */
export const topicsMarkedAsCompleted = async (data) => {
  try {
    const result = await axiosInstance.patch(`${ApiUrls.GET_CASE_SELECTION_DETAILS}`, data);
    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Object } params 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get Candidate Session Logs
 */
export const getCandidateSessionLogs = async (params) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_CANDIDATE_SESSION_LOG}?limit=5&offSet=0`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } session_id 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get candidate specific session logs.
 */
export const getCandidateSpecificSessionLogs = async (session_id) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_CANDIDATE_SPECIFIC_SESSION_LOG}/${session_id}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Save recording id when recording stop.
 */
export const onRecordingCompleted = async () => {
  try {
    const result = await axiosInstance.patch(`${ApiUrls.ON_RECORDING_COMPLETED}`);
    return {
      status: true,
      data: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @param { Integer } session_id
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Save date and time when session end.
 */
export const onLeaveSession = async (session_id) => {
  try {
    const result = await axiosInstance.patch(`${ApiUrls.ON_LEAVE_SESSION}/${session_id}`);
    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @param { Integer } session_status
 * @param { Integer } session_id
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the session status by invited user (Accepted/Rejected).
 */
export const updateSessionStatus = async (session_status, session_id, statusFrom) => {
  try {
    const result = await axiosInstance.patch(`${ApiUrls.UPDATE_SESSION_STATUS}/${session_id}?sessionStatus=${session_status}&statusFrom=${statusFrom}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @param { Integer } session_id 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get session recording in mp3 format.
 */
export const getRecordingFile = async (session_id) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_SESSION_RECORDING_FILE}/${session_id}`);
    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};