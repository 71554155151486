import { ApiUrls } from "../api/apiUrls";
import { axiosInstance } from "../api/Interceptors";

/**
 *
 * @method GET
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get dashboard overview data.
 */
export const getDashBoardOverView = async () => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_DASHBOARD_OVERVIEW}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};