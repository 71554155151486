import React, { useEffect, useRef, useState } from 'react';
import styles from './AppNavBar.module.scss';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { logOut } from '../../services/user.service';
import { useAuth } from "../../hooks/useAuth";
import { useMenu } from '../../hooks/useMenu';
import toast from 'react-hot-toast';

const AppNavBar = () => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { selectedMenu } = useMenu();
  const { profile, setIsAuthenticated, getUserProfile } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const logoutUser = async () => {
    const result = await logOut();

    if (result?.status) {
      setIsAuthenticated(false);
      localStorage.clear();
      navigate('/login');
    } else {
      toast.error('An error occurred');
      console.error('Logout failed:', result?.error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className={styles.appNavBar}>
      <div className={styles.content}>
        <div className={styles.menuTitle}>
          <p>{selectedMenu}</p>
        </div>

        <div className={styles.profileMenu} onClick={toggleMenu} ref={menuRef}>
          <div className={styles.avatarContainer}>
            <Avatar
              src={`${process.env.REACT_APP_IMAGE_URL}/users/${profile?.user_detail?.avatar}`}
              alt={profile?.first_name}
              sx={{ height: '40px', width: '40px', border: '1px solid #0e8fa1' }}
            />
            <span className={styles.badgeDot}></span>
          </div>

          <div className={styles.profileName}>
            <span style={{ fontSize: '12px', fontWeight: 600 }}>{`${profile?.first_name} ${profile?.last_name}`}</span>
            <span style={{ fontSize: '10px', color: '#0e8fa1' }}>Available</span>
          </div>

          {isMenuOpen && (
            <div className={styles.menu}>
              <div className={styles.menuItem} onClick={() => navigate("/settings/my-profile")}>
                <PersonOutlineOutlinedIcon fontSize='medium' />
                <span style={{ fontSize: '16px', fontWeight: 500 }}>My Profile</span>
              </div>
              <div className={styles.menuItem} onClick={logoutUser}>
                <LoginOutlinedIcon fontSize='small' />
                <span style={{ fontSize: '16px', fontWeight: 500 }}>Logout</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppNavBar;