import React from "react";
import styles from "./TipsAndGuideline.module.scss";
import { Typography } from "@mui/material";

const TipsAndGuidelines = () => {
  return (
    <div className={styles.tipsAndGuidelines}>
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        Tips & Guidelines for a Productive Session on OSCE connect
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Preparation is Essential:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Familiarize yourself with the OSCE scenario, station requirements,
            and expected responses.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Review the guidelines on OSCE connect to understand the platform's
            features and tools.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Standardize Patient Encounters:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Ensure that simulated patients (SPs) scenario is followed to
            maintain consistency in their portals.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Use the same set of instructions and questions provided to ensure
            maximum benefit.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Stay on Time:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            OSCE stations typically has fixed durations. Start and end
            punctually to the examination environment.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Provide Constructive Feedback:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            After the assessment, offer concise and actionable feedback.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Highlight areas of strength and areas that need improvement, guiding
            your OSCE partner on how to enhance their skills.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Ensure a Safe and Respectful Environment:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            OSCEs can be stressful for participants. Create a comfortable
            setting, free of distractions.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Ensure that feedback is given is a constructive and non-threatening
            manner
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Use OSCEconnect Tolls Effectively:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Make use of any digital tools provided by OSCE connect for scoring,
            feedback or time-keeping.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Familiarize yourself with the platform's featured to troubleshoot
            any potential tech issues swiftly.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Seek Feedback on the Session:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Encourage each other to give feedback on the session's organization
            and delivery. This can help improve future OSCEs.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Utilize OSCEconnect's feedback features to gather and analyze
            feedback efficiently.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Collabrate with Fellow Examiners:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Share insights, challenges, and best practices with other members on
            the platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Regular discussions can help standardize assesments and improve the
            overall quality of OSCEs.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontFamily: "Montserrat",
          marginTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Stay Calm with Professional
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            A calm and professional demeanor will help candidates perform at
            their best and ensure a smooth examination process.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            Utilize OSCE connect's feedback features to gather and analyze
            feedback efficiently.
          </Typography>
        </li>
      </ul >

      <Typography
        variant="body1"
        sx={{ fontWeight: 600, padding: "10px 0px", fontFamily: 'Montserrat' }}
      >
        By adhering to these guidelines, you can ensure that the OSCEs conducted
        on OSCEconnect are both effective in assessing clinical competencies and
        fair for all participants.
      </Typography>
    </div >
  );
};

export default TipsAndGuidelines;