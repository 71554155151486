import React, { createContext, useState } from "react";

export const MessagesTabContext = createContext();

export const MessagesTabProvider = ({ children }) => {
  const [tab, setTab] = useState(0);

  return (
    <MessagesTabContext.Provider value={{ tab, setTab }}>
      {children}
    </MessagesTabContext.Provider>
  )
};