import React, { useEffect, useState } from 'react';
import styles from './TopicDetails.module.scss';
import { Box } from '@mui/material';
import { IoIosMenu } from "react-icons/io";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import AccordianBox from '../../../../components/AccordionBox/index';
import { getCategoryTopicDetails, getCategoryTopicList } from '../../../../services/learningHub.service';
import toast from 'react-hot-toast';

const TopicDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { topicId } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');
  const type = queryParams.get('type');
  const [collapsed, setCollapsed] = useState(false);
  const [topicData, setTopicData] = useState(null);
  const [clinicalTopicList, setClinicalTopicList] = useState([]);
  const IMAGE_URL = `${process.env.REACT_APP_IMAGE_URL}/category/${topicData?.category?.image}`;
  const CLINICAL_IMAGE_URL = `${process.env.REACT_APP_IMAGE_URL}/clinical-system/${topicData?.clinical_system?.image}`;

  const handleSelectTopic = (topicId) => {
    navigate(`/learning-hub/topic-details/${topicId}?category=${category}&type=${type}`);
  };

  const handleAccordionExpand = index => {
    setTopicData(prevTopicData => ({
      ...prevTopicData,
      topic_details: prevTopicData?.topic_details?.map((item, i) => ({
        ...item,
        enable: i === index ? !item.enable : item.enable
      }))
    }));
  };

  const getCategoryTopicData = async () => {
    const result = await getCategoryTopicDetails(topicId);

    if (result.status) {
      setTopicData(result?.data);
    } else {
      toast.error(result?.error)
    }
  };

  const fetchClinicalSystemTopics = async () => {
    const result = await getCategoryTopicList(topicData?.category_id, topicData?.clinical_system?.id)

    if (result?.status) {
      setClinicalTopicList(result?.data)
    } else {
      toast.error(result?.error)
    }
  };

  useEffect(() => {
    if (topicId) getCategoryTopicData();
  }, [topicId]);

  useEffect(() => {
    if (topicData) fetchClinicalSystemTopics();
  }, [topicData]);

  return (
    <div className={styles.topicDetails}>
      <div className={styles.header}>
        <div className={styles.categoryImage}>
          <img src={IMAGE_URL} alt="category-image" width={190} height={190} />
        </div>

        <div className={styles.categoryTitle}>
          <span className={styles.clinicalTitle}>Clinical System</span>
          <span className={styles.title}>{category}</span>
        </div>
      </div>

      <div className={`${styles.content} ${collapsed ? styles.collapsedContent : ""}`}>
        <div className={`${styles.leftContainer} ${collapsed ? styles.collapsedLeftContainer : ""}`}>
          <div className={styles.leftContainerHeader}>
            <img
              src={CLINICAL_IMAGE_URL}
              alt="clinical-system-image"
              height={80}
              width={80}
              style={{
                borderRadius: "100%",
                objectFit: "contain",
                display: collapsed ? "none" : "block",
              }}
            />

            <div className={styles.typeAndButton}>
              <span style={{ display: collapsed ? "none" : "block" }}>
                {type}
              </span>
              <div
                className={styles.menuIcon}
                onClick={() => setCollapsed(!collapsed)}
              >
                <IoIosMenu color="#1f7698" fontSize={"1.5rem"} />
              </div>
            </div>
          </div>

          {!collapsed && (
            <div className={styles.leftContainerContent}>
              <div className={styles.topicList}>
                {clinicalTopicList?.map((topic, index) => {
                  const isSelected = topicData?.id === topic?.id;
                  return (
                    <span
                      key={index}
                      className={isSelected ? styles.selectedTopic : ""}
                      onClick={() => handleSelectTopic(topic?.id)}
                    >
                      {topic?.title}
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className={`${styles.rightContainer} ${collapsed ? styles.collapsedRightContainer : ""}`}>
          <div className={styles.rightContainerHeader}>
            <span className={styles.topicTitle}>{topicData?.title}</span>
            {topicData?.clinical_system?.title === "Clinical Cases" && (
              <span className={styles.caseId}>{`Case ID : ${topicData?.case_id}`}</span>
            )}
          </div>

          <div className={styles.rightContainerContent}>
            {topicData?.topic_details?.map((item, index) => (
              <AccordianBox
                expand={item?.enable}
                setExpanded={() => handleAccordionExpand(index)}
                summaryWidth={"100%"}
                summaryType="topic-details"
                backgroundColor={"#1f7698"}
                title={
                  <Box sx={{ width: "100%", color: "#ffffff", fontWeight: "600" }}>
                    {item?.section_name}
                  </Box>
                }
                details={
                  <div className="froala-editor-view-container">
                    <FroalaEditorView model={item?.section_value} />
                  </div>
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div >
  );
};

export default TopicDetails;