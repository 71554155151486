export const nationality = [
  {
    "id": 1,
    "country": "Afghanistan",
    "nationality": "Afghan"
  },
  {
    "id": 2,
    "country": "Albania",
    "nationality": "Albanian"
  },
  {
    "id": 3,
    "country": "Algeria",
    "nationality": "Algerian"
  },
  {
    "id": 4,
    "country": "Andorra",
    "nationality": "Andorran"
  },
  {
    "id": 5,
    "country": "Angola",
    "nationality": "Angolan"
  },
  {
    "id": 6,
    "country": "Antigua and Barbuda",
    "nationality": "Antiguan"
  },
  {
    "id": 7,
    "country": "Argentina",
    "nationality": "Argentine"
  },
  {
    "id": 8,
    "country": "Armenia",
    "nationality": "Armenian"
  },
  {
    "id": 9,
    "country": "Australia",
    "nationality": "Australian"
  },
  {
    "id": 10,
    "country": "Austria",
    "nationality": "Austrian"
  },
  {
    "id": 11,
    "country": "Azerbaijan",
    "nationality": "Azerbaijani"
  },
  {
    "id": 12,
    "country": "Bahamas",
    "nationality": "Bahamian"
  },
  {
    "id": 13,
    "country": "Bahrain",
    "nationality": "Bahraini"
  },
  {
    "id": 14,
    "country": "Bangladesh",
    "nationality": "Bangladeshi"
  },
  {
    "id": 15,
    "country": "Barbados",
    "nationality": "Barbadian"
  },
  {
    "id": 16,
    "country": "Belarus",
    "nationality": "Belarusian"
  },
  {
    "id": 17,
    "country": "Belgium",
    "nationality": "Belgian"
  },
  {
    "id": 18,
    "country": "Belize",
    "nationality": "Belizean"
  },
  {
    "id": 19,
    "country": "Benin",
    "nationality": "Beninese"
  },
  {
    "id": 20,
    "country": "Bhutan",
    "nationality": "Bhutanese"
  },
  {
    "id": 21,
    "country": "Bolivia",
    "nationality": "Bolivian"
  },
  {
    "id": 22,
    "country": "Bosnia and Herzegovina",
    "nationality": "Bosnian"
  },
  {
    "id": 23,
    "country": "Botswana",
    "nationality": "Motswana/Botswanan"
  },
  {
    "id": 24,
    "country": "Brazil",
    "nationality": "Brazilian"
  },
  {
    "id": 25,
    "country": "Brunei",
    "nationality": "Bruneian"
  },
  {
    "id": 26,
    "country": "Bulgaria",
    "nationality": "Bulgarian"
  },
  {
    "id": 27,
    "country": "Burkina Faso",
    "nationality": "Burkinabé"
  },
  {
    "id": 28,
    "country": "Burundi",
    "nationality": "Burundian"
  },
  {
    "id": 29,
    "country": "Cambodia",
    "nationality": "Cambodian"
  },
  {
    "id": 30,
    "country": "Cameroon",
    "nationality": "Cameroonian"
  },
  {
    "id": 31,
    "country": "Canada",
    "nationality": "Canadian"
  },
  {
    "id": 32,
    "country": "Cape Verde",
    "nationality": "Cape Verdean"
  },
  {
    "id": 33,
    "country": "Central African Republic",
    "nationality": "Central African"
  },
  {
    "id": 34,
    "country": "Chad",
    "nationality": "Chadian"
  },
  {
    "id": 35,
    "country": "Chile",
    "nationality": "Chilean"
  },
  {
    "id": 36,
    "country": "China",
    "nationality": "Chinese"
  },
  {
    "id": 37,
    "country": "Colombia",
    "nationality": "Colombian"
  },
  {
    "id": 38,
    "country": "Comoros",
    "nationality": "Comoran"
  },
  {
    "id": 39,
    "country": "Congo (Brazzaville)",
    "nationality": "Congolese"
  },
  {
    "id": 40,
    "country": "Congo (Kinshasa)",
    "nationality": "Congolese"
  },
  {
    "id": 41,
    "country": "Costa Rica",
    "nationality": "Costa Rican"
  },
  {
    "id": 42,
    "country": "Croatia",
    "nationality": "Croatian"
  },
  {
    "id": 43,
    "country": "Cuba",
    "nationality": "Cuban"
  },
  {
    "id": 44,
    "country": "Cyprus",
    "nationality": "Cypriot"
  },
  {
    "id": 45,
    "country": "Czech Republic",
    "nationality": "Czech"
  },
  {
    "id": 46,
    "country": "Denmark",
    "nationality": "Danish"
  },
  {
    "id": 47,
    "country": "Djibouti",
    "nationality": "Djibouti"
  },
  {
    "id": 48,
    "country": "Dominica",
    "nationality": "Dominican"
  },
  {
    "id": 49,
    "country": "Dominican Republic",
    "nationality": "Dominican"
  },
  {
    "id": 50,
    "country": "Ecuador",
    "nationality": "Ecuadorian"
  },
  {
    "id": 51,
    "country": "Egypt",
    "nationality": "Egyptian"
  },
  {
    "id": 52,
    "country": "El Salvador",
    "nationality": "Salvadoran"
  },
  {
    "id": 53,
    "country": "Equatorial Guinea",
    "nationality": "Equatorial Guinean"
  },
  {
    "id": 54,
    "country": "Eritrea",
    "nationality": "Eritrean"
  },
  {
    "id": 55,
    "country": "Estonia",
    "nationality": "Estonian"
  },
  {
    "id": 56,
    "country": "Ethiopia",
    "nationality": "Ethiopian"
  },
  {
    "id": 57,
    "country": "Fiji",
    "nationality": "Fijian"
  },
  {
    "id": 58,
    "country": "Finland",
    "nationality": "Finnish"
  },
  {
    "id": 59,
    "country": "France",
    "nationality": "French"
  },
  {
    "id": 60,
    "country": "Gabon",
    "nationality": "Gabonese"
  },
  {
    "id": 61,
    "country": "Gambia",
    "nationality": "Gambian"
  },
  {
    "id": 62,
    "country": "Georgia",
    "nationality": "Georgian"
  },
  {
    "id": 63,
    "country": "Germany",
    "nationality": "German"
  },
  {
    "id": 64,
    "country": "Ghana",
    "nationality": "Ghanaian"
  },
  {
    "id": 65,
    "country": "Greece",
    "nationality": "Greek"
  },
  {
    "id": 66,
    "country": "Grenada",
    "nationality": "Grenadian"
  },
  {
    "id": 67,
    "country": "Guatemala",
    "nationality": "Guatemalan"
  },
  {
    "id": 68,
    "country": "Guinea",
    "nationality": "Guinean"
  },
  {
    "id": 69,
    "country": "Guinea-Bissau",
    "nationality": "Guinean"
  },
  {
    "id": 70,
    "country": "Guyana",
    "nationality": "Guyanese"
  },
  {
    "id": 71,
    "country": "Haiti",
    "nationality": "Haitian"
  },
  {
    "id": 72,
    "country": "Honduras",
    "nationality": "Honduran"
  },
  {
    "id": 73,
    "country": "Hungary",
    "nationality": "Hungarian"
  },
  {
    "id": 74,
    "country": "Iceland",
    "nationality": "Icelandic"
  },
  {
    "id": 75,
    "country": "India",
    "nationality": "Indian"
  },
  {
    "id": 76,
    "country": "Indonesia",
    "nationality": "Indonesian"
  },
  {
    "id": 77,
    "country": "Iran",
    "nationality": "Iranian"
  },
  {
    "id": 78,
    "country": "Iraq",
    "nationality": "Iraqi"
  },
  {
    "id": 79,
    "country": "Ireland",
    "nationality": "Irish"
  },
  {
    "id": 80,
    "country": "Israel",
    "nationality": "Israeli"
  },
  {
    "id": 81,
    "country": "Italy",
    "nationality": "Italian"
  },
  {
    "id": 82,
    "country": "Ivory Coast",
    "nationality": "Ivorian"
  },
  {
    "id": 83,
    "country": "Jamaica",
    "nationality": "Jamaican"
  },
  {
    "id": 84,
    "country": "Japan",
    "nationality": "Japanese"
  },
  {
    "id": 85,
    "country": "Jordan",
    "nationality": "Jordanian"
  },
  {
    "id": 86,
    "country": "Kazakhstan",
    "nationality": "Kazakhstani"
  },
  {
    "id": 87,
    "country": "Kenya",
    "nationality": "Kenyan"
  },
  {
    "id": 88,
    "country": "Kiribati",
    "nationality": "I-Kiribati"
  },
  {
    "id": 89,
    "country": "Kuwait",
    "nationality": "Kuwaiti"
  },
  {
    "id": 90,
    "country": "Kyrgyzstan",
    "nationality": "Kyrgyzstani"
  },
  {
    "id": 91,
    "country": "Laos",
    "nationality": "Laotian"
  },
  {
    "id": 92,
    "country": "Latvia",
    "nationality": "Latvian"
  },
  {
    "id": 93,
    "country": "Lebanon",
    "nationality": "Lebanese"
  },
  {
    "id": 94,
    "country": "Lesotho",
    "nationality": "Mosotho/Lesothan"
  },
  {
    "id": 95,
    "country": "Liberia",
    "nationality": "Liberian"
  },
  {
    "id": 96,
    "country": "Libya",
    "nationality": "Libyan"
  },
  {
    "id": 97,
    "country": "Liechtenstein",
    "nationality": "Liechtensteiner"
  },
  {
    "id": 98,
    "country": "Lithuania",
    "nationality": "Lithuanian"
  },
  {
    "id": 99,
    "country": "Luxembourg",
    "nationality": "Luxembourger"
  },
  {
    "id": 100,
    "country": "Madagascar",
    "nationality": "Malagasy"
  },
  {
    "id": 101,
    "country": "Malawi",
    "nationality": "Malawian"
  },
  {
    "id": 102,
    "country": "Malaysia",
    "nationality": "Malaysian"
  },
  {
    "id": 103,
    "country": "Maldives",
    "nationality": "Maldivian"
  },
  {
    "id": 104,
    "country": "Mali",
    "nationality": "Malian"
  },
  {
    "id": 105,
    "country": "Malta",
    "nationality": "Maltese"
  },
  {
    "id": 106,
    "country": "Marshall Islands",
    "nationality": "Marshallese"
  },
  {
    "id": 107,
    "country": "Mauritania",
    "nationality": "Mauritanian"
  },
  {
    "id": 108,
    "country": "Mauritius",
    "nationality": "Mauritian"
  },
  {
    "id": 109,
    "country": "Mexico",
    "nationality": "Mexican"
  },
  {
    "id": 110,
    "country": "Micronesia",
    "nationality": "Micronesian"
  },
  {
    "id": 111,
    "country": "Moldova",
    "nationality": "Moldovan"
  },
  {
    "id": 112,
    "country": "Monaco",
    "nationality": "Monacan"
  },
  {
    "id": 113,
    "country": "Mongolia",
    "nationality": "Mongolian"
  },
  {
    "id": 114,
    "country": "Montenegro",
    "nationality": "Montenegrin"
  },
  {
    "id": 115,
    "country": "Morocco",
    "nationality": "Moroccan"
  },
  {
    "id": 116,
    "country": "Mozambique",
    "nationality": "Mozambican"
  },
  {
    "id": 117,
    "country": "Myanmar",
    "nationality": "Burmese"
  },
  {
    "id": 118,
    "country": "Namibia",
    "nationality": "Namibian"
  },
  {
    "id": 119,
    "country": "Nauru",
    "nationality": "Nauruan"
  },
  {
    "id": 120,
    "country": "Nepal",
    "nationality": "Nepali"
  },
  {
    "id": 121,
    "country": "Netherlands",
    "nationality": "Dutch"
  },
  {
    "id": 122,
    "country": "New Zealand",
    "nationality": "New Zealander"
  },
  {
    "id": 123,
    "country": "Nicaragua",
    "nationality": "Nicaraguan"
  },
  {
    "id": 124,
    "country": "Niger",
    "nationality": "Nigerien"
  },
  {
    "id": 125,
    "country": "Nigeria",
    "nationality": "Nigerian"
  },
  {
    "id": 126,
    "country": "North Korea",
    "nationality": "North Korean"
  },
  {
    "id": 127,
    "country": "North Macedonia",
    "nationality": "Macedonian"
  },
  {
    "id": 128,
    "country": "Norway",
    "nationality": "Norwegian"
  },
  {
    "id": 129,
    "country": "Oman",
    "nationality": "Omani"
  },
  {
    "id": 130,
    "country": "Pakistan",
    "nationality": "Pakistani"
  },
  {
    "id": 131,
    "country": "Palau",
    "nationality": "Palauan"
  },
  {
    "id": 132,
    "country": "Panama",
    "nationality": "Panamanian"
  },
  {
    "id": 133,
    "country": "Papua New Guinea",
    "nationality": "Papua New Guinean"
  },
  {
    "id": 134,
    "country": "Paraguay",
    "nationality": "Paraguayan"
  },
  {
    "id": 135,
    "country": "Peru",
    "nationality": "Peruvian"
  },
  {
    "id": 136,
    "country": "Philippines",
    "nationality": "Filipino"
  },
  {
    "id": 137,
    "country": "Poland",
    "nationality": "Polish"
  },
  {
    "id": 138,
    "country": "Portugal",
    "nationality": "Portuguese"
  },
  {
    "id": 139,
    "country": "Qatar",
    "nationality": "Qatari"
  },
  {
    "id": 140,
    "country": "Romania",
    "nationality": "Romanian"
  },
  {
    "id": 141,
    "country": "Russia",
    "nationality": "Russian"
  },
  {
    "id": 142,
    "country": "Rwanda",
    "nationality": "Rwandan"
  },
  {
    "id": 143,
    "country": "Saint Kitts and Nevis",
    "nationality": "Kittitian/Nevisian"
  },
  {
    "id": 144,
    "country": "Saint Lucia",
    "nationality": "Saint Lucian"
  },
  {
    "id": 145,
    "country": "Saint Vincent and the Grenadines",
    "nationality": "Vincentian"
  },
  {
    "id": 146,
    "country": "Samoa",
    "nationality": "Samoan"
  },
  {
    "id": 147,
    "country": "San Marino",
    "nationality": "Sammarinese"
  },
  {
    "id": 148,
    "country": "Sao Tome and Principe",
    "nationality": "São Toméan"
  },
  {
    "id": 149,
    "country": "Saudi Arabia",
    "nationality": "Saudi"
  },
  {
    "id": 150,
    "country": "Senegal",
    "nationality": "Senegalese"
  },
  {
    "id": 151,
    "country": "Serbia",
    "nationality": "Serbian"
  },
  {
    "id": 152,
    "country": "Seychelles",
    "nationality": "Seychellois"
  },
  {
    "id": 153,
    "country": "Sierra Leone",
    "nationality": "Sierra Leonean"
  },
  {
    "id": 154,
    "country": "Singapore",
    "nationality": "Singaporean"
  },
  {
    "id": 155,
    "country": "Slovakia",
    "nationality": "Slovak"
  },
  {
    "id": 156,
    "country": "Slovenia",
    "nationality": "Slovene/Slovenian"
  },
  {
    "id": 157,
    "country": "Solomon Islands",
    "nationality": "Solomon Islander"
  },
  {
    "id": 158,
    "country": "Somalia",
    "nationality": "Somali"
  },
  {
    "id": 159,
    "country": "South Africa",
    "nationality": "South African"
  },
  {
    "id": 160,
    "country": "South Korea",
    "nationality": "South Korean"
  },
  {
    "id": 161,
    "country": "South Sudan",
    "nationality": "South Sudanese"
  },
  {
    "id": 162,
    "country": "Spain",
    "nationality": "Spanish"
  },
  {
    "id": 163,
    "country": "Sri Lanka",
    "nationality": "Sri Lankan"
  },
  {
    "id": 164,
    "country": "Sudan",
    "nationality": "Sudanese"
  },
  {
    "id": 165,
    "country": "Suriname",
    "nationality": "Surinamese"
  },
  {
    "id": 166,
    "country": "Sweden",
    "nationality": "Swedish"
  },
  {
    "id": 167,
    "country": "Switzerland",
    "nationality": "Swiss"
  },
  {
    "id": 168,
    "country": "Syria",
    "nationality": "Syrian"
  },
  {
    "id": 169,
    "country": "Taiwan",
    "nationality": "Taiwanese"
  },
  {
    "id": 170,
    "country": "Tajikistan",
    "nationality": "Tajikistani"
  },
  {
    "id": 171,
    "country": "Tanzania",
    "nationality": "Tanzanian"
  },
  {
    "id": 172,
    "country": "Thailand",
    "nationality": "Thai"
  },
  {
    "id": 173,
    "country": "Timor-Leste",
    "nationality": "Timorese"
  },
  {
    "id": 174,
    "country": "Togo",
    "nationality": "Togolese"
  },
  {
    "id": 175,
    "country": "Tonga",
    "nationality": "Tongan"
  },
  {
    "id": 176,
    "country": "Trinidad and Tobago",
    "nationality": "Trinidadian/Tobagonian"
  },
  {
    "id": 177,
    "country": "Tunisia",
    "nationality": "Tunisian"
  },
  {
    "id": 178,
    "country": "Turkey",
    "nationality": "Turkish"
  },
  {
    "id": 179,
    "country": "Turkmenistan",
    "nationality": "Turkmen"
  },
  {
    "id": 180,
    "country": "Tuvalu",
    "nationality": "Tuvaluan"
  },
  {
    "id": 181,
    "country": "Uganda",
    "nationality": "Ugandan"
  },
  {
    "id": 182,
    "country": "Ukraine",
    "nationality": "Ukrainian"
  },
  {
    "id": 183,
    "country": "United Arab Emirates",
    "nationality": "Emirati"
  },
  {
    "id": 184,
    "country": "United Kingdom",
    "nationality": "British"
  },
  {
    "id": 185,
    "country": "United States",
    "nationality": "American"
  },
  {
    "id": 186,
    "country": "Uruguay",
    "nationality": "Uruguayan"
  },
  {
    "id": 187,
    "country": "Uzbekistan",
    "nationality": "Uzbekistani"
  },
  {
    "id": 188,
    "country": "Vanuatu",
    "nationality": "Ni-Vanuatu"
  },
  {
    "id": 189,
    "country": "Vatican City",
    "nationality": "Vatican"
  },
  {
    "id": 190,
    "country": "Venezuela",
    "nationality": "Venezuelan"
  },
  {
    "id": 191,
    "country": "Vietnam",
    "nationality": "Vietnamese"
  },
  {
    "id": 192,
    "country": "Yemen",
    "nationality": "Yemeni"
  },
  {
    "id": 193,
    "country": "Zambia",
    "nationality": "Zambian"
  },
  {
    "id": 194,
    "country": "Zimbabwe",
    "nationality": "Zimbabwean"
  }
];