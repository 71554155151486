import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import { initialNavigation, getAllCategories } from "../../navigation/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useMenu } from "../../hooks/useMenu";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [navigation, setNavigation] = useState(initialNavigation);
  const { setSelectedMenu } = useMenu();
  const [openSubMenus, setOpenSubMenus] = useState(null);

  const handleLogoClick = () => {
    setSelectedMenu('Dashboard');
    setOpenSubMenus(null);
    navigate("/");
  };

  const handleMenuClick = (menu) => {
    const firstSubMenu = menu?.children?.[0];
    setSelectedMenu(menu.title);

    if (menu.title === "Learning Hub") {
      navigate(menu.path);
    } else {
      navigate(firstSubMenu ? `${menu.path}${firstSubMenu.path}` : menu.path);
    }

    setOpenSubMenus((prev) => (prev === menu.id ? null : menu.id));
  };

  const handleSubMenuClick = (e, subMenu, menuPath) => {
    e.stopPropagation();
    navigate(`${menuPath}${subMenu?.path}`);
  };

  const isActiveMenu = (menu) => {
    if (menu.path === "/") {
      return location.pathname === "/";
    } else {
      return location.pathname.startsWith(menu.path) && menu.path !== "/";
    }
  };

  const isActiveSubMenu = (subMenu, menuPath) => {
    return location.pathname === `${menuPath}${subMenu.path}`;
  };

  useEffect(() => {
    getAllCategories(setNavigation);
  }, []);

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarLogo}>
        <img
          src="../images/logos/sidebarIcon.png"
          width={130}
          height={70}
          onClick={handleLogoClick}
          style={{ cursor: "pointer" }}
          alt="logo"
        />
      </div>

      <div className={styles.navbarList}>
        <ul className={styles.mainUl}>
          {navigation?.map((menu, index) => (
            <li
              key={index}
              className={styles.mainLi}
              onClick={() => handleMenuClick(menu)}
            >
              <div className={`${styles.contentArea} ${isActiveMenu(menu) ? styles.active : ""}`}>
                {menu?.icon}
                {menu?.title}
              </div>

              {menu?.children && openSubMenus === menu.id && (
                <ul className={styles.subUl}>
                  {menu?.children?.map((subMenu, subIndex) => (
                    <li
                      key={subIndex}
                      className={`${styles.subLi} ${isActiveSubMenu(subMenu, menu.path) ? styles.activeSub : ""}`}
                      onClick={(e) => handleSubMenuClick(e, subMenu, menu?.path)}
                    >
                      {subMenu?.title}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;