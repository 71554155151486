import React, { useEffect, useState } from "react";
import styles from "./Login.module.scss";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useForm } from "react-hook-form";
import { login } from '../../services/user.service';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const handleLogin = async (data) => {
    setLoading(true);
    const objData = {
      email: data.email,
      password: data.password
    };

    const result = await login(objData);

    if (result.status) {
      localStorage.setItem("access_token", result?.data?.access_token);
      localStorage.setItem("refresh_token", result?.data?.refresh_token);
      localStorage.setItem("login_id", result?.data?.login_id);
      localStorage.setItem("comet_uid", result?.data?.user?.id);
      setIsAuthenticated(true);
    } else {
      toast.error(result.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    !isAuthenticated && (
      < div className={styles.mainContainer} >
        <div className={styles.innerContainer}>
          <div className={styles.logo}>
            <img
              src="../images/logos/Logo-no-slogan.png"
              alt="logo"
              height={50}
            />
          </div>
          <div className={styles.title}>
            <h4>Welcome to OSCE Connect!</h4>
            <span className={styles.wavingHand}>👋🏻</span>
          </div>

          <form onSubmit={handleSubmit(handleLogin)}>
            <div className={styles.inputContainer}>
              <label htmlFor="email">
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                name="email"
                autoFocus
                {...register('email', { required: true })}
                error={errors.email}
              />
            </div>

            <div className={styles.inputContainer}>
              <label htmlFor="password">
                Password <span style={{ color: "red" }}>*</span>
              </label>
              <div className={styles.passwordInput}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  {...register('password', { required: true })}
                  error={errors.password}
                />
                <div className={styles.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </div>
              </div>
            </div>

            <div className={styles.options}>
              <div className={styles.rememberMe}>
                <input
                  type="checkbox"
                  id="rememberMe"
                  style={{ transform: "scale(1.2)", marginTop: "2px" }}
                />
                <label htmlFor="rememberMe">Remember Me</label>
              </div>
              <a href="/forgot-password">Forgot Password?</a>
            </div>

            <button
              type="submit"
              className={styles.loginButton}
              disabled={loading}
            >
              {loading ? "Loading..." : "LOGIN"}
            </button>
            <div className={styles.footer}>
              <span>
                New on our platform? <a href="/register">Create an account</a>
              </span>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default Login;