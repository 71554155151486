import React, { useEffect, useState } from 'react';
import styles from './Calendar.module.scss';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import EventTooltip from './components/EventTooltip';
import AddSession from '../../components/AddSession/index';
import { getSessionsList } from '../../services/session.service';
import useSessionList from '../../hooks/custom/useSessionList';
import { useAuth } from '../../hooks/useAuth';
import toast from 'react-hot-toast';

const Calendar = () => {
  const { profile } = useAuth();
  const navigate = useNavigate();
  const { getSessionList } = useSessionList();
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getScheduledEvent = async () => {
      try {
        const result = await getSessionsList();

        if (result?.status) {
          const appointments = result?.data?.map((item) => {
            let role;
            if (profile?.id === item?.created_by && item?.user_role === 'Examiner') {
              role = "Examiner";
            } else if (profile?.id === item?.created_by && item?.user_role === 'Candidate') {
              role = "Candidate";
            } else if (profile?.id === item?.invited_user && item?.user_role === 'Examiner') {
              role = "Candidate";
            } else if (profile?.id === item?.invited_user && item?.user_role === 'Candidate') {
              role = "Examiner";
            } else {
              role = "Observer";
            }

            const endDate = new Date(item?.end_date);
            endDate.setMinutes(endDate.getMinutes() + 1);

            return {
              title: item?.session_name,
              start: new Date(item?.start_date),
              end: endDate,
              id: item?.id,
              session_ended_at: item?.session_ended_at,
              description: item?.description,
              user_role: role,
              session_status: item?.session_status
            }
          });

          setEvents(appointments);
        } else {
          toast.error(result?.error);
        }
      } catch (error) {
        console.error('Error fetching session list:', error);
        toast.error('An error occurred while fetching session list.');
      }
    };

    if (profile) {
      getScheduledEvent();
    }
  }, [profile]);

  const renderSessionTooltip = (eventInfo) => {
    const session_id = eventInfo?.event?._def?.publicId;

    const redirectToSession = () => {
      navigate(`/sessions/session-details/${session_id}`);
    };

    return (
      <EventTooltip
        eventInfo={eventInfo}
        redirectToSession={redirectToSession}
      />
    )
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.addSession}>
        <button onClick={() => setShowModal(true)}>Add Session</button>
      </div>

      <div className={styles.fullCalendar}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridPlugin, listPlugin]}
          initialView="timeGridWeek"
          events={events}
          eventContent={renderSessionTooltip}
          eventBackgroundColor='transparent'
          eventTextColor='#fff'
          eventBorderColor='transparent'
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            hour12: true
          }}
          eventMouseEnter={(e) => {
            const x = e.el;
            x.parentNode.style.zIndex = 999
          }}
          eventMouseLeave={(e) => {
            const x = e.el;
            x.parentNode.style.zIndex = 1
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short'
          }}
          headerToolbar={{
            left: 'prev,next today',
            right: 'timeGridDay,timeGridWeek'
          }}
          views={{
            timeGrid: {
              allDayText: 'Time',
            }
          }}
          buttonText={{
            today: 'Today',
            day: 'Day',
            week: 'Week'
          }}
        />
      </div>

      {showModal && (
        <AddSession
          hide={() => setShowModal(false)}
          getSessionList={getSessionList}
        />
      )}
    </div>
  );
};

export default Calendar;