import { ApiUrls } from "../api/apiUrls";
import { axiosInstance } from "../api/Interceptors";

/**
 *
 * @method GET
 * @param { Object } params
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all the categories.
 */
export const getCategoryList = async (type, filterSearch = '') => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALL_CATEGORIES}?type=${type}&search=${filterSearch}&limit=25&offSet=0`);
    return {
      status: true,
      data: result?.data?.data?.rows
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } categoryId 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get specific category details by category id.
 */
export const getSpecificCategoryDetails = async (categoryId) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_SPECIFIC_CATEGORY_DETAILS}/${categoryId}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Object } params
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all the category topics.
 */
export const getCategoryTopicList = async (categoryId, clinicalId) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALL_CATEGORY_TOPICS}?catId=${categoryId}&csId=${clinicalId}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } topicId
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the topic details.
 */
export const getCategoryTopicDetails = async (topicId) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_CATEGORY_TOPIC_DETAIL}/${topicId}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};