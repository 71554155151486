export const languageSpoken = [
  { "id": 1, "language": "Arabic" },
  { "id": 2, "language": "Armenian" },
  { "id": 3, "language": "Bengali" },
  { "id": 4, "language": "Belarusian" },
  { "id": 5, "language": "Bikol" },
  { "id": 6, "language": "Bosnian" },
  { "id": 7, "language": "Bulgarian" },
  { "id": 8, "language": "Cantonese" },
  { "id": 9, "language": "Catalan" },
  { "id": 10, "language": "Cebuano" },
  { "id": 11, "language": "Cham" },
  { "id": 12, "language": "Chamorro (Guam)" },
  { "id": 13, "language": "Chiu Chow" },
  { "id": 14, "language": "Chow Jo" },
  { "id": 15, "language": "Croatian" },
  { "id": 16, "language": "Czech" },
  { "id": 17, "language": "Dari" },
  { "id": 18, "language": "Danish" },
  { "id": 19, "language": "Dinka (Sudan)" },
  { "id": 20, "language": "Dutch" },
  { "id": 21, "language": "English" },
  { "id": 22, "language": "Estonian" },
  { "id": 23, "language": "Farsi" },
  { "id": 24, "language": "Faroese" },
  { "id": 25, "language": "Fijian" },
  { "id": 26, "language": "Finnish" },
  { "id": 27, "language": "Flemish" },
  { "id": 28, "language": "French" },
  { "id": 29, "language": "French Creole" },
  { "id": 30, "language": "Georgian" },
  { "id": 31, "language": "German" },
  { "id": 32, "language": "Greek" },
  { "id": 33, "language": "Gujarati (India)" },
  { "id": 34, "language": "Hainanese" },
  { "id": 35, "language": "Hakka (China)" },
  { "id": 36, "language": "Hindi" },
  { "id": 37, "language": "Hungarian" },
  { "id": 38, "language": "Ibo (Nigeria)" },
  { "id": 39, "language": "Icelandic" },
  { "id": 40, "language": "Ilocano" },
  { "id": 41, "language": "Ilongo" },
  { "id": 42, "language": "Indonesian" },
  { "id": 43, "language": "Italian" },
  { "id": 44, "language": "Japanese" },
  { "id": 45, "language": "Kazakh" },
  { "id": 46, "language": "Khmer (Cambodia)" },
  { "id": 47, "language": "Kinyarwanda" },
  { "id": 48, "language": "Kirundi" },
  { "id": 49, "language": "Kmhmu (Laos)*" },
  { "id": 50, "language": "Korean" },
  { "id": 51, "language": "Kurdish" },
  { "id": 52, "language": "Laotian" },
  { "id": 53, "language": "Latvian" },
  { "id": 54, "language": "Lithuanian" },
  { "id": 55, "language": "Macedonian" },
  { "id": 56, "language": "Malay" },
  { "id": 57, "language": "Malayalam" },
  { "id": 58, "language": "Mandarin" },
  { "id": 59, "language": "Mandinka" },
  { "id": 60, "language": "Marathi (India)" },
  { "id": 61, "language": "Marshallese" },
  { "id": 62, "language": "Mien" },
  { "id": 63, "language": "Mongolian" },
  { "id": 64, "language": "Navajo" },
  { "id": 65, "language": "Nepali" },
  { "id": 66, "language": "Nuer (Nilo-Saharan)" },
  { "id": 67, "language": "Norwegian" },
  { "id": 68, "language": "Oromo (Ethiopia)" },
  { "id": 69, "language": "Pakistani" },
  { "id": 70, "language": "Palauan*" },
  { "id": 71, "language": "Pampangan" },
  { "id": 72, "language": "Pangasinan" },
  { "id": 73, "language": "Pashtu" },
  { "id": 74, "language": "Polish" },
  { "id": 75, "language": "Portuguese" },
  { "id": 76, "language": "Punjabi" },
  { "id": 77, "language": "Quechua" },
  { "id": 78, "language": "Romanian" },
  { "id": 79, "language": "Russian" },
  { "id": 80, "language": "Samoan" },
  { "id": 81, "language": "Serbian" },
  { "id": 82, "language": "Sherpa*" },
  { "id": 83, "language": "Shona (Zimbabwe)" },
  { "id": 84, "language": "Slovak" },
  { "id": 85, "language": "Slovenian" },
  { "id": 86, "language": "Somali" },
  { "id": 87, "language": "Spanish" },
  { "id": 88, "language": "Swahili" },
  { "id": 96, "language": "Swedish" },
  { "id": 97, "language": "Tagalog" },
  { "id": 98, "language": "Tamil" },
  { "id": 99, "language": "Tibetan" },
  { "id": 100, "language": "Thai" },
  { "id": 101, "language": "Tigrigna (Ethiopia)" },
  { "id": 102, "language": "Tongan" },
  { "id": 103, "language": "Turkish" },
  { "id": 104, "language": "Turkmen" },
  { "id": 105, "language": "Ukrainian" },
  { "id": 106, "language": "Urdu" },
  { "id": 107, "language": "Uzbek" },
  { "id": 108, "language": "Vietnamese" },
  { "id": 109, "language": "Visayan" },
  { "id": 110, "language": "Wolof" },
  { "id": 111, "language": "Xhosa" },
  { "id": 112, "language": "Yoruba" },
  { "id": 113, "language": "Zulu" }
];