import React from 'react';
import styles from './Additional.module.scss';

const AdditionalInformation = ({ register }) => {
  return (
    <div className={styles.additionalInformation}>
      <div className={styles.content}>
        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Professional Interests</span>
            <textarea
              name='professional_interests'
              placeholder='Type...'
              {...register('professional_interests')}
              className={styles.inputText}
            />
          </div>
        </div>

        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Previous Examination Experience</span>
            <textarea
              name='prev_exam_experience'
              placeholder='Type...'
              {...register('prev_exam_experience')}
              className={styles.inputText}
            />
          </div>
        </div>

        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Short Bio</span>
            <textarea
              name='short_bio'
              placeholder='Type...'
              {...register('short_bio')}
              className={styles.inputText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInformation;