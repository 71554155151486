import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./routes/private";
import { useAuth } from "./hooks/useAuth";

/* Import all the pages here */
import Layouts from './components/Layouts/index';
import Login from './pages/Login/index';
import Register from './pages/Register/index';
import ForgotPassword from "./pages/ForgotPassword/index";
import Dashboard from './pages/Dashboard/index';
import Calendar from './pages/Calendar/index';
import Messages from './pages/Messages/index';
import Sessions from './pages/Sessions/index';
import LearningHub from './pages/LearningHub/index';
import MyProgress from './pages/MyProgress/index';
import UserDetails from './pages/UserDetails/index';
import VerifyEmailToken from './pages/VerifyEmail/index';
import MyConnections from './pages/Connections/components/MyConnections/index';
import ConnectionRequest from './pages/Connections/components/Requests/index';
import FindConnections from './pages/Connections/components/FindConnections/index';
import MyProfile from './pages/Settings/components/MyProfile/index';
import Notifications from './pages/Settings/components/Notifications/index';
import Billing from './pages/Settings/components/Billing/index';
import ClinicalSystem from './pages/LearningHub/components/ClinicalSystem/index';
import TopicDetails from './pages/LearningHub/components/TopicDetails/index';
import SessionDetails from './pages/Sessions/components/SessionDetails/index';
import SessionLogDetails from './pages/MyProgress/components/SessionLogDetails';

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to='/' /> : <Login />} />
        <Route path="/register" element={isAuthenticated ? <Navigate to='/' /> : <Register />} />
        <Route path="/forgot-password" element={isAuthenticated ? <Navigate to='/' /> : <ForgotPassword />} />
        <Route path="/verify-email/:token" element={isAuthenticated ? <Navigate to='/' /> : <VerifyEmailToken />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Layouts>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/messages" element={<Messages />} />
                  <Route path="/sessions" element={<Sessions />} />
                  <Route path="/sessions/session-details/:sessionId" element={<SessionDetails />} />
                  <Route path="/learning-hub" element={<LearningHub />} />
                  <Route path="/learning-hub/:category" element={<ClinicalSystem />} />
                  <Route path="/learning-hub/topic-details/:topicId" element={<TopicDetails />} />
                  <Route path="/my-progress" element={<MyProgress />} />
                  <Route path="/my-progress/session-feedback-details/:sessionId" element={<SessionLogDetails />} />
                  <Route path="/connections/my-connections" element={<MyConnections />} />
                  <Route path="/connections/requests" element={<ConnectionRequest />} />
                  <Route path="/connections/find-connections" element={<FindConnections />} />
                  <Route path="/settings/my-profile" element={<MyProfile />} />
                  <Route path="/settings/notifications" element={<Notifications />} />
                  <Route path="/settings/billing" element={<Billing />} />
                  <Route path="/user-details/:userId" element={<UserDetails />} />
                </Routes>
              </Layouts>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;