import React, { useEffect, useState } from 'react';
import styles from './MyProfile.module.scss';
import { Tabs, Tab } from "@mui/material";
import PersonalInformation from './components/PersonalInformation';
import ContactInformation from './components/ContactInformation';
import WorkStudyInformation from './components/WorkStudyInformation';
import ExamInformation from './components/ExamInformation';
import ExpertiseInformation from './components/ExpertiseInformation';
import AdditionalInformation from './components/AdditionalInformation/index';
import { updateUser } from '../../../../services/user.service';
import { useAuth } from '../../../../hooks/useAuth';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const tabStyles = {
  width: "calc(16% + 8px)",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  lineHeight: '1',
  minHeight: 'unset'
};

const MyProfile = () => {
  const { profile, getUserProfile } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
  };

  const { register, handleSubmit, setValue, watch, formState: { errors }, reset } = useForm({
    defaultValues: {
      title: profile?.user_detail?.title,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      gender: profile?.user_detail?.gender,
      nationality: profile?.user_detail?.nationality,
      language: profile?.user_detail?.language_spoken
      // ? JSON?.parse(JSON?.parse(profile?.user_detail?.language_spoken))
      // : ''
      ,
      phone: profile?.user_detail?.phone,
      email: profile?.email,
      country: profile?.user_detail?.country,
      city: profile?.user_detail?.city,
      year_of_practice: profile?.user_detail?.year_of_practice,
      work_schedule_type: profile?.user_detail?.work_schedule_type,
      available_study_hour: profile?.user_detail?.available_study_hour,
      preferred_study_method: profile?.user_detail?.preferred_study_method,
      pass_year: profile?.user_detail?.pass_year,
      exam_diet: profile?.user_detail?.exam_diet,
      strengths: profile?.user_detail?.strengths,
      area_of_improvement: profile?.user_detail?.area_of_improvement,
      professional_interests: profile?.user_detail?.professional_interests,
      prev_exam_experience: profile?.user_detail?.prev_exam_experience,
      short_bio: profile?.user_detail?.short_bio,
      profile_view_setting: profile?.user_detail?.profile_view_setting,
      invite_as_examiner_setting: profile?.user_detail?.invite_as_examiner_setting,
      observe_my_session_setting: profile?.user_detail?.observe_my_session_setting
    }
  });

  useEffect(() => {
    reset({
      title: profile?.user_detail?.title,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      gender: profile?.user_detail?.gender,
      nationality: profile?.user_detail?.nationality,
      language: profile?.user_detail?.language_spoken
      // ? JSON?.parse(JSON?.parse(profile?.user_detail?.language_spoken))
      // : ''
      ,
      phone: profile?.user_detail?.phone,
      email: profile?.email,
      country: profile?.user_detail?.country,
      city: profile?.user_detail?.city,
      year_of_practice: profile?.user_detail?.year_of_practice,
      work_schedule_type: profile?.user_detail?.work_schedule_type,
      available_study_hour: profile?.user_detail?.available_study_hour,
      preferred_study_method: profile?.user_detail?.preferred_study_method,
      pass_year: profile?.user_detail?.pass_year,
      exam_diet: profile?.user_detail?.exam_diet,
      strengths: profile?.user_detail?.strengths,
      area_of_improvement: profile?.user_detail?.area_of_improvement,
      professional_interests: profile?.user_detail?.professional_interests,
      prev_exam_experience: profile?.user_detail?.prev_exam_experience,
      short_bio: profile?.user_detail?.short_bio,
      profile_view_setting: profile?.user_detail?.profile_view_setting,
      invite_as_examiner_setting: profile?.user_detail?.invite_as_examiner_setting,
      observe_my_session_setting: profile?.user_detail?.observe_my_session_setting
    })
  }, [profile]);

  const handleUpdateSubmit = async (data) => {
    const result = await updateUser(data);

    if (result.status) {
      toast.success(result?.data);
      await getUserProfile();
    } else {
      toast.error(result?.error);
    }
  };

  return (
    <div className={styles.myProfile}>
      <div className={styles.header}>
        <h5>My Profile</h5>
      </div>

      <div className={styles.myProfileTabs}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#1EA7B6",
            },
          }}
          sx={{
            "& .MuiTab-root": {
              color: "#",
              fontWeight: 400,
              background: "#CDEAED",
              borderRadius: "20px 20px 0 0",
              textTransform: 'capitalize',
              "&.Mui-selected": {
                color: "#56d1e0",
                fontWeight: 600,
                background: "#fff",
              }
            }
          }}
        >
          <Tab
            label="Personal Information"
            sx={tabStyles}
          />
          <Tab
            label="Contact Information"
            sx={tabStyles}
          />
          <Tab
            label="Work & Study Information"
            sx={tabStyles}
          />
          <Tab
            label="Exam Information"
            sx={tabStyles}
          />
          <Tab
            label="Area of Expertise & Improvement"
            sx={tabStyles}
          />
          <Tab
            label="Additional Information"
            sx={tabStyles}
          />
        </Tabs>
      </div>

      <div className={styles.content}>
        {tabIndex === 0 &&
          <PersonalInformation
            register={register}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        }
        {tabIndex === 1 &&
          <ContactInformation
            register={register}
            watch={watch}
            errors={errors}
          />
        }
        {tabIndex === 2 &&
          <WorkStudyInformation
            register={register}
            watch={watch}
          />
        }
        {tabIndex === 3 &&
          <ExamInformation
            register={register}
            watch={watch}
          />
        }
        {tabIndex === 4 &&
          <ExpertiseInformation
            register={register}
          />
        }
        {tabIndex === 5 &&
          <AdditionalInformation
            register={register}
          />
        }
      </div>

      <button
        type='submit'
        className={styles.submitButton}
        onClick={handleSubmit(handleUpdateSubmit)}
      >
        Save & Submit
      </button>
    </div>
  );
};

export default MyProfile;