import React from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/index';
import AppNavBar from '../AppNavbar/index';

const Layouts = ({ children }) => {
  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.sidebarLayoutWrapper}>
        <Sidebar />
      </div>

      <div className={styles.contentLayoutWrapper}>
        <header className={styles.navbarLayout}>
          <AppNavBar />
        </header>

        <main className={styles.pageLayout}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layouts;