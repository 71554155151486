import React from 'react';
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer
} from '@mui/material';

const OverallProgressTable = ({ scoreDetails }) => {
  const candidateColumns = [
    { id: 'domain', label: 'Domain', minWidth: 100 },
    { id: 'poor', label: 'Poor (%)', minWidth: 100 },
    { id: 'unsatisfactory', label: 'Unsatisfactory (%)', minWidth: 120 },
    { id: 'competent', label: 'Competent (%)', minWidth: 120 },
    { id: 'excellent', label: 'Excellent (%)', minWidth: 120 },
    { id: 'case_count', label: 'Case Count', minWidth: 100 },
  ];

  const domainRowsValue = [
    { id: 1, label: 'Consultation Skills', value: 'consultation_skills' },
    { id: 2, label: 'Data Gathering Skills', value: 'data_gathering_skills' },
    { id: 3, label: 'Counselling Skills', value: 'counselling_skills' },
    { id: 4, label: 'Examination Skills', value: 'examination_skills' },
    { id: 5, label: 'Management Skills and Investigation', value: 'management_and_investigation_skills' }
  ];

  const formatPercentage = (value) => {
    if (value !== undefined) {
      if (value % 1 === 0) {
        return value;
      } else {
        return value.toFixed(2);
      }
    } else {
      return 0;
    }
  };

  const scoreData = scoreDetails?.percentageScores;
  const totalCount = scoreDetails?.totalCount;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  color: '#000',
                  backgroundColor: "#FAE9DD"
                }
              }}
            >
              {candidateColumns?.map(column => (
                <TableCell
                  key={column.id}
                  align={'center'}
                  sx={{ minWidth: column.minWidth }}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {domainRowsValue?.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: '130px' }}>
                  {item?.label}
                </TableCell>
                <TableCell align='center'>
                  {formatPercentage(scoreData?.[item.value]?.Poor)}
                </TableCell>
                <TableCell align='center'>
                  {formatPercentage(scoreData?.[item.value]?.Unsatisfactory)}
                </TableCell>
                <TableCell align='center'>
                  {formatPercentage(scoreData?.[item.value]?.Competent)}
                </TableCell>
                <TableCell align='center'>
                  {formatPercentage(scoreData?.[item.value]?.Excellent)}
                </TableCell>
                <TableCell align='center'>
                  {totalCount ?? 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OverallProgressTable;