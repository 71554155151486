import React, { useEffect, useState } from 'react';
import styles from './Friends.module.scss';
import { useNavigate } from 'react-router-dom';
import CometUserSkeletonList from '../../../../components/SkeletonLoader/cometUserLoader';
import { getCometFriends } from '../../../../services/cometChat.service';
import toast from 'react-hot-toast';

const Friends = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cometFriends, setCometFriends] = useState([]);

  const fetchCometFriends = async () => {
    try {
      const uid = localStorage.getItem('comet_uid');
      const result = await getCometFriends(uid);

      if (result?.status) {
        setCometFriends(result?.data);
      } else {
        toast.error(result?.error);
      }

      setLoading(false);
    } catch (error) {
      toast.error('Error fetching friends');
      console.error('Error fetching friends:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCometFriends();
  }, []);

  if (loading) {
    return <CometUserSkeletonList />
  }

  return (
    <div className={styles.friends}>
      <div className={styles.header}>
        Friends List
      </div>

      <div className={styles.friendsCardArea}>
        {cometFriends.length === 0 ? (
          <p className={styles.noFriendsMsg}>No friends available.</p>
        ) : (
          cometFriends.map((item, index) => {
            const user_id = parseInt(item?.uid);
            return (
              <div className={styles.friendsCard} key={index}>
                <div className={styles.imageAndName}>
                  <img
                    src={item?.avatar ? item.avatar : '../images/avatars/default-avatar.jpg'}
                    alt='profile'
                    width={120}
                    height={120}
                    style={{ objectFit: 'cover', borderRadius: '30px' }}
                  />
                  <span>{item?.name}</span>
                </div>
                <div
                  className={styles.viewProfile}
                  onClick={() => navigate(`/user-details/${user_id}`)}
                >
                  View Profile
                </div>
              </div>
            )
          }
          ))}
      </div>
    </div>
  );
};

export default Friends;