import React, { useState } from 'react';
import styles from './Upcoming.module.scss';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { LiaUserNurseSolid } from "react-icons/lia";
import CommonDeleteModal from '../../../../components/Modal/CommonDeleteModal';
import SessionDialogBox from '../../../../components/SessionDialogBox/index';
import { useAuth } from "../../../../hooks/useAuth";
import moment from 'moment';

const UpcomingSession = (props) => {
  const { profile } = useAuth();
  const [showSessionModal, setShowSessionModal] = useState(false);
  const { sessionList, showDeleteModal, setShowDeleteModal, sessionId, setSessionId, handleDelete } = props;

  const userSessionLabel = (created_by, invited_user, user_role) => {
    if (profile?.id === created_by && user_role === 'Examiner') {
      return 'Examiner';
    } else if (profile?.id === created_by && user_role === 'Candidate') {
      return 'Candidate';
    } else if (profile?.id === invited_user && user_role === 'Examiner') {
      return 'Candidate';
    } else if (profile?.id === invited_user && user_role === 'Candidate') {
      return 'Examiner';
    } else {
      return 'Observer';
    }
  };

  const inviteeLabel = (item) => {
    if (profile?.id === item?.created_by && item?.user_role === 'Examiner') {
      return 'Candidate';
    } else if (profile?.id === item?.created_by && item?.user_role === 'Candidate') {
      return 'Examiner';
    } else if (profile?.id === item?.invited_user && item?.user_role === 'Examiner') {
      return 'Examiner';
    } else if (profile?.id === item?.invited_user && item?.user_role === 'Candidate') {
      return 'Candidate';
    }
  };

  const inviteeName = (item) => {
    if (profile?.id === item?.created_by) {
      return `${item?.invitee?.first_name} ${item?.invitee?.last_name}`;
    } else if (profile?.id === item?.invited_user) {
      return `${item?.examiner?.first_name} ${item?.examiner?.last_name}`;
    }
  };

  const handleSessionClick = (e, sessionId) => {
    e.stopPropagation();
    setShowSessionModal(true);
    setSessionId(sessionId);
  };

  const handleDeleteClick = (e, sessionId) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setSessionId(sessionId);
  };

  return (
    <div className={styles.sessionContent}>
      {sessionList?.length === 0 ? (
        <p className={styles.noSessionMsg}>No session available</p>
      ) : (
        sessionList?.map((item, index) => {
          const start_date = moment(item?.start_date).format('ddd, D MMMM');
          const start_time = moment(item?.start_date).format("h:mm A");
          const end_time = moment(item?.end_date).add(1, 'minute').format("h:mm A");
          return (
            <div
              key={index}
              className={styles.sessionCard}
              onClick={(e) => handleSessionClick(e, item?.id)}
            >
              <div className={styles.header}>
                <span>{item?.session_name}</span>
                <span onClick={(e) => handleDeleteClick(e, item?.id)}>
                  <DeleteOutlineIcon fontSize={'small'} />
                </span>
              </div>

              <div className={styles.content}>
                <div className={styles.line}>
                  <CalendarMonthOutlinedIcon />
                  <span>{start_date}</span>
                </div>

                <div className={styles.line}>
                  <ScheduleIcon />
                  <span>{start_time} - {end_time}</span>
                </div>

                <div className={styles.line}>
                  <LiaUserNurseSolid fontSize={'1.7rem'} />
                  <span>{inviteeName(item)} ( {inviteeLabel(item)} )</span>
                </div>

                <div className={styles.userSessionLabel}>
                  {userSessionLabel(item?.created_by, item?.invited_user, item?.user_role)}
                </div>
              </div>
            </div>
          )
        })
      )}

      <CommonDeleteModal
        open={showDeleteModal}
        handleClose={() => { setShowDeleteModal(false) }}
        id={sessionId}
        title={"upcoming session"}
        handleDelete={handleDelete}
      />

      {showSessionModal &&
        <SessionDialogBox
          open={showSessionModal}
          onClose={() => setShowSessionModal(false)}
          sessionId={sessionId}
        />
      }
    </div>
  );
};

export default UpcomingSession;