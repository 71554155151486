import React, { useState } from "react";
import styles from "./Register.module.scss";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signUp } from "../../services/user.service";
import toast from "react-hot-toast";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      termsChecked: false
    }
  });

  const handleRegister = async (data) => {
    if (watch('termsChecked')) {
      setLoading(true);

      const objData = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password
      };

      const result = await signUp(objData);

      if (result.status) {
        const successMessage = 'We have sent an email to your email address.\nPlease verify your email.';
        toast.success(successMessage, {
          autoClose: 5000
        });
        navigate('/login');
      } else {
        toast.error(result.error);
      }
      setLoading(false);
    } else {
      toast.error('Please accept terms & conditions');
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.logo}>
          <img
            src="../images/logos/Logo-no-slogan.png"
            alt="logo"
            height={50}
          />
        </div>
        <div className={styles.title}>
          <h4>Join us Today!</h4>
        </div>

        <form autoComplete="off" onSubmit={handleSubmit(handleRegister)}>
          <div className={styles.inputContainer}>
            <label htmlFor="firstName">
              First Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="firstName"
              autoFocus
              {...register('firstName', { required: true })}
              error={errors.firstName}
            />
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              {...register('lastName', { required: true })}
              error={errors.lastName}
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="email">
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              {...register('email', { required: true })}
              error={errors.email}
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="password">
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <div className={styles.passwordInput}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                {...register('password', { required: true })}
                error={errors.password}
              />
              <div className={styles.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </div>
            </div>
          </div>

          <div className={styles.options}>
            <div className={styles.privacyPolicy}>
              <input
                type="checkbox"
                name="termsChecked"
                style={{ transform: "scale(1.3)", marginTop: "2px" }}
                {...register('termsChecked', { required: true })}
                error={errors.termsChecked}
              />
              <span>I agree to</span>
              <a href="/">privacy policy & terms <span style={{ color: "red" }}>*</span>
              </a>
            </div>
          </div>

          <button
            type="submit"
            className={styles.signupButton}
            disabled={loading}
          >
            {loading ? "Loading..." : "SIGN UP"}
          </button>
          <div className={styles.footer}>
            <span>
              Already have an account? <a href="/login">Sign in instead</a>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;