import React, { useEffect } from 'react';
import styles from './Received.module.scss';
import { useConnectionRequest } from '../../../../../hooks/useConnectionRequest';
import { acceptOrDeclineConnectionRequest } from '../../../../../services/connections.service';
import Pagination from '../../../../../components/Pagination/index';
import toast from 'react-hot-toast';

const ReceivedRequest = () => {
  const {
    page,
    limit,
    setPage,
    totalCount,
    receivedRequest,
    fetchReceivedRequest
  } = useConnectionRequest();

  const handleReceivedRequest = async (request_id, request_status) => {
    const result = await acceptOrDeclineConnectionRequest({ request_id, request_status });

    if (result?.status) {
      toast.success(request_status);
      await fetchReceivedRequest(page, limit);
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    fetchReceivedRequest(page, limit);
  }, [page]);

  return (
    <div className={styles.connectionRequest}>
      <div className={styles.receivedCardArea}>
        {receivedRequest?.length === 0 ? (
          <p className={styles.noRequestMsg}>No request available</p>
        ) : (
          receivedRequest?.map((item, index) => {
            const image = item?.requestData?.user_detail?.avatar;
            const avatar = `${process.env.REACT_APP_IMAGE_URL}/users/${item?.requestData?.user_detail?.avatar}`;
            return (
              <div className={styles.receivedCard} key={index}>
                <div className={styles.imageAndName}>
                  <img
                    src={image ? avatar : '../images/avatars/default-avatar.jpg'}
                    alt='profile'
                    width={130}
                    height={130}
                    style={{ objectFit: 'cover', borderRadius: '30px' }}
                  />

                  <span>{`${item?.requestData?.first_name} ${item?.requestData?.last_name}`}</span>
                </div>

                <div className={styles.buttons}>
                  <div
                    className={styles.decline}
                    onClick={() => handleReceivedRequest(item?.id, 'declined')}
                  >
                    Decline
                  </div>
                  <div
                    className={styles.approve}
                    onClick={() => handleReceivedRequest(item?.id, 'accepted')}
                  >
                    Approve
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>

      <div className={styles.paginationArea}>
        <Pagination
          page={page}
          count={Math.ceil(totalCount / limit)}
          onPageChange={(e, value) => setPage(value)}
        />
      </div>
    </div>
  );
};

export default ReceivedRequest;