import React, { useEffect, useState } from 'react';
import styles from './Dashboard.module.scss';
import ProfileOverview from './components/ProfileOverview/index';
import CaseCount from './components/CaseCount/index';
import CandidateFeedback from './components/CandidateFeedback';
import UpcomingSessions from './components/UpcomingSessions';
import { getDashBoardOverView } from '../../services/dashBoard.service';
import toast from 'react-hot-toast';

const Dashboard = () => {
  const [overViewCount, setOverviewCount] = useState(0);

  const dashboardOverviewCount = async () => {
    const result = await getDashBoardOverView();

    if (result.status) {
      setOverviewCount(result?.data);
    } else {
      toast.error(result?.error)
    }
  };

  useEffect(() => {
    dashboardOverviewCount();
  }, []);

  return (
    <div className={styles.dashboard}>
      <div className={styles.leftContainer}>
        <ProfileOverview />
        <CaseCount overViewCount={overViewCount} />
        <CandidateFeedback />
      </div>

      <div className={styles.rightContainer}>
        <UpcomingSessions />
      </div>
    </div>
  );
};

export default Dashboard;