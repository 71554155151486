import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  Skeleton,
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UserDetailSkeleton = () => {
  return (
    <>
      <Box
        onClick={() => window.history.back()}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h5'>Profile Details</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          border: '1px solid #ced0d4',
          marginTop: '20px',
          borderRadius: '8px',
          overflow: 'hidden',
          background: '#f0f0f0'
        }}
      >
        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
          <Box sx={{ padding: '10px' }}>
            <Skeleton width={500} />
          </Box>
          <Box>
            <Skeleton width={500} />
            <Skeleton width={500} />
          </Box>
        </Box>
      </Box >

      <Box sx={{ marginTop: '20px' }}>
        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <Skeleton width={900} />
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <Skeleton width={800} />
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <Skeleton width={700} />
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <Skeleton width={600} />
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <Skeleton width={500} />
        </Accordion>

        <Accordion
          sx={{
            border: '1px solid #ced0d4',
            borderRadius: '8px',
            marginBottom: '10px',
            '&:before': {
              display: 'none'
            },
            '&:first-of-type': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }}
        >
          <Skeleton width={400} />
        </Accordion>
      </Box>
    </>
  );
};

export default UserDetailSkeleton;