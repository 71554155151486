import React, { useEffect, useState } from "react";
import styles from "./Messages.module.scss";
import { Tabs, Tab } from "@mui/material";
import { BsChatSquareText } from "react-icons/bs";
import { MdOutlineGroups2 } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";
import { useMessageTab } from "../../hooks/useMessageTab";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { useLocation } from "react-router-dom";
import { initializeCometApp } from "../../utils/cometChat";
import Chats from "./components/Chats/index";
import Groups from "./components/Groups/index";
import Friends from "./components/Friends/index";
import BlockedUsers from "./components/BlockedUsers/index";
import MessageSkeleton from "../../components/SkeletonLoader/messageLoader";
import toast from "react-hot-toast";

const tabStyles = {
  width: "25%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
};

const Messages = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipientId = queryParams.get("uid");
  const { tab, setTab } = useMessageTab();
  const [loading, setLoading] = useState(true);
  const [specificUser, setSpecificUser] = useState(null);

  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  const cometChatInitialize = async () => {
    try {
      const cometChatUID = localStorage.getItem("comet_uid");

      if (cometChatUID) {
        await initializeCometApp();
      }

      if (recipientId) {
        await openChatUserWithUID(recipientId);
      }

      setLoading(false);
    } catch (error) {
      toast.error("Failed to initialize CometChat");
      console.error("CometChat initialization error:", error);
      setLoading(false);
    }
  };

  const openChatUserWithUID = async (recipientUID) => {
    try {
      const user = await CometChat.getUser(recipientUID);

      if (user) {
        setSpecificUser(user);
      } else {
        toast.error("User not found");
      }
    } catch (error) {
      toast.error("Error fetching user");
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    cometChatInitialize();
  }, [recipientId]);

  return (
    <div className={styles.messages}>
      <div className={styles.messageTabs}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#099EAF",
              borderRadius: "4px",
              height: "5px",
            },
          }}
          sx={{
            "& .MuiTab-root": {
              color: "#010101",
              fontWeight: 400,
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              fontSize: "16px",
              "&.Mui-selected": {
                color: "#099EAF",
                fontWeight: 600,
              },
            },
          }}
        >
          <Tab
            icon={<BsChatSquareText fontSize={"1.3rem"} style={{ margin: 0 }} />}
            label="Chats"
            sx={tabStyles}
          />
          <Tab
            icon={<MdOutlineGroups2 fontSize={"1.5rem"} style={{ margin: 0 }} />}
            label="Groups"
            sx={tabStyles}
          />
          <Tab
            icon={<FaUserFriends fontSize={"1.5rem"} style={{ margin: 0 }} />}
            label="Friends"
            sx={tabStyles}
          />
          <Tab
            icon={<ImBlocked fontSize={"1.3rem"} style={{ margin: 0 }} />}
            label="Blocked Users"
            sx={tabStyles}
          />
        </Tabs>
      </div>

      {loading ? (
        <div><MessageSkeleton /></div>
      ) : (
        <>
          {tab === 0 && <Chats specificUser={specificUser} />}
          {tab === 1 && <Groups />}
          {tab === 2 && <Friends />}
          {tab === 3 && <BlockedUsers />}
        </>
      )}
    </div >
  );
};

export default Messages;