import React from 'react';
import styles from './Billing.module.scss';

const Billing = () => {
  return (
    <div className={styles.billing}>
      <h5 className={styles.header}>Billing</h5>
      <div className={styles.contentArea}>
        <div className={styles.content}>
          <div className={styles.section}>
            <h5>Billing Address</h5>
            <input type='text' className={styles.input} />
          </div>

          <div className={styles.section}>
            <h5>Preferred Billing Method :</h5>
            <div className={styles.radioGroup}>
              <label>
                <input type="radio" name="billingMethod" value="Credit Card" /> Credit Card
              </label>
              <label>
                <input type="radio" name="billingMethod" value="Debit Card" /> Debit Card
              </label>
            </div>
          </div>

          <div className={styles.section}>
            <h5>Save/Delete Card Information :</h5>
            <div className={styles.cardInfo}>
              <span>1234 4567 7890 1234</span>
              <div className={styles.buttons}>
                <button className={`${styles.button} ${styles.save}`}>Save</button>
                <button className={`${styles.button} ${styles.delete}`}>Delete</button>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <h5>Subscriptions & Plans :</h5>
            <div className={styles.subscription}>
              <span className={styles.currentPlan}>Current Plan</span>
              <span className={styles.planType}>Basic</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;