import axios from "axios";
import { ApiUrls } from "../api/apiUrls";
import { axiosInstance } from "../api/Interceptors";

/**
 *
 * @method POST
 * @param { Object } credentials
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Login the user.
 */
export const login = async (credentials) => {
  try {
    const result = await axios.post(ApiUrls.LOGIN, credentials);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Logout the user.
 */
export const logOut = async () => {
  try {
    const result = await axiosInstance.patch(ApiUrls.LOGOUT);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method POST
 * @param { Object } data
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Create the user.
 */
export const signUp = async (data) => {
  try {
    const result = await axios.post(ApiUrls.CREATE_USER, data);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { String } token 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Verify the user email while creating the user account.
 */
export const verifyEmailToken = async (token) => {
  try {
    const result = await axios.get(`${ApiUrls.VERIFY_EMAIL_TOKEN}/${token}`);
    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the user list.
 */
export const getUsersList = async () => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_USER_LIST}?limit=25&offSet=0`);
    return {
      status: true,
      data: result?.data?.data?.rows
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the profile details of login user.
 */
export const getProfile = async () => {
  try {
    const result = await axiosInstance.get(ApiUrls.GET_PORFILE);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @param { Object } data 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the user by id.
 */
export const updateUser = async (data) => {
  try {
    const result = await axiosInstance.put(ApiUrls.UPDATE_USER, data);
    return {
      status: true,
      data: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @param { String } email
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get the profile details of login user.
 */
export const forgotPassword = async (email) => {
  try {
    const result = await axios.post(ApiUrls.FORGOT_PASSWORD, email);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @param { Object } data
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Reset password after OTP verification.
 */
export const resetPassword = async (data) => {
  try {
    const result = await axios.patch(ApiUrls.RESET_PASSWORD, data);
    return {
      status: true,
      data: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @param { Object } data
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Change password after verification of previous password.
 */
export const changePassword = async (data) => {
  try {
    const result = await axiosInstance.patch(ApiUrls.CHANGE_PASSWORD, data);
    return {
      status: true,
      data: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @param { Object } data
 * @param { String } type
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the profile picture.
 */
export const uploadProfilePicture = async (data, type) => {
  try {
    const result = await axiosInstance.patch(`${ApiUrls.UPDATE_PROFILE_PICTURE}?type=${type}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return {
      status: true,
      data: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message
    }
  }
};

/**
 *
 * @method PATCH
 * @param { Object } data
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the email settings.
 */
export const updateEmailSetting = async (data) => {
  try {
    const result = await axiosInstance.patch(`${ApiUrls.UPDATE_EMAIL_SETTING}`, { send_email_setting: data });
    return {
      status: true,
      data: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message?.data?.message
    }
  }
};

/**
 *
 * @method GET
 * @param { Integer } userId 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get specific user details.
 */
export const getSpecificUserDetails = async (userId) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_SPECIFIC_USER_DETAIL}/${userId}`);
    return {
      status: true,
      data: result?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message?.data?.message
    }
  }
};