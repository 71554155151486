import React from 'react';
import styles from './WorkStudy.module.scss';
import { MenuItem, Select } from '@mui/material';

const WorkStudyInformation = ({ register, watch }) => {
  return (
    <div className={styles.workStudyInformation}>
      <div className={styles.content}>
        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Years of Practice</span>
            <Select
              name='year_of_practice'
              value={watch('year_of_practice') || ''}
              {...register('year_of_practice')}
              className={styles.selectBox}
            >
              <MenuItem value="null">Select</MenuItem>
              <MenuItem value="1-5">1-5 Years</MenuItem>
              <MenuItem value="6-10">6-10 Years</MenuItem>
              <MenuItem value="11-15">11-15 Years</MenuItem>
              <MenuItem value="16-20">15-20 Years</MenuItem>
              <MenuItem value=">20">{'>20 Years'}</MenuItem>
            </Select>
          </div>

          <div className={styles.field}>
            <span className={styles.label}>Work Schedule Type</span>
            <Select
              name='work_schedule_type'
              value={watch('work_schedule_type') || ''}
              {...register('work_schedule_type')}
              className={styles.selectBox}
            >
              <MenuItem value="null">Select</MenuItem>
              <MenuItem value="Fixed">Fixed</MenuItem>
              <MenuItem value="Shift">Shift</MenuItem>
            </Select>
          </div>

          <div className={styles.field}>
            <span className={styles.label}>Available Hours</span>
            <Select
              name='available_study_hour'
              value={watch('available_study_hour') || ''}
              {...register('available_study_hour')}
              className={styles.selectBox}
            >
              <MenuItem value="null">Select</MenuItem>
              <MenuItem value="1">1 hrs/week</MenuItem>
              <MenuItem value="2">2 hrs/week</MenuItem>
              <MenuItem value="3">3 hrs/week</MenuItem>
              <MenuItem value="4">4 hrs/week</MenuItem>
              <MenuItem value="5">5 hrs/week</MenuItem>
              <MenuItem value="6">6 hrs/week</MenuItem>
              <MenuItem value="7">7 hrs/week</MenuItem>
              <MenuItem value="8">8 hrs/week</MenuItem>
              <MenuItem value="9">9 hrs/week</MenuItem>
              <MenuItem value="10">10 hrs/week</MenuItem>
            </Select>
          </div>

          <div className={styles.field}>
            <span className={styles.label}>Preferred Study Method</span>
            <Select
              name='preferred_study_method'
              value={watch('preferred_study_method') || ''}
              {...register('preferred_study_method')}
              className={styles.selectBox}
            >
              <MenuItem value="null">Select</MenuItem>
              <MenuItem value="Online">Online</MenuItem>
              <MenuItem value="In-Person">In-Person</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </div >
  );
};

export default WorkStudyInformation;