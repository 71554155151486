const baseUrl = process.env.REACT_APP_API_URL;

export const ApiUrls = {
  LOGIN: baseUrl + "auth/user/login",
  LOGOUT: baseUrl + "auth/logout",
  CREATE_USER: baseUrl + "user/create",
  GET_USER_LIST: baseUrl + "user/user-list",
  GET_PORFILE: baseUrl + "user/profile",
  UPDATE_USER: baseUrl + "user/update",
  FORGOT_PASSWORD: baseUrl + "user/forgot-password",
  RESET_PASSWORD: baseUrl + "user/reset-password",
  CHANGE_PASSWORD: baseUrl + "user/change-password",
  VERIFY_EMAIL_TOKEN: baseUrl + "user/verify-email",
  UPDATE_PROFILE_PICTURE: baseUrl + "user/profile-picture",
  UPDATE_EMAIL_SETTING: baseUrl + "user/setting/email",

  GET_ALL_CATEGORIES: baseUrl + "learningHub/categories",
  GET_SPECIFIC_CATEGORY_DETAILS: baseUrl + "learningHub/category",
  GET_ALL_CATEGORY_TOPICS: baseUrl + "learningHub/topics",
  GET_CATEGORY_TOPIC_DETAIL: baseUrl + "learningHub/topic-details",

  GET_ALL_CLINICAL_SYSTEM: baseUrl + "clinical-system",

  CREATE_SESSIONS: baseUrl + "sessions/create",
  GET_ALL_SESSIONS: baseUrl + "sessions",
  GET_SPECIFIC_SESSION_DETAIL: baseUrl + "sessions",
  GET_INVITATION_NOTIFICATION: baseUrl + "sessions/invitation",
  UPDATE_SESSION: baseUrl + "sessions/update",
  DELETE_SESSION: baseUrl + "sessions/delete",
  GET_CASE_SELECTION_DETAILS: baseUrl + "sessions/case-selection",
  GET_CANDIDATE_SESSION_LOG: baseUrl + "sessions/session/logs",
  GET_CANDIDATE_SPECIFIC_SESSION_LOG: baseUrl + "sessions/session/logs",
  ON_RECORDING_COMPLETED: baseUrl + "sessions/recording-stop",
  ON_LEAVE_SESSION: baseUrl + "sessions/end-session",
  UPDATE_SESSION_STATUS: baseUrl + "sessions/status",
  GET_SESSION_RECORDING_FILE: baseUrl + "sessions/get-recording",

  GET_FEEDBACK_SECTIONS: baseUrl + "feedback",
  SAVE_SESSION_FEEDBACK: baseUrl + "feedback/save",
  SAVE_TOPIC_FEEDBACK_SCORE: baseUrl + "feedback/save/score",
  GET_SESSION_FEEDBACK_BY_ID: baseUrl + "feedback/session-feedback",
  GET_PROGRESS_FEEDBACK_SCORE: baseUrl + "feedback/progress-score",

  GET_DASHBOARD_OVERVIEW: baseUrl + "dashboard/overview",

  GET_RANDOM_FRIENDS: baseUrl + "friends/get-random-connections",
  GET_ALL_FRIENDS: baseUrl + "friends/get-friends",
  REMOVE_FRIENDS: baseUrl + "friends/delete-friends",
  SEND_CONNECTION_REQUEST: baseUrl + "friends/send-request",
  ACCEPT_DECLINE_CONNECTION_REQUEST: baseUrl + "friends/accept-or-declined-request",
  GET_SENT_CONNECTION_REQUEST: baseUrl + "friends/get-sended-request",
  GET_RECEIVED_CONNECTION_REQUESTS: baseUrl + "friends/get-friend-request",
  CANCEL_CONNECTION_REQUEST: baseUrl + "friends/cancel-sent-request",
  GET_SPECIFIC_USER_DETAIL: baseUrl + "friends/get-profile-data",

  GET_COMET_CHAT_FRIENDS: baseUrl + "comet-user/friends",
  GET_COMET_CHAT_BLOCKED_USERS: baseUrl + "comet-user/blocked-users",
  COMET_CHAT_UNBLOCK_USERS: baseUrl + "comet-user/unblock-user"
};