import React from 'react';
import { Box, Skeleton } from '@mui/material';

const MessageSkeleton = () => {
  return (
    <Box sx={{ padding: '20px' }}>
      <Skeleton variant='text' height={20} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <Skeleton variant='rectangular' height={150} sx={{ marginTop: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <Skeleton variant='text' height={20} sx={{ marginTop: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <Skeleton variant='rectangular' height={150} sx={{ marginTop: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <Skeleton variant='text' height={20} sx={{ marginTop: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <Skeleton variant='rectangular' height={120} sx={{ marginTop: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
    </Box>
  );
};

export default MessageSkeleton;