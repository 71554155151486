import React, { useState } from "react";
import styles from "./Sessions.module.scss";
import { Tabs, Tab } from "@mui/material";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useSessionList from "../../hooks/custom/useSessionList";
import UpcomingSession from "./components/Upcoming/index";
import PastSession from "./components/Past/index";
import TipsAndGuidelines from "./components/TipsAndGuidelines/index";
import InvitationList from "./components/Invitations";
import AddSession from "../../components/AddSession";
import { deleteSession } from "../../services/session.service";
import toast from "react-hot-toast";

const tabStyles = {
  width: "25%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  minHeight: 'unset'
};

const Sessions = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { sessionList, getSessionList } = useSessionList('upcoming');

  const handleTabChange = async (e, newValue) => {
    setTabIndex(newValue);
    if (newValue === 0) {
      await getSessionList("upcoming");
    } else if (newValue === 1) {
      await getSessionList("past");
    }
  };

  const handleDeleteSession = async () => {
    const result = await deleteSession(sessionId);

    if (result.status) {
      if (tabIndex === 0) {
        await getSessionList('upcoming');
      } else {
        await getSessionList("past");
      }
      setShowDeleteModal(false);
      toast.success(result?.msg);
    } else {
      toast.error(result?.error);
    }
  };

  return (
    <div className={styles.sessions}>
      <div className={styles.leftContainer}>
        <div className={styles.headingContainer}>
          <h4 className={styles.heading}>Overview</h4>
          <button
            className={styles.addSession}
            onClick={() => setShowModal(true)}
          >
            Add Session
          </button>
        </div>

        <div className={styles.sessionTabs}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#1EA7B6",
              },
            }}
            sx={{
              "& .MuiTab-root": {
                color: "#",
                fontWeight: 400,
                background: "#CDEAED",
                borderRadius: "20px 20px 0 0",
                textTransform: 'capitalize',
                "&.Mui-selected": {
                  color: "#56d1e0",
                  fontWeight: 600,
                  background: "#fff",
                }
              }
            }}
          >
            <Tab
              icon={<PlayCircleOutlinedIcon />}
              label="Upcoming Sessions"
              sx={tabStyles}
            />
            <Tab
              icon={<HistoryOutlinedIcon />}
              label="Past Sessions"
              sx={tabStyles}
            />
            <Tab
              icon={<InfoOutlinedIcon />}
              label="Tips And Guidelines"
              sx={tabStyles}
            />
          </Tabs>
        </div>

        {tabIndex === 0 &&
          <UpcomingSession
            sessionList={sessionList}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            sessionId={sessionId}
            setSessionId={setSessionId}
            handleDelete={handleDeleteSession}
          />
        }

        {tabIndex === 1 &&
          <PastSession
            sessionList={sessionList}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            sessionId={sessionId}
            setSessionId={setSessionId}
            handleDelete={handleDeleteSession}
          />
        }

        {tabIndex === 2 && <TipsAndGuidelines />}
      </div>

      <div className={styles.rightContainer}>
        <InvitationList getSessionList={getSessionList} />
      </div>

      {showModal && (
        <AddSession
          hide={() => setShowModal(false)}
          getSessionList={getSessionList}
        />
      )}
    </div>
  );
};

export default Sessions;