import React, { useEffect, useState } from 'react';
import styles from './BlockedUser.module.scss';
import CometUserSkeletonList from '../../../../components/SkeletonLoader/cometUserLoader';
import { getBlockedUsers, unblockUsers } from '../../../../services/cometChat.service';
import toast from 'react-hot-toast';

const BlockedUsers = () => {
  const [loading, setLoading] = useState(true);
  const [blockedUsers, setBlockedUsers] = useState([]);

  const fetchBlockedUsersList = async () => {
    const uid = localStorage.getItem('comet_uid');
    const result = await getBlockedUsers(uid);

    if (result.status) {
      setBlockedUsers(result?.data?.data);
    } else {
      toast.error(result?.error);
    }

    setLoading(false);
  };

  const unblockCometChatUser = async (blocked_id) => {
    const result = await unblockUsers(blocked_id);

    if (result.status) {
      toast.success('User unblock successfully');
      await fetchBlockedUsersList();
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    fetchBlockedUsersList();
  }, []);

  if (loading) {
    return <CometUserSkeletonList />
  }

  return (
    <div className={styles.blockedUsers}>
      <div className={styles.header}>
        Blocked Users
      </div>

      <div className={styles.blockedUserCardArea}>
        {blockedUsers.length === 0 ? (
          <p className={styles.noUserMsg}>No blocked users available</p>
        ) : (
          blockedUsers.map((item, index) => (
            <div
              key={index}
              className={styles.blockedUserCard}
            >
              <div className={styles.imageAndName}>
                <img
                  src='https://img.freepik.com/free-photo/beautiful-woman-park-last-sunny-days_144627-16058.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1719878400&semt=ais_user'
                  alt='profile'
                  width={120}
                  height={120}
                  style={{ objectFit: 'cover', borderRadius: '40px' }}
                />
                <span>{item?.name}</span>
              </div>
              <div
                className={styles.unblock}
                onClick={() => unblockCometChatUser(item?.id)}
              >
                Unblock
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BlockedUsers;