import React, { useEffect, useState } from 'react';
import styles from './ClinicalSystem.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSpecificCategoryDetails } from '../../../../services/learningHub.service';
import { getAllClinicalSystem } from '../../../../services/clinicalSystem.service';
import toast from 'react-hot-toast';

const ClinicalSystem = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const catId = queryParams.get('catId');
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [systemSectionList, setSystemSectionList] = useState([]);
  const IMAGE_URL = `${process.env.REACT_APP_IMAGE_URL}/category/${categoryDetails?.image}`;

  const handleSelectClinicalSystem = (clincalId, clinicalTitle) => {
    const topicList = categoryDetails?.topics?.filter((topic) => topic.clinical_type === clincalId);
    navigate(
      `/learning-hub/topic-details/${topicList[0]?.id}?category=${category}&type=${clinicalTitle}`
    );
  };

  const fetchCategoryDetailsById = async () => {
    const result = await getSpecificCategoryDetails(catId);

    if (result.status) {
      setCategoryDetails(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  const getClinicalSystem = async () => {
    const result = await getAllClinicalSystem(catId);

    if (result.status) {
      setSystemSectionList(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    if (catId) {
      fetchCategoryDetailsById();
      getClinicalSystem();
    }
  }, [catId]);

  return (
    <div className={styles.clinicalSystem}>
      <div className={styles.header}>
        <div className={styles.categoryImage}>
          <img
            src={IMAGE_URL}
            alt='category-image'
            width={190}
            height={190}
          />
        </div>

        <div className={styles.categoryTitle}>
          <span className={styles.clinicalTitle}>Clinical System</span>
          <span className={styles.title}>{categoryDetails?.title}</span>
        </div>
      </div>

      <div className={styles.content}>
        {systemSectionList?.map((item, index) => {
          const topicList = categoryDetails?.topics?.filter((topic) => topic.clinical_type === item?.id);
          const IMAGE_URL = `${process.env.REACT_APP_IMAGE_URL}/clinical-system/${item?.image}`;
          return (
            <div className={styles.clinicalBox} key={index}>
              <img
                src={IMAGE_URL}
                alt='clinical-image'
                height={150}
                width={150}
                className={styles.clinicalImage}
              />

              <div className={styles.clinicalTitle}>
                <span>{item?.title}</span>
                <button
                  className={topicList?.length === 0 ? styles.disabledButton : ''}
                  onClick={() => handleSelectClinicalSystem(item?.id, item?.title)}
                  disabled={topicList?.length === 0}
                >
                  See Topics
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ClinicalSystem;