import React from 'react';
import styles from './Contact.module.scss';
import { MenuItem, Select } from '@mui/material';
import { nationality } from "../../../../../../utils/nationality";

const ContactInformation = ({ register, watch, errors }) => {
  const isPhoneNumberValid = (phoneNumber) => {
    const numberRegex = /^[0-9+\s]+$/;
    return numberRegex.test(phoneNumber);
  };

  return (
    <div className={styles.conatctInformation}>
      <div className={styles.content}>
        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Phone</span>
            <input
              type='text'
              name='phone'
              {...register('phone', {
                validate: (value) => !value || isPhoneNumberValid(value)
              })}
              className={styles.inputText}
            />
          </div>
        </div>

        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Email</span>
            <input
              type='text'
              name='email'
              {...register('email', { required: true })}
              error={errors.email}
              className={styles.inputText}
            />
          </div>
        </div>

        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>City</span>
            <input
              type='text'
              name='city'
              {...register('city')}
              className={styles.inputText}
            />
          </div>
        </div>

        <div className={`${styles.formLine}`}>
          <div className={styles.field}>
            <span className={styles.label}>Country</span>
            <Select
              name='country'
              value={watch('country') || ''}
              {...register('country')}
              className={styles.selectBox}
            >
              <MenuItem value="null">Select</MenuItem>
              {nationality?.map((item, index) => (
                <MenuItem value={item?.country} key={index}>{item?.country}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ContactInformation;