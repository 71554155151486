import React from 'react';
import styles from './Filter.module.scss';
import { MenuItem, Select } from '@mui/material';
import { nationality } from '../../../../../utils/nationality';
import { languageSpoken } from '../../../../../utils/languageSpoken';

const FindConnectionFilter = (props) => {
  const { filters, handleFilterChange, handleClearFilters, handleLanguageChange } = props;

  return (
    <div className={styles.filterContainer}>
      <div className={styles.innerFilterContainer}>
        <Select
          name='title'
          className={styles.customSelect}
          value={filters.title}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Title'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          <MenuItem value='Resident Physician'>Resident Physician</MenuItem>
          <MenuItem value='Junior Doctor'>Junior Doctor</MenuItem>
          <MenuItem value='Senior Doctor'>Senior Doctor</MenuItem>
          <MenuItem value='Specialist'>Specialist</MenuItem>
          <MenuItem value='Consultant'>Consultant</MenuItem>
          <MenuItem value='General Practitioner'>General Practitioner</MenuItem>
          <MenuItem value='Others'>Others</MenuItem>
        </Select>

        <Select
          name='gender'
          className={styles.customSelect}
          value={filters.gender}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Gender'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          <MenuItem value='Male'>Male</MenuItem>
          <MenuItem value='Female'>Female</MenuItem>
          <MenuItem value='Others'>Preferred Not To Say</MenuItem>
        </Select>

        <Select
          name='nationality'
          className={styles.customSelect}
          value={filters.nationality}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Nationality'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          {nationality?.map((item, index) => (
            <MenuItem key={index} value={item?.nationality}>
              {item?.nationality}
            </MenuItem>
          ))}
        </Select>

        <Select
          name='country'
          className={styles.customSelect}
          value={filters.country}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Country'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          {nationality?.map(item => (
            <MenuItem value={item?.country}>{item?.country}</MenuItem>
          ))}
        </Select>

        <Select
          multiple
          name='language_spoken'
          value={filters.language_spoken}
          onChange={handleLanguageChange}
          className={styles.customSelect}
          displayEmpty
          renderValue={selected => {
            if (selected.length === 0) {
              return 'Language spoken'
            }
            return selected.join(', ')
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          {languageSpoken?.map(item => (
            <MenuItem key={item.id} value={item?.language}>
              {item?.language}
            </MenuItem>
          ))}
        </Select>

        <Select
          name='year_of_practice'
          className={styles.customSelect}
          value={filters.year_of_practice}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Year of practice'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          <MenuItem value='1-5'>1-5 Years</MenuItem>
          <MenuItem value='6-10'>6-10 Years</MenuItem>
          <MenuItem value='11-15'>11-15 Years</MenuItem>
          <MenuItem value='16-20'>15-20 Years</MenuItem>
          <MenuItem value='>20'>{'>20 Years'}</MenuItem>
        </Select>

        <Select
          name='work_schedule_type'
          className={styles.customSelect}
          value={filters.work_schedule_type}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Work schedule type'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          <MenuItem value='Fixed'>Fixed</MenuItem>
          <MenuItem value='Shift'>Shift</MenuItem>
        </Select>

        <Select
          name='available_study_hour'
          className={styles.customSelect}
          value={filters.available_study_hour}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Available hours'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          <MenuItem value='1'>1 hrs/week</MenuItem>
          <MenuItem value='2'>2 hrs/week</MenuItem>
          <MenuItem value='3'>3 hrs/week</MenuItem>
          <MenuItem value='4'>4 hrs/week</MenuItem>
          <MenuItem value='5'>5 hrs/week</MenuItem>
          <MenuItem value='6'>6 hrs/week</MenuItem>
          <MenuItem value='7'>7 hrs/week</MenuItem>
          <MenuItem value='8'>8 hrs/week</MenuItem>
          <MenuItem value='9'>9 hrs/week</MenuItem>
          <MenuItem value='10'>10 hrs/week</MenuItem>
        </Select>

        <Select
          name='preferred_study_method'
          className={styles.customSelect}
          value={filters.preferred_study_method}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return 'Preferred study method'
            }
            return selected
          }}
        >
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          <MenuItem value='Online'>Online</MenuItem>
          <MenuItem value='In-Person'>In-Person</MenuItem>
        </Select>

        <button
          className={styles.clearFilter}
          onClick={handleClearFilters}
        >
          Clear filters
        </button>
      </div>
    </div >
  );
};

export default FindConnectionFilter;