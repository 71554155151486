import React, { createContext, useEffect, useState } from 'react';
import { getReceivedConnectionRequest } from '../services/connections.service';

export const ConnectionRequestContext = createContext();

export const ConnectionRequestProvider = ({ children }) => {
  const limit = 10;
  const [receivedRequest, setReceivedRequest] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const fetchReceivedRequest = async (page, limit) => {
    const offset = (page - 1) * limit;
    const result = await getReceivedConnectionRequest(limit, offset);

    if (result.status) {
      setReceivedRequest(result?.data);
      setTotalCount(result?.data?.length);
    } else {
      console.error(result?.error);
    }
  };

  useEffect(() => {
    fetchReceivedRequest(page, limit);
  }, [page]);

  return (
    <ConnectionRequestContext.Provider
      value={{
        receivedRequest,
        totalCount,
        limit,
        page,
        setPage,
        setReceivedRequest,
        fetchReceivedRequest
      }}
    >
      {children}
    </ConnectionRequestContext.Provider>
  );
};