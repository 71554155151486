import React, { useState } from 'react';
import styles from './Personal.module.scss';
import { MenuItem, Select } from '@mui/material';
import { CiCamera } from "react-icons/ci";
import { nationality } from "../../../../../../utils/nationality";
import { languageSpoken } from "../../../../../../utils/languageSpoken";
import { useDropzone } from 'react-dropzone';
import { uploadProfilePicture } from '../../../../../../services/user.service';
import { useAuth } from '../../../../../../hooks/useAuth';
import toast from 'react-hot-toast';

const PersonalInformation = (props) => {
  const { register, watch, setValue, errors } = props;
  const { profile, getUserProfile } = useAuth();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedUserImg, setSelectedUserImg] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const UserImage = `${process.env.REACT_APP_IMAGE_URL}/users/${profile?.user_detail?.avatar}`;

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedImage(file);
    setSelectedUserImg(URL.createObjectURL(file));
    setIsImageUploaded(true);
  };

  const { open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop
  });

  const cancelUploadImage = () => {
    setUploadedImage(null);
    setIsImageUploaded(false);
    setSelectedUserImg(profile?.user_detail?.avatar);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguages = event.target.value;
    setSelectedLanguages(selectedLanguages);
    setValue('language', selectedLanguages);
  };

  const handleUploadImage = async () => {
    if (isImageUploaded) {
      if (!uploadedImage) return;

      const formData = new FormData();
      formData.append('image', uploadedImage);
      const result = await uploadProfilePicture(formData, "users");

      if (result.status) {
        setIsImageUploaded(false);
        await getUserProfile();
        toast.success("Avatar Updated successfully!");
      } else {
        toast.error(result?.error);
      }
    } else {
      open();
    }
  };

  return (
    <div className={styles.personalInformation}>
      <div className={styles.leftContainer}>
        {isImageUploaded ? (
          <img
            alt='preview'
            src={selectedUserImg}
            className={styles.profileImageArea}
          />
        ) : profile?.user_detail?.avatar ? (
          <img
            alt='avatar'
            src={UserImage}
            className={styles.profileImageArea}
          />
        ) : (
          <div className={styles.profileImageArea}>
            <CiCamera
              color='#24a5acb0'
              fontSize={'1.8rem'}
              className={styles.cameraIcon}
            />
          </div>
        )}

        <div className={styles.buttons}>
          <button className={`${styles.button} ${styles.upload}`} onClick={handleUploadImage}>
            {isImageUploaded ? 'Upload Photo' : 'Select Photo'}
          </button>
          <button className={`${styles.button} ${styles.remove}`} onClick={cancelUploadImage}>
            Remove Photo
          </button>
        </div>
      </div>

      <div className={styles.rightContainer}>
        <form>
          <div className={styles.formLine}>
            <span className={styles.label}>Select A Title</span>
            <Select
              name='title'
              value={watch('title') || ''}
              {...register('title')}
              className={styles.selectBox}
            >
              <MenuItem value="null">Select</MenuItem>
              <MenuItem value="Resident Physician">Resident Physician</MenuItem>
              <MenuItem value="Junior Doctor">Junior Doctor</MenuItem>
              <MenuItem value="Senior Doctor">Senior Doctor</MenuItem>
              <MenuItem value="Specialist">Specialist</MenuItem>
              <MenuItem value="Consultant">Consultant</MenuItem>
              <MenuItem value="General Practitioner">General Practitioner</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </div>

          <div className={`${styles.formLine} ${styles.inline}`}>
            <div className={styles.field}>
              <span className={styles.label}>First Name</span>
              <input
                type='text'
                name='first_name'
                {...register('first_name', { required: true })}
                onError={errors.first_name}
                className={styles.inputText}
              />
            </div>
            <div className={styles.field}>
              <span className={styles.label}>Last Name</span>
              <input
                type='text'
                name='last_name'
                {...register('last_name')}
                className={styles.inputText}
              />
            </div>
          </div>

          <div className={`${styles.formLine} ${styles.inline}`}>
            <div className={styles.field}>
              <span className={styles.label}>Gender</span>
              <Select
                name='gender'
                value={watch('gender') || ''}
                {...register('gender')}
                className={styles.selectBox}
              >
                <MenuItem value="null">Select</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Others">Preferred Not To Say</MenuItem>
              </Select>
            </div>
            <div className={styles.field}>
              <span className={styles.label}>Nationality</span>
              <Select
                name='nationality'
                value={watch('nationality') || ''}
                {...register('nationality')}
                className={styles.selectBox}
              >
                <MenuItem value="null">Select</MenuItem>
                {nationality?.map((item, index) => (
                  <MenuItem key={index} value={item?.nationality}>{item?.nationality}</MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className={styles.formLine}>
            <span className={styles.label}>Language Spoken</span>
            <Select
              multiple
              value={selectedLanguages}
              onChange={handleLanguageChange}
              className={styles.selectBox}
              renderValue={(selected) => (
                <div>
                  {selected.join(', ')}
                </div>
              )}
            >
              <MenuItem value="null">Select</MenuItem>
              {languageSpoken?.map((item) => (
                <MenuItem key={item.language} value={item?.language}>{item?.language}</MenuItem>
              ))}
            </Select>
          </div>
        </form>
      </div>
    </div >
  );
};

export default PersonalInformation;