import React, { createContext, useEffect, useState } from 'react';
import { getProfile } from '../services/user.service';
import toast from 'react-hot-toast';

const initials = {
  isLoggedIn: localStorage.getItem('access_token') ? true : false
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(initials?.isLoggedIn);
  const [profile, setProfile] = useState(null);

  const getUserProfile = async () => {
    const result = await getProfile();

    if (result.status) {
      setProfile(result.data);
    } else {
      toast.error(result.error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, getUserProfile, profile }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;