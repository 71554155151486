import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer
} from '@mui/material';
import { useParams } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getCandidateSpecificSessionLogs, getRecordingFile } from '../../../../services/session.service';
import { grade } from '../../../../utils/feedbackCalculation';
import download from 'downloadjs';
import toast from 'react-hot-toast';
import moment from 'moment';

const SessionLogDetails = () => {
  const SessionLogDetailColumns = [
    { id: 'case_no', label: 'Case No.', minWidth: 100 },
    { id: 'case_id', label: 'Case ID', minWidth: 120 },
    { id: 'consultation_skills', label: 'Consultation Skills', minWidth: 120, align: 'center' },
    { id: 'data_gathering_skills', label: 'Data Gathering Skills', minWidth: 120, align: 'center' },
    { id: 'counselling_skills', label: 'Counselling Skills', minWidth: 120, align: 'center' },
    { id: 'examination_skills', label: 'Examination Skills', minWidth: 120, align: 'center' },
    { id: 'management_skills', label: 'Management Skills & Investigation', minWidth: 120, align: 'center' },
    { id: 'overall_performance', label: 'Overall Performance', minWidth: 120, align: 'center' }
  ];

  const { sessionId } = useParams();
  const [sessionLogDetail, setSessionLogDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSpecificSessionLogs = async () => {
    const result = await getCandidateSpecificSessionLogs(sessionId);

    if (result?.status) {
      setSessionLogDetail(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await getRecordingFile(sessionId);

      if (response?.status) {
        const { room_name, recording_file } = response?.data;
        const fullUrl = `${process.env.NEXT_PUBLIC_IMAGE_URL}${recording_file}`;
        download(fullUrl, `${room_name}.mp3`, 'audio/mpeg');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading(false);
    }
  };

  const feedbackTotals = useMemo(() => {
    const categoryTotals = {};
    const differences = {};

    sessionLogDetail?.case_selection_topics?.forEach(topic => {
      const caseId = topic.category_topic[0].case_id;

      if (!categoryTotals[caseId]) {
        categoryTotals[caseId] = {};
      }

      topic.feedback.forEach(feedback => {
        const feedback_section_id = feedback.details.feedback_section_id;
        const feedback_type = feedback.details.feedback_type;
        const point = feedback.details.point;

        if (!categoryTotals[caseId][feedback_section_id]) {
          categoryTotals[caseId][feedback_section_id] = {
            positive: 0,
            negative: 0
          };
        }

        if (feedback_type === "Positive") {
          categoryTotals[caseId][feedback_section_id].positive += point;
        } else {
          categoryTotals[caseId][feedback_section_id].negative += point;
        }
      });
    });

    Object.keys(categoryTotals).forEach(caseId => {
      differences[caseId] = {};
      Object.keys(categoryTotals[caseId]).forEach(section_id => {
        differences[caseId][section_id] = categoryTotals[caseId][section_id].positive - categoryTotals[caseId][section_id].negative;
      });
    });

    return differences;
  }, [sessionLogDetail]);

  const overallTotals = useMemo(() => {
    const averages = {};

    for (const caseId in feedbackTotals) {
      const totals = feedbackTotals[caseId];
      let totalSum = 0;
      let keyCount = 0;

      for (const key in totals) {
        totalSum += totals[key];
        keyCount++;
      }

      const average = keyCount > 0 ? totalSum / keyCount : 0;
      averages[caseId] = average;
    };

    return averages;
  }, [sessionLogDetail, feedbackTotals]);

  useEffect(() => {
    if (sessionId) fetchSpecificSessionLogs();
  }, [sessionId]);

  return (
    <>
      <Box
        onClick={() => window.history.back()}
        sx={{ display: 'inline-block', mb: 2, cursor: 'pointer' }}
      >
        <ArrowBackIcon fontSize='medium' htmlColor='#099eaf' />
      </Box>

      <Box sx={{ display: 'flex', mb: 2, gap: '20px' }}>
        <Typography sx={{ background: '#00587A', color: '#fff', padding: '5px', borderRadius: '5px' }}>
          {sessionLogDetail?.session_name}
        </Typography>

        <Typography sx={{ background: '#2B4565', color: '#fff', padding: '5px', borderRadius: '5px' }}>
          {moment(sessionLogDetail?.start_date).format('DD/MM/YYYY')}
        </Typography>

        <Typography
          onClick={() => handleDownload()}
          sx={{
            display: 'flex',
            gap: '5px',
            background: '#2B4565',
            color: '#fff',
            padding: '5px',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          {loading ? 'Downloading...' :
            <>
              Session Recording
              <FileDownloadOutlinedIcon fontSize='medium' htmlColor='#fff' />
            </>
          }
        </Typography>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    color: 'white',
                    backgroundColor: '#099eaf'
                  }
                }}
              >
                {SessionLogDetailColumns?.map(column => (
                  <TableCell key={column.id} align={column.align} sx={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {sessionLogDetail?.case_selection_topics?.map((row, index) => {
                const caseId = row?.category_topic[0]?.case_id;
                const overallPerformance = overallTotals[caseId];
                const totals = feedbackTotals[caseId];

                return (
                  <TableRow key={index} sx={{ '& td': { color: '#000 !important' } }}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{caseId}</TableCell>
                    {totals &&
                      Object?.values(totals)?.map((total, index) => (
                        <TableCell key={index} align='center'>
                          {grade(total?.toFixed(2))}
                        </TableCell>
                      ))}
                    <TableCell align='center'>
                      {overallPerformance ? grade(overallPerformance.toFixed(2)) : '-'}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default SessionLogDetails;