import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getCandidateSessionLogs } from '../../../../services/session.service';
import OverallProgressTable from "../OverallProgressTable";
import CategoryProgressTable from "../CategoryProgressTable";
import toast from 'react-hot-toast';
import SessionLogTable from "../SessionLogTable";

const FeedbackProgress = ({ scoreData, viewType, selectedFeedbackFor }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [sessionLogs, setSessionLogs] = useState([]);
  const performanceLevels = ["Poor", "Unsatisfactory", "Competent", "Excellent"];

  const chartData = {
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Consultation Skills",
          "Data Gathering Skills",
          "Counselling Skills",
          "Examination Skills",
          "Management Skill & Investigation",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 10,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        title: {
          text: "Percentage (%)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val} %`;
          },
        },
      },
    },
    series: seriesData,
  };

  const fetchSessionLogs = async () => {
    const result = await getCandidateSessionLogs();

    if (result.status) {
      setSessionLogs(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  useEffect(() => {
    if (scoreData) {
      const data = performanceLevels.map((level) => ({
        name: level,
        data: Object.keys(scoreData?.percentageScores).map(
          (skill) => scoreData?.percentageScores[skill][level]
        ),
      }));
      setSeriesData(data);
    }
  }, [scoreData]);

  useEffect(() => {
    fetchSessionLogs();
  }, []);

  return (
    <div style={{ marginTop: "16px" }}>
      {viewType === "Table" && selectedFeedbackFor?.id === "overall" && (
        <OverallProgressTable scoreDetails={scoreData} />
      )}

      {viewType === "Table" && selectedFeedbackFor?.id !== "overall" && (
        <CategoryProgressTable scoreDetails={scoreData} />
      )}

      {viewType === "Bar Chart" && (
        <ReactApexChart
          width={1200}
          height={440}
          options={chartData.options}
          series={chartData.series}
          type="bar"
        />
      )}

      <div style={{ marginTop: '24px' }}>
        <h6
          style={{
            fontSize: '20px',
            fontWeight: 600,
            marginBottom: '16px'
          }}>
          Session Log
        </h6>

        <SessionLogTable
          rows={sessionLogs?.rows}
          count={sessionLogs?.count}
        />
      </div>
    </div>
  );
};

export default FeedbackProgress;