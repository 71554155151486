import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styles from './AddSession.module.scss';
import {
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormControlLabel,
  FormGroup,
  Chip,
  Radio,
  InputAdornment,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerWrapper from '../../components/ReactDatepicker/index';
import DatePicker from 'react-datepicker';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Close } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { getUsersList } from '../../services/user.service';
import { getCategoryList } from '../../services/learningHub.service';
import { createSession } from '../../services/session.service';
import toast from 'react-hot-toast';

const AddSession = ({ hide, getSessionList }) => {
  const { register, handleSubmit, setValue, watch, reset, control, formState: { errors } } = useForm({
    defaultValues: {
      session_name: '',
      start_date: '',
      end_date: '',
      session_type: '',
      user_role: null,
      invited_id: null,
      description: '',
      notification_time: '',
      observers: [],
      case_selections: []
    }
  });

  const CustomStartDateInput = forwardRef((props, ref) => {
    return (
      <TextField
        {...props}
        inputRef={ref}
        label='Start Date'
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '20px', fontSize: '14px'
          }
        }}
      />
    );
  });

  const CustomEndDateInput = forwardRef((props, ref) => {
    return (
      <TextField
        {...props}
        inputRef={ref}
        label='End Date'
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '20px', fontSize: '14px'
          }
        }}
      />
    );
  });

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250
      }
    }
  };

  const { profile } = useAuth();
  const maxCharacterCount = 500;
  const dropdownRef = useRef(null);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [sessionType, setSessionType] = useState('public');
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenSubDropdown, setIsOpenSubDropdown] = useState(false);
  const [notificationTime, setNotificationTime] = useState('');
  const [selectedCaseSelections, setSelectedCaseSelections] = useState([]);
  const [currentCaseSelectionId, setCurrentCaseSelectionId] = useState(null);
  const [caseSelectionError, setCaseSelectionError] = useState(false);
  const [selectedObservers, setSelectedObservers] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [remainingCount, setRemainingCount] = useState(maxCharacterCount);
  const startDate = watch('start_date');
  const endDate = watch('end_date');
  const userRole = watch('user_role');

  /* Handle onChange event for observer */
  const handleObserverChange = (values) => {
    setSelectedObservers(values);
    setValue('observers', values);
  };

  /* Handle onChange event for candidate or examiner */
  const handleCandidateSelection = (value) => {
    setValue('invited_id', value?.id);
  };

  /* Get user list from API */
  const getUserList = async () => {
    const result = await getUsersList();

    if (result?.status) {
      const filteredUserList = result?.data?.filter((user) => {
        return user.id !== profile?.id && user.id !== watch('invited_id');
      });
      setUserList(filteredUserList);
    } else {
      toast.error(result?.error);
    }
  };

  /* Get category list from API */
  const getCategorysList = async () => {
    const result = await getCategoryList("session");

    if (result?.status) {
      setCategoryList(result?.data);
    } else {
      toast.error(result?.error);
    }
  };

  /* Create the session API */
  const createSessions = async (data) => {
    setFormSubmitted(true);
    setLoading(true);

    if (selectedCaseSelections.length === 0) {
      setLoading(false);
      setCaseSelectionError(true);
      return;
    }

    const result = await createSession(data);
    clearForm();

    if (result?.status) {
      getSessionList('upcoming');
      toast.success(result?.msg);
    } else {
      toast.error(result?.error);
    }

    setLoading(false);
  };

  /* Case Selection Dropdown */
  const toggleMainDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
    setIsOpenSubDropdown(false);
  };

  /* Case Selection Topic Dropdown */
  const toggleSubDropdown = (caseSelectionId) => {
    setIsOpenSubDropdown((prev) => {
      if (prev && caseSelectionId === currentCaseSelectionId) {
        return false;
      } else {
        setCurrentCaseSelectionId(caseSelectionId);
        return true;
      }
    })
  };

  /* Handle Case Selection Dropdown */
  const handleTopicSelection = (caseSelectionId, topicId, caseId) => {
    const selectionIndex = selectedCaseSelections.findIndex(selection => selection.id === caseSelectionId);

    if (selectionIndex !== -1) {
      const topicIndex = selectedCaseSelections[selectionIndex].case_selection_topic.findIndex(topic => topic.topicId === topicId);

      if (topicIndex !== -1) {
        selectedCaseSelections[selectionIndex].case_selection_topic.splice(topicIndex, 1);

        if (selectedCaseSelections[selectionIndex].case_selection_topic.length === 0) {
          selectedCaseSelections.splice(selectionIndex, 1);
        }
      } else {
        selectedCaseSelections[selectionIndex].case_selection_topic.push({ topicId, caseId });
      }
    } else {
      selectedCaseSelections.push({ id: caseSelectionId, case_selection_topic: [{ topicId, caseId }] });
    }

    setSelectedCaseSelections([...selectedCaseSelections]);
    setValue('case_selections', [...selectedCaseSelections]);
    if (watch('case_selections') && setCaseSelectionError(false));
  };

  /* Handle deletion of selected case selection */
  const handleDeleteCaseSelection = (caseSelectionId, topicId) => {
    const newSelections = selectedCaseSelections.map(selection => {
      if (selection.id === caseSelectionId) {
        const updatedTopics = selection.case_selection_topic.filter(topic => topic.topicId !== topicId);
        return {
          ...selection,
          case_selection_topic: updatedTopics
        };
      }
      return selection;
    });

    setSelectedCaseSelections(newSelections);
    setValue('case_selections', newSelections);
  };

  /* Hide or Reset the form on click close button */
  const handleHide = () => {
    reset();
    hide();
    setSelectedCaseSelections([]);
    setIsOpenDropdown(false);
  };

  const clearForm = () => {
    reset();
    hide();
    setSessionType('public');
    setNotificationTime('');
    setSelectedCaseSelections([]);
  };

  /* Component Render */
  useEffect(() => {
    setValue('notification_time', notificationTime);
  }, [notificationTime, setValue]);

  useEffect(() => {
    setValue('session_type', sessionType);
  }, [sessionType, setValue]);

  useEffect(() => {
    if (isOpenDropdown) {
      getCategorysList();
    }
  }, [isOpenDropdown]);

  useEffect(() => {
    getUserList();
  }, [watch('invited_id')]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenDropdown(false);
        setIsOpenSubDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDescriptionChange = (event) => {
    const inputText = event.target.value;
    const remainingChars = maxCharacterCount - inputText.length;

    if (remainingChars >= 0) {
      setRemainingCount(remainingChars);
    }
    setValue('description', event.target.value);
  };

  return (
    <React.Fragment>
      <div className={styles.dailoagBoxLayer}>
        <div className={styles.dialogBox}>
          <div className={styles.dialogTitle}>
            <span>Add Session</span>
            <div className={styles.closeButton} onClick={handleHide}>
              <Close fontSize="medium" htmlColor="#FFF" />
            </div>
          </div>

          <div className={styles.dialogContent}>
            <DatePickerWrapper>
              <form onSubmit={handleSubmit(createSessions)} style={{ display: 'inline-block', width: '100%' }}>
                <TextField
                  label="Session Name"
                  name="session_name"
                  {...register("session_name", { required: true })}
                  error={errors.session_name}
                  fullWidth
                  sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '20px', fontSize: '14px' } }}
                />

                <Grid container spacing={3} sx={{ mb: 2 }}>
                  <Grid item lg={6}>
                    <DatePicker
                      name="start_date"
                      showTimeSelect
                      showYearDropdown
                      showMonthDropdown
                      minDate={new Date()}
                      selected={startDate}
                      placeholderText="yyyy-MM-dd, h:mm aa"
                      dateFormat="yyyy-MM-dd, h:mm aa"
                      customInput={<CustomStartDateInput />}
                      {...register("start_date", { required: true })}
                      error={errors.start_date}
                      onChange={(date) => setValue("start_date", date)}
                      timeIntervals={10}
                      timeCaption="Time"
                      filterTime={(time) => {
                        const currentTime = new Date();
                        return (
                          time.getHours() > currentTime.getHours() ||
                          (time.getHours() === currentTime.getHours() &&
                            time.getMinutes() >= currentTime.getMinutes())
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <DatePicker
                      name="end_date"
                      showTimeSelect
                      showYearDropdown
                      showMonthDropdown
                      minDate={new Date()}
                      selected={endDate}
                      placeholderText="yyyy-MM-dd, h:mm aa"
                      dateFormat="yyyy-MM-dd, h:mm aa"
                      customInput={<CustomEndDateInput />}
                      {...register("end_date", { required: true })}
                      error={errors.end_date}
                      onChange={(date) => setValue("end_date", date)}
                      disabled={!watch("start_date")}
                      timeIntervals={10}
                      timeCaption="Time"
                      filterTime={(time) => {
                        const startTime = watch("start_date") || new Date();
                        const tenMinutesAfterStartTime = new Date(
                          startTime.getTime() + 10 * 60 * 1000
                        );
                        const twoHoursAfterStartTime = new Date(
                          startTime.getTime() + 2 * 60 * 60 * 1000
                        );
                        return (
                          time >= tenMinutesAfterStartTime &&
                          time <= twoHoursAfterStartTime
                        );
                      }}
                    />
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                    gap: "50px",
                    width: '100%'
                  }}
                >
                  <InputLabel sx={{ width: '20%' }}>
                    Session Type <span style={{ color: "red" }}>*</span>
                  </InputLabel>

                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gap: '50px',
                      width: 'calc(80% - 50px)',
                    }}
                  >
                    <FormControlLabel
                      label="Public"
                      control={
                        <Radio
                          checked={sessionType === "public"}
                          onChange={() => setSessionType("public")}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Private"
                      control={
                        <Radio
                          checked={sessionType === "private"}
                          onChange={() => setSessionType("private")}
                        />
                      }
                    />
                  </FormGroup>
                </div>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>
                    My Role<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    label="My Role"
                    name="user_role"
                    MenuProps={MenuProps}
                    value={watch("user_role") || ""}
                    {...register("user_role", { required: true })}
                    error={errors.user_role}
                    sx={{ borderRadius: '20px', fontSize: '14px' }}
                  >
                    <MenuItem value={1}>Examiner</MenuItem>
                    <MenuItem value={2}>Candidate</MenuItem>
                  </Select>
                </FormControl>

                {userRole && sessionType && (
                  <Box sx={{ mb: 2 }}>
                    <Grid container spacing={5}>
                      <Grid item lg={6}>
                        <Autocomplete
                          options={userList || []}
                          getOptionLabel={(option) =>
                            `${option.first_name} ${option.last_name}`
                          }
                          onChange={(e, values) =>
                            handleCandidateSelection(values)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="Select Invitee"
                              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '20px', fontSize: '14px' } }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {userRole === 1 ? "Candidate" : "Examiner"}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item lg={6}>
                        <Controller
                          name="observers"
                          control={control}
                          render={({ field }) => {
                            const availableUsers =
                              userList?.filter(
                                (user) =>
                                  !selectedObservers.some(
                                    (observer) => observer.id === user.id
                                  )
                              ) || [];
                            return (
                              <Autocomplete
                                {...field}
                                multiple
                                options={availableUsers}
                                getOptionLabel={(option) =>
                                  `${option.first_name} ${option.last_name}`
                                }
                                onChange={(e, values) =>
                                  handleObserverChange(values)
                                }
                                disabled={!watch("invited_id")}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Select Observers"
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '20px', fontSize: '14px' } }}
                                  />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value?.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={`${option.first_name} ${option.last_name}`}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <TextField
                  label="Invitation message"
                  name="description"
                  variant="outlined"
                  placeholder="Type here.."
                  rows={3}
                  multiline
                  fullWidth
                  onChange={handleDescriptionChange}
                  inputProps={{ maxLength: maxCharacterCount }}
                  sx={{
                    mb: 1,
                    background: "white",
                    '& .MuiOutlinedInput-root': { borderRadius: '20px', fontSize: '14px' }
                  }}
                />
                <div style={{ textAlign: "end", fontSize: "12px" }}>
                  Remaining Characters: {remainingCount}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "50px",
                    marginTop: "10px",
                    marginBottom: "16px",
                    width: "100%",
                  }}
                >
                  <InputLabel sx={{ width: "20%" }}>
                    Reminders <span style={{ color: "red" }}>*</span>
                  </InputLabel>

                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "calc(80% - 50px)"
                    }}
                  >
                    <div style={{ display: "inline-block" }}>
                      <FormControlLabel
                        label="5 min before"
                        control={
                          <Radio
                            checked={notificationTime === "minutes before"}
                            onChange={() =>
                              setNotificationTime("minutes before")
                            }
                          />
                        }
                      />
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <FormControlLabel
                        label="An hour before"
                        control={
                          <Radio
                            checked={notificationTime === "hour before"}
                            onChange={() => setNotificationTime("hour before")}
                          />
                        }
                      />
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <FormControlLabel
                        label="A day before"
                        control={
                          <Radio
                            checked={notificationTime === "day before"}
                            onChange={() => setNotificationTime("day before")}
                          />
                        }
                      />
                    </div>
                  </FormGroup>
                </div>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "40px",
                  }}
                >
                  <InputLabel>
                    Case Selection <span style={{ color: "red" }}>*</span>
                  </InputLabel>

                  <div className="Box">
                    <div
                      ref={dropdownRef}
                      className="case_selection_dropdown_box"
                    >
                      <div
                        className="main-dropdown"
                        onClick={toggleMainDropdown}
                      >
                        <input
                          type="text"
                          placeholder="Selection Case"
                          readOnly
                          className={`${caseSelectionError ? "case-error" : ""
                            }`}
                        />
                        <div className="main_dropdown_arrow">
                          {isOpenDropdown ? (
                            <ArrowDropUpIcon htmlColor="#099eaf" />
                          ) : (
                            <ArrowDropDown htmlColor="#099eaf" />
                          )}
                        </div>
                      </div>

                      {isOpenDropdown && (
                        <div className="dropdown-list">
                          <ul className="topic_inner_list">
                            {categoryList?.map((item, index) => (
                              <li
                                key={index}
                                onClick={() => toggleSubDropdown(item?.id)}
                              >
                                {item?.title}
                                {isOpenSubDropdown &&
                                  item.id === currentCaseSelectionId && (
                                    <ArrowRightIcon
                                      htmlColor="#19a4ff"
                                      fontSize="large"
                                    />
                                  )}
                                {isOpenSubDropdown &&
                                  item.id === currentCaseSelectionId && (
                                    <div className="sub-dropdown">
                                      {item?.topics &&
                                        item?.topics?.length > 0 ? (
                                        <ul
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {item.topics.map((ele, index) => (
                                            <li
                                              key={index}
                                              onClick={() =>
                                                handleTopicSelection(
                                                  item.id,
                                                  ele.id,
                                                  ele.case_id
                                                )
                                              }
                                            >
                                              <span>
                                                <input
                                                  type="checkbox"
                                                  checked={selectedCaseSelections.some(
                                                    (selection) =>
                                                      selection?.id ===
                                                      item.id &&
                                                      selection?.case_selection_topic.some(
                                                        (topic) =>
                                                          topic?.topicId ===
                                                          ele.id
                                                      )
                                                  )}
                                                />
                                                {ele.case_id}
                                              </span>
                                              <VisibilityOutlinedIcon className="eye-icon" />
                                              {ele.summary ? (
                                                <div className="description-box">
                                                  {ele.summary}
                                                </div>
                                              ) : (
                                                <div className="description-box">
                                                  No Content
                                                </div>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <div
                                          style={{
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          No topics available
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </Box>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: '10px' }}>
                  {selectedCaseSelections?.flatMap((selection) =>
                    selection.case_selection_topic?.map(
                      ({ topicId, caseId }, index) => (
                        <Chip
                          key={index}
                          label={caseId}
                          onDelete={() =>
                            handleDeleteCaseSelection(selection?.id, topicId)
                          }
                        />
                      )
                    )
                  )}
                </Box>

                <Button
                  type="submit"
                  disabled={loading}
                  sx={{
                    position: "relative",
                    height: '40px',
                    minWidth: '150px',
                    color: "#FFF",
                    marginTop: '24px',
                    padding: '5px 25px 5px 25px',
                    background: "#E45653",
                    borderRadius: '16px',
                    textTransform: 'capitalize',
                    float: 'right',
                    '&:hover': {
                      background: '#E45653'
                    }
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                        color: '#FFF'
                      }}
                    />
                  ) : (
                    "Create Session"
                  )}
                </Button>
              </form>
            </DatePickerWrapper>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddSession;