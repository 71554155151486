import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, Select, MenuItem, Checkbox } from '@mui/material';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import AccordianBox from '../../../../../../components/AccordionBox/index';

const CaseSection = (props) => {
  const { caseSelection, sessionViewType, selectedCaseTopic, setSelectedCaseTopic } = props;
  const [selectedCaseId, setSelectedCaseId] = useState('');
  const [selectedCaseSelectionSections, setSelectedCaseSelectionSections] = useState([]);
  const [selectedCaseDescription, setSelectedCaseDescription] = useState('');

  const handleAccordionExpand = (index) => {
    setSelectedCaseSelectionSections(prevSelectedCaseSelection => {
      return prevSelectedCaseSelection?.map((section, i) => {
        if (i === index) {
          return {
            ...section,
            enable: !section.enable
          }
        } else {
          return section
        }
      })
    })
  };

  const handleCaseIdChange = (event) => {
    const selectedCaseId = event.target.value;
    setSelectedCaseId(selectedCaseId);

    const selectedCase = caseSelection?.find(item =>
      item?.category_topic?.some(topic => topic?.case_id === selectedCaseId)
    );

    if (selectedCase) {
      setSelectedCaseTopic({
        ...selectedCaseTopic,
        category_id: selectedCase?.case_selection_id,
        topic_id: selectedCase?.case_selections_topic,
        case_id: selectedCaseId,
        isFeedbackGiven: selectedCase?.isFeedbackGiven
      });

      const caseSelectionData = selectedCase?.category_topic?.find(topic => topic?.case_id === selectedCaseId)?.topic_details || [];
      setSelectedCaseSelectionSections(caseSelectionData);
      const caseDescription = selectedCase?.category_topic?.find(topic => topic?.case_id === selectedCaseId)?.summary || '';
      setSelectedCaseDescription(caseDescription);
    }
  };

  useEffect(() => {
    if (caseSelection && caseSelection?.length > 0) {
      const initialCaseId = caseSelection[0]?.category_topic[0]?.case_id;
      setSelectedCaseId(initialCaseId);
      handleCaseIdChange({ target: { value: initialCaseId } });
    }
  }, [caseSelection]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '30px',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          fontFamily: 'Montserrat'
        }}
      >
        <Typography
          variant='h6'
          sx={{
            width: '30%',
            color: 'black',
            fontWeight: 600,
            fontFamily: 'Montserrat'
          }}
        >
          Case ID
        </Typography>

        <Select
          value={selectedCaseId}
          onChange={handleCaseIdChange}
          sx={{
            height: '2rem',
            width: 'calc(70% - 30px)',
            background: '#dde0e5',
            fontFamily: 'Montserrat'
          }}
        >
          {caseSelection?.flatMap(item => item?.category_topic)?.map((topic, index) => (
            <MenuItem
              key={index}
              value={topic?.case_id}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {topic?.case_id}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {sessionViewType == 'Examiner View' && (
        <Card
          sx={{
            background: '#dde0e5',
            borderRadius: '5px',
            maxHeight: 'calc(100% - 70px)',
            overflowY: 'scroll',
            marginTop: '15px',
            '-ms-overflow-style': 'none',
            '::-webkit-scrollbar': { display: 'none' }
          }}
        >
          <CardContent>
            {selectedCaseSelectionSections?.length > 0 ? (
              selectedCaseSelectionSections.map((section, index) => (
                <Box sx={{ marginTop: '0.3rem' }} key={index}>
                  <AccordianBox
                    expand={section?.enable}
                    backgroundColor={'#FFF'}
                    setExpanded={() => handleAccordionExpand(index)}
                    title={<Box sx={{ width: '100%', color: '#000', fontWeight: '400' }}>{section?.section_name}</Box>}
                    details={<FroalaEditorView model={section?.section_value} />}
                  />
                </Box>
              ))
            ) : (
              <Box sx={{ marginTop: '0.3rem', color: '#000', fontWeight: '400', textAlign: 'center' }}>
                No topic details available
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {sessionViewType !== 'Examiner View' && (
        <Box sx={{ marginTop: '30px' }}>
          <Typography
            variant='subtitle1'
            sx={{
              display: 'inline-block',
              background: '#469aa0',
              padding: '5px 10px',
              color: '#fff',
              borderRadius: '5px'
            }}
          >
            Case Description
          </Typography>
          {selectedCaseDescription ? (
            <Typography
              sx={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '8px',
                marginBottom: '16px',
                marginTop: '15px'
              }}
            >
              {selectedCaseDescription}
            </Typography>
          ) : (
            <Typography sx={{ marginTop: '50px', textAlign: 'center', color: '#888' }}>
              No case description
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default CaseSection;