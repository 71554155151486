import React from 'react';
import styles from './Chats.module.scss';
import { CometChatConversationsWithMessages } from '@cometchat/chat-uikit-react';

const Chats = ({ specificUser }) => {
  return (
    <div className={styles.chats}>
      <CometChatConversationsWithMessages
        user={specificUser}
        typingIndicator={true}
        pushNotifications={true}
        messageText='Please select a chat to start messaging'
        conversationsWithMessagesStyle={{
          width: '100%',
          height: '100%',
          border: "1px solid #00000024",
          borderRadius: '2px'
        }}
      />
    </div>
  );
};

export default Chats;