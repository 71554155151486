import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const EventTooltip = ({ eventInfo, redirectToSession }) => {
  const title = eventInfo.event._def.title;
  const sessionTime = eventInfo.timeText;
  const role = eventInfo.event._def.extendedProps.user_role;
  const description = eventInfo.event._def.extendedProps.description;
  const sessionStatus = eventInfo.event._def.extendedProps.session_status;

  const tooltipContent = (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          gap: "10px",
          padding: "10px",
          color: "#6b6c6c",
          fontFamily: 'Montserrat'
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {sessionTime}
        </Typography>
        <Typography variant="body2">
          <span style={{ fontWeight: 600 }}>Your Role -</span> {role}
        </Typography>
        <Typography variant="body2">
          <span style={{ fontWeight: 600 }}>Description -</span> {description}
        </Typography>
      </div>
      {sessionStatus !== "Rejected" && (
        <div style={{ textAlign: "center", padding: "8px" }}>
          <Button
            style={{ fontSize: "12px" }}
            variant="contained"
            onClick={redirectToSession}
          >
            Join Session
          </Button>
        </div>
      )}
    </>
  );

  return (
    <Tooltip
      placement='top'
      TransitionProps={{ style: { backgroundColor: '#fff', border: '1px solid #099EAF' } }}
      title={tooltipContent}
    >
      <Box
        sx={{
          minHeight: '80px',
          border: role === "Examiner" ? '1px solid #36b170' : (role === "Candidate" ? '1px solid #ffcc00' : '1px solid #ea6363'),
          borderLeft: role === "Examiner" ? '6px solid #36b170' : (role === "Candidate" ? '6px solid #ffcc00' : '6px solid #ea6363'),
          borderRadius: '3px',
          cursor: 'pointer',
          background: '#fff',
          padding: '10px'
        }}
      >
        <Typography sx={{ color: '#2b363c', fontWeight: 600 }}>{title}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '5px', marginLeft: '5px' }}>
          <WatchLaterIcon style={{ color: 'rgba(58, 53, 65, 0.54)' }} fontSize='18px' />
          <Typography sx={{ color: '#2b363c', fontWeight: 500, fontSize: '12px' }}>{sessionTime}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default EventTooltip;