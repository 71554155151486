import React from 'react';
import styles from './UpcomingSession.module.scss';
import { useNavigate } from 'react-router-dom';
import { LuClock3 } from "react-icons/lu";
import { CgPlayButtonO } from "react-icons/cg";
import { useAuth } from '../../../../hooks/useAuth';
import useSessionList from '../../../../hooks/custom/useSessionList';
import moment from 'moment';

const UpcomingSessions = () => {
  const { profile } = useAuth();
  const navigate = useNavigate();
  const { sessionList } = useSessionList('upcoming');

  const userSessionLabel = (created_by, invited_user, user_role) => {
    if (profile?.id === created_by && user_role === 'Examiner') {
      return 'As Examiner';
    } else if (profile?.id === created_by && user_role === 'Candidate') {
      return 'As Candidate';
    } else if (profile?.id === invited_user && user_role === 'Examiner') {
      return 'As Candidate';
    } else if (profile?.id === invited_user && user_role === 'Candidate') {
      return 'As Examiner';
    } else {
      return 'As Observer';
    }
  };

  return (
    <div className={styles.upcomingSession}>
      <div className={styles.heading}>Upcoming Sessions</div>

      <div className={styles.sessionCard}>
        {sessionList?.length === 0 ? (
          <div className={styles.noSessionMessage}>No upcoming sessions</div>
        ) : (
          sessionList?.map((item, index) => {
            const startTime = moment(item?.start_date).format('h:mm A');
            const endTime = moment(item?.end_date).add(1, 'minute').format("h:mm A");
            return (
              <div className={styles.innerSessionCard} key={index}>
                <div className={styles.firstRow}>
                  <div className={styles.titleAndRole}>
                    <div className={styles.sessionTitle}>{item?.session_name}</div>
                    <div className={styles.role}>
                      {userSessionLabel(item?.created_by, item?.invited_user, item?.user_role)}
                    </div>
                  </div>

                  <div
                    className={styles.playButton}
                    onClick={() => navigate(`/sessions/session-details/${item?.id}`)}
                  >
                    <CgPlayButtonO />
                  </div>
                </div>

                <div className={styles.secondRow}>
                  <div className={styles.sessionTime}>
                    <LuClock3 fontSize={14} />
                    {startTime} - {endTime}
                  </div>
                </div>
              </div>
            )
          })
        )}

        {sessionList?.length > 0 && (
          <div
            className={styles.allSessionButton}
            onClick={() => navigate('/sessions')}
          >
            All Sessions
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingSessions;