import React from 'react';
import { Box, Skeleton } from '@mui/material';

const CometUserSkeleton = () => {
  return (
    <Box sx={{ padding: '10px' }}>
      <Skeleton
        variant='rectangular'
        width={210}
        height={150}
        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      />
      <Skeleton width={210} height={30} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <Skeleton width={180} height={30} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
    </Box>
  );
};

const CometUserSkeletonList = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        width: '100%'
      }}
    >
      {Array.from({ length: 10 }).map((_, index) => (
        <CometUserSkeleton key={index} />
      ))}
    </Box>
  );
};

export default CometUserSkeletonList;