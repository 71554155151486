import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Link } from '@mui/material';
import { verifyEmailToken } from '../../services/user.service';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const VerifyEmailToken = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);

  const getVerifyEmailConfirmation = async () => {
    try {
      const result = await verifyEmailToken(token);
      if (result.status) {
        toast.success(result?.msg);
      } else {
        toast.error(result?.error);
      }
    } catch (error) {
      console.error('Error verifying email token:', error);
      toast.error('An error occurred while verifying the email token');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) getVerifyEmailConfirmation();
  }, [token]);

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Typography variant='h4' sx={{ fontWeight: 800 }}>
            Validating the email....
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '40px',
              alignItems: 'center'
            }}
          >
            <Typography variant='h4' sx={{ fontSize: '44px !important', color: '#000' }}>
              Email verification complete!
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant='subtitle2' sx={{ fontSize: '20px !important' }}>
              Thank you for verifying your email address.
            </Typography>
            <Typography variant='subtitle2' sx={{ fontSize: '20px !important' }}>
              Please note that your account is pending approval from an administrator.
            </Typography>
            <Typography variant='subtitle2' sx={{ fontSize: '18px !important', marginTop: '20px' }}>
              You will receive an email notification once your account has been approved. Please check back later.
            </Typography>
            <Typography variant='subtitle2' sx={{ fontSize: '18px !important' }}>
              Thank you for your patience &#128522;
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
            <Link passHref href='/login'>
              <Button component='a' variant='contained' sx={{ px: 3.5 }}>
                Back to Login
              </Button>
            </Link>
          </Box>
        </>
      )}
    </>
  );
};

export default VerifyEmailToken;